import React, { ChangeEvent, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { parse, ParseResult } from 'papaparse'
import { useDispatch } from 'react-redux';
import styles from './index.module.css';

import { createInventoryListItemFromCSVRow, projectReplaceItems } from '../../../store/projectForEdit/actions';
import { InventoryListItem } from '../../../store/typings/types';
import { InventoryItems } from '../../../utils/requests';

const IGNORE_ROW_COUNT = 1;

const CSVButton: React.FC = () => {
  const dispatch = useDispatch();
  const updateProjectItems = (items: InventoryListItem[]) => dispatch(projectReplaceItems(items));
  const [parsing, setParsing] = useState<boolean>(false);

  async function onParseComplete(results: ParseResult) {
    const rows = results.data
      .slice(IGNORE_ROW_COUNT)
      .filter(row => row.length > 1 && row[0].length !== ''); // ignore empty line

    const inventoryItemName = rows.map(row => row[0]);
    const inventoryItems = await InventoryItems.findByTitle(inventoryItemName);
    const listItems = rows.map(row => createInventoryListItemFromCSVRow(row, inventoryItems));
    updateProjectItems(listItems);
    setParsing(false);
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;

    if (!files || files.length === 0) {
      return;
    }

    let file = files[0];

    setParsing(true);
    parse(file, { complete: onParseComplete });
  }

  return (
    <Button className="primary" loading={parsing} disabled={parsing}>
      <input id="csv-picker" type="file" accept=".csv" className={styles.picker} onChange={onChange} />
      <label htmlFor="csv-picker">Upload a CSV</label>
    </Button>
  );
};

export default CSVButton;
