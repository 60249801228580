export const ROLES = {
  SYSTEM_MANAGER: 'system_manager',
  LOGISTIC_PARTNER: 'lp',
  CLIENT: 'client'
};

export function setUser(user: any) {
  localStorage.setItem('user', JSON.stringify({
    token: user.jwt,
    id: user.user.id,
    username: user.user.username,
    email: user.user.email,
    type: user.user.role ? user.user.role.type : null,
    lp: user.user.lp || null,
    client: user.user.client || null
  }));
}

export function getUser() {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user) : null;
}

export function removeUser() {
  localStorage.removeItem('user');
}

export function getUserRole() {
  const user = getUser();
  return user && user.type ? user.type : '';
}
