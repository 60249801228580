import React from 'react';

const RecyclingNotes = () => (
  <div>
    <p>
      <strong>Notes</strong><br/>
    </p>
    <p>
      Divisions of Recycle by Grade<br/>
      <strong>Grade 1</strong> - 95% Recyclable (Mixed Metals)<br/>
      <strong>Grade 2</strong> – 90% Recyclable (E waste)<br/>
      <strong>Grade 3</strong> - 85% Recyclable (Commingled or Clean Wood)<br/>
      <strong>Grade 4</strong> - 60% Recycled (Mixed material, Fabrics,
      Plastics)<br/>
      <strong>Grade 5</strong> - Less than 40% Recyclable<br/>
      <strong>Grade 6</strong> - 100% Garbage (non-recyclable)<br/>

      **Rubble - clean dirt, concrete, sod, wet-pour, etc (or a
      combination of any)<br/>
      can be repurposed as clean fill in certain areas
    </p>
  </div>
);

export default RecyclingNotes;
