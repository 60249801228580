import React, {SyntheticEvent, useEffect, useState} from 'react';
import Pagination from 'react-pagination-lite';
import { PAGINATION_ITEMS_PER_PAGE } from '../../constants/common';

interface EcoPaginationProps {
  loading?: boolean;
  totalCount: number;
  onPaginate: (limit: number, offset: number) => void;
}

const EcoPagination: React.FC<EcoPaginationProps> = (props) => {
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const onPaginate = (pageNo: number) => {
    setActivePage(pageNo);
    props.onPaginate(PAGINATION_ITEMS_PER_PAGE, (pageNo - 1) * PAGINATION_ITEMS_PER_PAGE);
  };

  useEffect(() => {
    (async () => {
      await setActivePage(1);
      await setTotalCount(0);
      await setTotalCount(props.totalCount);
    })()
  }, [props.totalCount]);

  return (
    <div className={`eco-pagination-container ${props.loading ? 'element-disabled' : ''}`}>
      {
        totalCount ? <Pagination
          range={Math.ceil(totalCount/PAGINATION_ITEMS_PER_PAGE) > 10 ? 10 : Math.ceil(totalCount/PAGINATION_ITEMS_PER_PAGE)}
          activePage={activePage}
          totalCount={totalCount}
          itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
          onPaginate={onPaginate}
          styles={{
            navigationBtns: {
              activeIconColor: '#000',
              inactiveIconColor: '#b5b5b5',
              borderColor: '#eeeeee'
            },
            paginationCells: {
              padding: '6px 12px',
              activeBgColor: '#81c241',
              inactiveBgColor: '#f2f2f2',
              textColor: '#000000'
            }
          }}
        /> : ''
      }
    </div>
  );
};

export default EcoPagination;
