import React, { Fragment, useMemo, useState } from 'react';
import { Modal, Input, Button, Dimmer, Loader } from 'semantic-ui-react';
import { useFormState } from 'react-use-form-state';
import styles from './index.module.css';

import { Client, Photo } from '../../../store/typings/types';
import { Clients, Uploads } from '../../../utils/requests';
import { getImageUrlForPhoto } from '../../../utils/image';
import { createClient, createPhoto } from '../../../store/data';

import ImagePicker from '../../components/ImagePicker';

interface FormFields {
  name: string;
  logo?: Photo | null;
}

type Props = {
  close: () => void;
  client?: Client;
  onChange?: (client: Client, oldId: string) => void;
}

const ClientEditor: React.FC<Props> = (props) => {
  const client = props.client || createClient()

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formWasChanged, setFormWasChanged] = useState<boolean>(false);

  const initialFormData = useMemo((): FormFields => {
    const { name, logo } = client;
    return {
      name,
      logo
    }
  }, [client])

  const [formState, { text, email, raw }] = useFormState<FormFields>(initialFormData, {
    onChange: () => setFormWasChanged(true)
  });

  const { logo } = formState.values;

  const saveClientAndClose = async () => {

    if (!formWasChanged) {
      return props.close();
    }

    setIsLoading(true);

    const { name } = formState.values;

    let updatedClient = {
      ...client,
      name
    }

    try {
      const oldId = updatedClient.id;
      const res = await Clients.addOrUpdate(updatedClient);
      updatedClient.id = res.id;

      if (!logo && client.logo) {
        await Uploads.deletePhoto(client.logo);
        delete updatedClient.logo;
      }

      if (logo) {
        updatedClient = await uploadPhoto(updatedClient)
      }

      if (typeof props.onChange === 'function') {
        props.onChange(updatedClient, oldId);
      }

      return props.close();

    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  }

  const uploadPhoto = async (client: Client): Promise<Client> => {
    if (logo && logo.localFile) {
      const res = await Clients.addLogo(logo.localFile, client.id)

      client.logo = {
        id: res.id,
        url: res.url
      }
      return client
    }
    return client
  }

  let imgUrl = useMemo(() => {
    if (!logo) {
      return '';
    }

    if (logo.localFile) {
      return URL.createObjectURL(logo.localFile)
    }

    return getImageUrlForPhoto(logo);
  }, [logo]);

  return (
    <Fragment>
      <Modal.Content>
        <Modal.Description>

          {
            isLoading &&
            <Dimmer active>
              <Loader />
            </Dimmer>
          }

          <div className={styles['form-container']}>
            <Input {...text('name')} className="primary" placeholder="Title" />
            <ImagePicker
              {...raw({
                name: 'logo',
                onChange: (files: File[]) => files.length ? createPhoto(files[0]) : null
              })}
              url={imgUrl} />

            <Button className={`${styles.btn} primary`} onClick={saveClientAndClose}>Save</Button>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Fragment>
  );
};

export default ClientEditor;
