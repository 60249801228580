import React, {useMemo, SyntheticEvent, useState} from 'react';
import {Input, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import styles from './item.module.css';

import {Project} from '../../../store/typings/types';

import {dateFormatter} from '../../../utils/date';
import {createEditProjectURL} from '../../../routing/urls';
import {useFormState} from 'react-use-form-state';
import {Projects} from '../../../utils/requests';
import {getProjectStatusForUI} from '../../../utils/calculation';

type Props = {
  project: Project;
}

type FormState = {
  prOfComp: number | undefined;
}

const ProjectItem: React.FC<Props> = (props) => {
  const {project} = props;

  const {title, refNumber, prOfComp: progress, date, client, projectOwner} = project;

  const [formState, { number }] = useFormState<FormState>({ prOfComp: progress })

  const {values: {prOfComp}} = formState;

  const [prOfCompActive, setPrOfCompActive] = useState(false);

  const status = useMemo(() => {
    return getProjectStatusForUI(prOfComp ? +prOfComp : 0)
  }, [prOfComp]);

  const onSubmitStatus = (e: SyntheticEvent) => {
    e.preventDefault();
    updateStatus()
    setPrOfCompActive(false)
  }

  const updateStatus = () => {
    const forServer = !prOfComp || isNaN(prOfComp) ? 0 : prOfComp;

    // prevent for the same value
    if (forServer === project.prOfComp) {
      return;
    }

    project.prOfComp = forServer;
    Projects.addOrUpdate({id: project.id, prOfComp: forServer})
  }

  return (
    <tr>
      <td>{title}</td>
      <td>{refNumber}</td>
      <td>{projectOwner && projectOwner.username}</td>
      <td>{dateFormatter(date)}</td>
      <td>{client && client.name}</td>
      <td>{status}</td>
      <td>
        <form onSubmit={onSubmitStatus}>
          <Input
            required
            action={{icon: 'checkmark'}}
            min="0"
            max="100"
            className={`${!prOfCompActive ? styles.input : styles.active} primary`}
            {...number({
              name: 'prOfComp',
              onChange: e => setPrOfCompActive(true),
            })} />
        </form>
      </td>
      <td>
        <Link to={createEditProjectURL(project.id)}>
          <Icon className="primary-text" name="edit"/>
        </Link>
      </td>
    </tr>
  );
};

export default ProjectItem;
