import React, {Component, SyntheticEvent} from 'react';

import styles from './index.module.css';
import {AutoSizer, List, ListRowProps} from 'react-virtualized';

import {AppState} from '../../../store';
import CharityItemDetails
  from "../../../common/components/CharityItemDetails";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button, Icon, Tab} from "semantic-ui-react";
import {
  addDonationRunCharityReporting,
  removeDonationRunCharityReporting
} from "../../../store/metrics/actions";


const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({
    addDonationRunCharityReporting: addDonationRunCharityReporting,
    removeDonationRunCharityReporting: removeDonationRunCharityReporting
  }, dispatch);
};

const mapStateToProps = (state: AppState) => ({
  charityReporting: state.inputDiversionMetrics.charityReporting,
});

type Props = ReturnType<typeof mapStateToProps> & {
  addDonationRunCharityReporting: (donationRunNo: number) => void,
  removeDonationRunCharityReporting: (donationRunNo: number) => void
}

type State = {
  panes: Array<object>
  activeDonationRunNo: number
};

class CharityReporting extends Component<Props, State> {
  handleTabChange: (e: SyntheticEvent, { activeIndex } : any) => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      activeDonationRunNo: 0,
      panes: [
        {menuItem: 'Donation Run 1'}
      ]
    };

    this.handleTabChange = (e, { activeIndex }) =>  this.setState({ activeDonationRunNo: activeIndex });
  }

  addItem = () => {
    const greatestDonationRunNo = Math.max.apply(Math, this.props.charityReporting.map(el => el.donationRunNo));
    this.props.addDonationRunCharityReporting(greatestDonationRunNo + 1);
    this.setState({
      activeDonationRunNo: greatestDonationRunNo + 1
    });
  };

  removeItem = (donationRunNo: number) => {
    this.props.removeDonationRunCharityReporting(donationRunNo);
    this.setState({
      activeDonationRunNo: donationRunNo - 1
    });
  };

  private paneLayout = (donationRunNo: number) => {
    const charityReporting = this.props.charityReporting.filter(el => el.donationRunNo === donationRunNo);
    return (
      <Tab.Pane className={styles.pane}>
        <div className={`${styles.row} ${styles.header}`}>
          <div className="secondary-text">Project ID</div>
          <div className="secondary-text">Items</div>
          <div className="secondary-text">Client Product Name</div>
          <div className="secondary-text">Quantity</div>
          <div></div>
          <div className="secondary-text">Qty Donated</div>
          <div className="secondary-text">Charity Sign Off Sheet</div>
        </div>
        <AutoSizer>
          {({width, height}) => (
            <List
              height={height}
              width={width}
              rowCount={charityReporting.length}
              rowHeight={58}
              rowRenderer={props => this.rowRenderer(donationRunNo, props)}
            />
          )}
        </AutoSizer>
        {
          donationRunNo > 0 ? <div className={styles.tabDeleteContainer}>
            <Button className={`${styles.tabDeleteBtn}`} color='red' onClick={() => this.removeItem(donationRunNo)}>
              Delete Donation
            </Button>
          </div> : ''
        }
      </Tab.Pane>
    )
  }

  private rowRenderer = (donationRunNo: number, {index, key, style}: ListRowProps) => {
    const item = this.props.charityReporting.filter(el => el.donationRunNo === donationRunNo)[index];
    return (
      <div className={`${styles.row} ${styles.listItem}`} key={item.id}
           style={style}>
        <CharityItemDetails
          item={item}
        />
      </div>
    );
  };

  render() {
    const {activeDonationRunNo} = this.state;

    const renderPanes = () => {
      const donationRunsNo = Array.from(new Set(this.props.charityReporting.map(el => el.donationRunNo))).sort();
      return donationRunsNo.map((donationRunNo, index) => ({
        menuItem: `Donation Run ${donationRunNo + 1}`,
        render: () => this.paneLayout(donationRunNo)
      }))
    };

    return (
      <div className={`page-padding-small ${styles.container}`}>

        <div className={styles.header}>
          <h1 className="secondary-text">Charity Reporting</h1>
        </div>

        <div className={styles.tabsContainer}>
          <Tab
            panes={renderPanes()}
            activeIndex={activeDonationRunNo}
            onTabChange={this.handleTabChange}
          />
        </div>
        <div>
          <button onClick={this.addItem}
                  className={`${styles.add} button`}>
            <label className="ui circular icon button primary">
              <Icon name="add"/>
            </label>
            <span>Add Another Ticket</span>
          </button>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CharityReporting)
