import React from "react";
import styles from "./index.module.css";
import { getImageUrlForPhoto } from "../../utils/image";
import { Modal } from "semantic-ui-react";

interface IPhoto {
  showPhotoLoader: boolean;
  isPhotoListEmpty: boolean;
  photosListing: Array<any>;
  projectSelected: any;
}

const Photos: React.FC<IPhoto> = (props) => {
  const { showPhotoLoader, photosListing, isPhotoListEmpty, projectSelected } =
    props;

  const renderPhotos = () => {
    let newObject = Object.keys(photosListing).map(function (key: any) {
      return photosListing[key];
    });
    return newObject.map((item1: any, index: number) => {
      return (
        <div key={index} className={styles.ImageBoxMainWrapper}>
          <h3>Donation Run {index + 1}</h3>
          <div key={index} className={styles.imageMainBox}>
            {item1.map((item2: any, index: number) => {
              return (
                <div key={index} className={styles.imageInnerBox}>
                  {item2.signOfSheetImage ? (
                    <>
                      <Modal
                        trigger={
                          <img
                            src={getImageUrlForPhoto(item2.signOfSheetImage)}
                          />
                        }
                        basic
                        size="small"
                      >
                        <Modal.Content>
                          <div className={styles.ImageViewerModal}>
                            <img
                              src={getImageUrlForPhoto(item2.signOfSheetImage)}
                            />
                          </div>
                        </Modal.Content>
                      </Modal>
                    </>
                  ) : (
                    <div className={styles.noImageFound}>No Image Found</div>
                  )}
                  <p>{item2.metric.inventoryItem.title}</p>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {!projectSelected ? (
        <div className="ui segment" style={{ height: "400px" }}>
          <div className="ui active inverted dimmer">Please Select Project</div>
          <p></p>
        </div>
      ) : showPhotoLoader ? (
        <div className="ui segment" style={{ height: "400px" }}>
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
          <p></p>
        </div>
      ) : (
        <div className={styles.sliderWrapper}>
          {isPhotoListEmpty ? (
            <div className="ui segment" style={{ height: "400px" }}>
              <div className="ui active inverted dimmer">No Data Found</div>
              <p></p>
            </div>
          ) : (
            renderPhotos()
          )}
        </div>
      )}
    </>
  );
};

export default Photos;
