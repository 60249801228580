import {
  ProjectsActionTypes,
  PROJECTS_FILTER,
  PROJECTS_DROPDOWN,
  PROJECTS_ID
} from "./types";
import {Projects} from "../../utils/requests";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../index";
import {Action} from "redux";
import {projectToggleLoading} from "../projectForEdit/actions";

export function editProjectsFilter(filter: string): ProjectsActionTypes {
  return {
    type: PROJECTS_FILTER,
    payload: filter
  };
}

export function editProjectsSelectedID(id: string): ProjectsActionTypes {
  return {
    type: PROJECTS_ID,
    payload: id
  };
}

export function ProjectDropdownAction(LP: string): ThunkAction<void, AppState, null, Action<string>> {
  return async (dispatch) => {
    dispatch(projectToggleLoading(true));
    const queryObj = LP ? { LP: LP, isLegacy: false } : { isLegacy: false };
    const projects = await Projects.projects(queryObj);
    dispatch({
      type: PROJECTS_DROPDOWN,
      payload: projects
    });
    dispatch(projectToggleLoading(false));
  }
}

