import {
  Project, Metric, RecyclingReportingTicket, Photo, CharityReporting, RecyclingReportingGrade, InventoryItem
} from "../typings/types";

export interface InputDiversionMetricsState {
  inventoryItems: InventoryItem[];
  project: Project,
  metrics: Metric[],
  originalMetrics: Metric[],
  recyclingReporting: RecyclingReportingTicket[],
  originalRecyclingReporting: RecyclingReportingTicket[],
  charityReporting: CharityReporting[],
  originalCharityReporting: CharityReporting[],
  recyclingReportingGrades: RecyclingReportingGrade[],
  isLoading?: boolean
}

export const INPUT_DIVERSION_METRICS_SET = 'INPUT_DIVERSION_METRICS_SET';
export const IDM_UPDATE_METRIC = 'IDM_UPDATE_METRIC';
export const IDM_ADD_RECYCLING_REPORTING_TICKET = 'IDM_ADD_RECYCLING_REPORTING_TICKET';
export const IDM_REMOVE_RECYCLING_REPORTING_TICKET = 'IDM_REMOVE_RECYCLING_REPORTING_TICKET';
export const IDM_UPDATE_RECYCLING_REPORTING_TICKET = 'IDM_UPDATE_RECYCLING_REPORTING_TICKET';
export const IDM_ADD_PROJECT_METRICS_STATIC_IMAGES = 'IDM_ADD_PROJECT_METRICS_STATIC_IMAGES';
export const IDM_UPDATE_PROJECT_METRICS_STATIC_IMAGE = 'IDM_UPDATE_PROJECT_METRICS_STATIC_IMAGE';
export const IDM_DELETE_PROJECT_METRICS_STATIC_IMAGE = 'IDM_DELETE_PROJECT_METRICS_STATIC_IMAGE';
export const IDM_ADD_DONATION_RUN_CHARITY_REPORTING = 'IDM_ADD_DONATION_RUN_CHARITY_REPORTING';
export const IDM_REMOVE_DONATION_RUN_CHARITY_REPORTING = 'IDM_REMOVE_DONATION_RUN_CHARITY_REPORTING';
export const IDM_UPDATE_CHARITY_REPORTING_ITEM = 'IDM_UPDATE_CHARITY_REPORTING_ITEM';
export const IDM_SET_IS_LOADING = 'IDM_SET_IS_LOADING';
export const IDM_PROJECT_SET_METRICS_STATUS = 'IDM_PROJECT_SET_METRICS_STATUS';
export const IDM_GET_RECYCLING_REPORTING_GRADES = 'IDM_GET_RECYCLING_REPORTING_GRADES';
export const IDM_ADD_METRIC = 'IDM_ADD_METRIC';
export const IDM_REMOVE_METRIC = 'IDM_REMOVE_METRIC';

interface InputDiversionMetricsSetAction {
  type: typeof INPUT_DIVERSION_METRICS_SET,
  payload: InputDiversionMetricsState
}
interface IDMUpdateMetricAction {
  type: typeof IDM_UPDATE_METRIC,
  payload: Metric
}
interface IDMAddRecyclingReportingAction {
  type: typeof IDM_ADD_RECYCLING_REPORTING_TICKET,
  payload: RecyclingReportingTicket
}
interface IDMRemoveRecyclingReportingAction {
  type: typeof IDM_REMOVE_RECYCLING_REPORTING_TICKET,
  payload: RecyclingReportingTicket
}
interface IDMUpdateRecyclingReportingAction {
  type: typeof IDM_UPDATE_RECYCLING_REPORTING_TICKET,
  payload: RecyclingReportingTicket
}
interface IDMAddProjectMetricsStaticImagesAction {
  type: typeof IDM_ADD_PROJECT_METRICS_STATIC_IMAGES;
  payload: Photo[]
}
interface IDMUpdateProjectMetricsStaticImageAction {
  type: typeof IDM_UPDATE_PROJECT_METRICS_STATIC_IMAGE;
  payload: {
    oldImgId: string,
    photo: Photo
  }
}
interface IDMDeleteProjectMetricsStaticImageAction {
  type: typeof IDM_DELETE_PROJECT_METRICS_STATIC_IMAGE;
  payload: {
    imgId: string
  }
}
interface IDMAddDonationRunCharityReportingAction {
  type: typeof IDM_ADD_DONATION_RUN_CHARITY_REPORTING;
  payload: {
    donationRunNo: number
  }
}
interface IDMRemoveDonationRunCharityReportingAction {
  type: typeof IDM_REMOVE_DONATION_RUN_CHARITY_REPORTING;
  payload: {
    donationRunNo: number
  }
}
interface IDMUpdateCharityReportingItemAction {
  type: typeof IDM_UPDATE_CHARITY_REPORTING_ITEM;
  payload: CharityReporting
}
interface IDMSetIsLoadingAction {
  type: typeof IDM_SET_IS_LOADING;
  payload: boolean
}
interface IDMProjectSetMetricsStatusAction {
  type: typeof IDM_PROJECT_SET_METRICS_STATUS;
  payload: string
}
interface IDMGetRecyclingReportingGradesAction {
  type: typeof IDM_GET_RECYCLING_REPORTING_GRADES
}
interface IDMAddMetricAction {
  type: typeof IDM_ADD_METRIC
}
interface IDMRemoveMetricAction {
  type: typeof IDM_REMOVE_METRIC,
  payload: {
    id: string
  }
}

export type InputDiversionMetricsActionTypes =
  | InputDiversionMetricsSetAction
  | IDMUpdateMetricAction
  | IDMAddRecyclingReportingAction
  | IDMRemoveRecyclingReportingAction
  | IDMUpdateRecyclingReportingAction
  | IDMAddProjectMetricsStaticImagesAction
  | IDMUpdateProjectMetricsStaticImageAction
  | IDMDeleteProjectMetricsStaticImageAction
  | IDMAddDonationRunCharityReportingAction
  | IDMRemoveDonationRunCharityReportingAction
  | IDMUpdateCharityReportingItemAction
  | IDMSetIsLoadingAction
  | IDMProjectSetMetricsStatusAction
  | IDMGetRecyclingReportingGradesAction
  | IDMAddMetricAction
  | IDMRemoveMetricAction;
