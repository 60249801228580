import { Currency, InventoryListItem, Quote, ProjectStatus } from "../store/typings/types";

export const TONNES_TO_POUND = 2204.623;

export const parseNumber = (a: string, defaultVal: number = 0): number => {
  const n = parseInt(a, 10);
  return isNaN(n) ? defaultVal : n
}
export const round = (num: number, decimalPlace: number = 2): number => {
  const factor = 10 ** decimalPlace;
  return Math.floor(num * factor) / factor;
}
export const convertPoundsToTonnes = (pounds: number, quantity: number): number => {
  if (pounds === 0 || quantity === 0) {
    return 0
  }
  return pounds * quantity / TONNES_TO_POUND
}

export function getCurrencySign(currency: Currency): string {
  switch (currency) {
    case Currency.CAD:
      return '$';
    case Currency.USD:
      return '$';
    case Currency.GBP:
      return '￡';
    case Currency.EUR:
      return '€';
    default:
      return 'Unsupported'
  }
}

function calcLandfillDiversion(items: InventoryListItem[], factor: number): number {
  const itemTonnes = items.map(item => convertPoundsToTonnes(item.pounds, item.quantity));
  const sum = itemTonnes.reduce((sum, ton) => sum + ton, 0);
  let internalReuseWeight = items.reduce((sum, el) => sum + el.internalReuse / el.quantity * convertPoundsToTonnes(el.pounds, el.quantity), 0);
  internalReuseWeight = isNaN(internalReuseWeight) ? 0 : internalReuseWeight;
  return (sum - internalReuseWeight) * factor;
}

export const calcWeight: (pounds: number, quantity: number) => number = convertPoundsToTonnes
export const calcItemValue = (quantity: number, FMV: number): String => currencyFormat(quantity * FMV);

export const calcQuote = (quote: Quote): { moveMgmt: number, processMgmt: number, landfillDiversion: number, total: number } => {
  const { inventoryListItems, LP, includeMoveMgmt, qCoeff: { MM, PM, LD }, manualMoveManagement, transferOfTitle } = quote;

  const lp = LP || 0;
  const moveMgmt = manualMoveManagement || (lp * MM);
  const processMgmt = moveMgmt * PM;
  const landfillDiversion = calcLandfillDiversion(inventoryListItems, LD);
  const total = (includeMoveMgmt ? moveMgmt : 0) + processMgmt + landfillDiversion - transferOfTitle

  return {
    moveMgmt: includeMoveMgmt ? moveMgmt : 0,
    processMgmt,
    landfillDiversion,
    total
  }
};

export const currencyFormat = (num: number): string => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const percentageFormat = (num: number): string => {
  return `${Math.floor(num)}%`
}

export const safeDivision = (left: number, right: number): number => right === 0 ? 0 : left / right;

export const calcLPPricePerTonnes = (lpQuote: number, items: InventoryListItem[]): string => {
  const sum = items.reduce((sum, { pounds, quantity }) => sum + convertPoundsToTonnes(pounds, quantity), 0)
  return currencyFormat(sum === 0 ? 0 : lpQuote / sum)
}
export const calcPricePerTonne = (total: number, items: InventoryListItem[]): string => {
  const sum = items.reduce((sum, { pounds, quantity }) => sum + convertPoundsToTonnes(pounds, quantity), 0)
  return currencyFormat(sum === 0 ? 0 : total / sum)
}
export const calcDifference = (lpQuote: number, total: number): number => total - lpQuote;
export const calcGrossMargin = (lpQuote: number, total: number): string => {
  if (total === 0) {
    return percentageFormat(0);
  }
  return percentageFormat(calcDifference(lpQuote, total) / total * 100)
}

const RECYCLING_FACTOR = 150;
const RECOVERED_MATERIALS_FACTOR = 15;

export const calcBottomBlock = (recycledPercent: number, items: InventoryListItem[], metalCreditFactor: number): {
  donated: string,
  internalReuse: number,
  totalRecycledValue: string,
  totalDiverted: string,
  totalRecoveredMaterials: string,
  quoteTotal: string,
  metalCredit: number
} => {
  const donated = items.reduce((sum, el) => sum + el.FMV * el.donation, 0)
  const internalReuse = items.reduce((sum, { pounds, internalReuse, quantity }) => {
    const leftPart = quantity === 0 ? 0 : internalReuse / quantity;
    return sum + leftPart * calcWeight(pounds, quantity);
  }, 0)

  const recycledTonnes = items.reduce((sum, { pounds, quantity, recycling }) => {
    return sum + recycling / 100 * calcWeight(pounds, quantity);
  }, 0)

  const totalRecycledValue = recycledTonnes * recycledPercent / 100 * RECYCLING_FACTOR;
  const totalDiverted = donated + totalRecycledValue;
  const totalRecoveredMaterials = recycledTonnes / 100 * RECOVERED_MATERIALS_FACTOR;
  const quoteTotal = totalDiverted + totalRecoveredMaterials;

  const allRecycledWeight = items.reduce((sum, { pounds, quantity = 0, recycling = 0 }) => {
    if (recycling === 0 || quantity === 0) {
      return sum;
    }
    return sum + recycling / quantity * calcWeight(pounds, quantity);
  }, 0)
  const recycledWeight = allRecycledWeight * 0.85;
  const recycledValue = recycledWeight * recycledPercent / 100;
  const metalCredit = recycledValue * metalCreditFactor;

  return {
    donated: currencyFormat(donated),
    internalReuse: round(internalReuse),
    totalRecycledValue: currencyFormat(totalRecycledValue),
    totalDiverted: currencyFormat(totalDiverted),
    totalRecoveredMaterials: currencyFormat(totalRecoveredMaterials),
    quoteTotal: currencyFormat(quoteTotal),
    metalCredit: round(metalCredit)
  }
}

export const getProjectStatusForUI = (prOfComp: number | undefined): String => {
  if (typeof prOfComp !== 'number' || isNaN(prOfComp) || prOfComp === 0) {
    return ProjectStatus.Draft;
  }
  if (prOfComp === 1) {
    return 'Target List';
  }
  if (prOfComp > 1 && prOfComp <= 5) {
    return 'Prospect Calls';
  }
  if (prOfComp > 5 && prOfComp <= 10) {
    return 'Registration Channel Partner or lead, Repeat Business, Presentation made'
  }
  if (prOfComp > 10 && prOfComp <= 20) {
    return 'Scope Requested'
  }
  if (prOfComp > 20 && prOfComp <= 30) {
    return 'Walk Through'
  }
  if (prOfComp > 30 && prOfComp <= 40) {
    return 'Scope Received'
  }
  if (prOfComp > 40 && prOfComp <= 50) {
    return 'Submitted'
  }
  if (prOfComp > 50 && prOfComp <= 55) {
    return 'Rejected'
  }
  if (prOfComp > 55 && prOfComp <= 60) {
    return 'Acceptance Received'
  }
  if (prOfComp > 60 && prOfComp <= 70) {
    return 'Project Scheduled'
  }
  if (prOfComp > 70 && prOfComp <= 80) {
    return 'Project complete'
  }
  if (prOfComp > 80 && prOfComp <= 90) {
    return 'Invoiced'
  }
  if (prOfComp > 90 && prOfComp <= 100) {
    return 'Remittance Received'
  }
  return '';
}

