import React, { SyntheticEvent } from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import styles from './index.module.css'

interface RadioElProps {
	label?: string;
  value: string;
  checked: boolean;
  className?: string;
	onChange?: (el: SyntheticEvent<HTMLElement, Event>) => void;
}

const RadioEl: React.FC<RadioElProps> = (props) => {
  const onChangeLocal = (e: SyntheticEvent<HTMLElement, Event>, data: CheckboxProps) => {
		if (props.onChange) {
			const updateEvent = Object.assign({}, e, {
				target: {
					value: data.value,
					validity: { valid: true }
				}
			});
			props.onChange(updateEvent);
		}
	};
	return (
    <Checkbox
      className={`${styles.checkbox} ${props.className}`}
      radio
      label={props.label}
      value={props.value}
      checked={props.checked}
      onChange={onChangeLocal}
    />
	);
};

export default RadioEl;
