import React, { useMemo, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { useSelector } from 'react-redux';
import styles from './index.module.css';

import Toggle from '../../../common/components/Toggle';
import LoadingEl from '../../../common/components/LoadingEl';

import { AppState } from '../../../store';
import { createInvoiceUrl } from '../../../utils/requests';

interface FormFields {
  buildWebRequestForm: boolean;
  buildOutWebPage: boolean;
}

const ReviewPage: React.FC = () => {
  const { project: { id, title }, loading } = useSelector((state: AppState) => state.projectForEdit);
  const [, { checkbox }] = useFormState<FormFields>({ buildWebRequestForm: true, buildOutWebPage: false });
  const pdfUrl = useMemo(() => createInvoiceUrl(id), [id]);
  const url = pdfUrl + '/' + encodeURI(title || '').replace(/\/|\/$/g, ' ');

  const [showSpinner, setShowSpinner] = useState(true);

  return (
    <div className={styles.container}>
      <div className="page-padding-small">
        <h1 className="secondary-text">Company</h1>
        <div className={styles.container}>
          <div className={styles.row}>
            <Toggle {...checkbox('buildWebRequestForm')} label="Build web request form" />
          </div>
          <div className={styles.row}>
            {/* <Toggle {...checkbox('buildOutWebPage')} label="Build out web page" /> */}
          </div>
        </div>
      </div>

      {
        !loading &&
        <div className={styles.iframeContainer}>
          <LoadingEl show={showSpinner} />
          <iframe title="Invoice" src={url} onLoad={() => setShowSpinner(false)} className={styles.iframe} />
        </div>
      }
    </div>
  );
};

export default ReviewPage;
