import React, {Component} from 'react';

import styles from './index.module.css';
import {AutoSizer, List, ListRowProps} from 'react-virtualized';

import {AppState} from '../../../store';
import {Photo} from "../../../store/typings/types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import PhotoEl from "../../../common/components/PhotoEl";
import {Icon} from "semantic-ui-react";
import ImagePicker from "../../../common/components/ImagePicker";
import {
  addMetric,
  deleteProjectMetricsStaticImage,
  fetchInputDiversionMetrics,
  projectUploadMetricsStaticImages
} from "../../../store/metrics/actions";
import MetricInfo from "../../../common/components/MetricInfo";
import ImageVD from "../../../common/components/ImageVD";

const UPLOAD_IMAGE_COUNT = 12;
const IMAGE_ARRAY = Array(UPLOAD_IMAGE_COUNT).fill(0);

const addItem = () => addMetric();

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({
    projectUploadMetricsStaticImages: projectUploadMetricsStaticImages,
    deleteProjectMetricsStaticImage: deleteProjectMetricsStaticImage,
    fetchInputDiversionMetrics: fetchInputDiversionMetrics,
    addItem
  }, dispatch);
};

const mapStateToProps = (state: AppState) => {
  return {
    metrics: state.inputDiversionMetrics.metrics,
    project: state.inputDiversionMetrics.project,
    refNumber: state.inputDiversionMetrics.project.refNumber,
    isLoading: state.inputDiversionMetrics.isLoading,
    defaultItems: state.inputDiversionMetrics.inventoryItems.map(el => ({ text: el.title, value: el.id, item: el }))
  }
};

type Props = ReturnType<typeof mapStateToProps> & {
  projectUploadMetricsStaticImages: (files: File[]) => void,
  deleteProjectMetricsStaticImage: (imgId: string) => void,
  fetchInputDiversionMetrics: (projectId: string) => void,
  match: { params: { id: string } },
  addItem: () => void
};

class PostReportings extends Component<Props> {

  constructor(props: Props) {
    super(props);

    const {match: {params: {id: paramsID}}} = this.props;
    const {id} = this.props.project;
    if (id != paramsID && !props.isLoading) {
      this.props.fetchInputDiversionMetrics(paramsID);
    }
  }

  uploadPhotos = (files: File[]) => {
    if (!files.length) {
      return;
    }

    const {project} = this.props;
    const couldAddFilesCount = UPLOAD_IMAGE_COUNT - project.projectMetricsStaticImages.length;
    if (1 > couldAddFilesCount) {
      return
    }

    const validFiles = files.slice(0, couldAddFilesCount);
    this.props.projectUploadMetricsStaticImages(validFiles);
  };

  deletePhoto = (photo: Photo) => {
    this.props.deleteProjectMetricsStaticImage(photo.id);
  };

  private rowRenderer = ({index, key, style}: ListRowProps) => {
    const item = this.props.metrics[index];
    return (
      <div className={`${styles.row} ${styles.listItem}`} key={item.id}
           style={style}>
        <MetricInfo
          item={item}
          defaultOptions={this.props.defaultItems}
        />
      </div>
    );
  };

  render() {
    const {metrics, refNumber, project} = this.props;

    const uploadLayout = IMAGE_ARRAY.map((el, i) => {
      const content =
        project && project.projectMetricsStaticImages && project.projectMetricsStaticImages[i] ? (
          <ImageVD photo={project.projectMetricsStaticImages[i]} onClose={this.deletePhoto}/>
        ) : (
          <Icon name="image" size="big"/>
        );
      return <div key={i}>{content}</div>;
    });

    return (
      <div className={`page-padding-small ${styles.container}`}>
        <div className={styles.header}>
          <h1 className="secondary-text">{refNumber} - Post Project Reporting</h1>
        </div>
        <div className={styles['form-grid']}>
          <div className={`${styles.row} ${styles.header}`}>
            <div className="secondary-text">Items</div>
            <div className="secondary-text">Client Product Name</div>
            <div className="secondary-text">Quantity</div>
            <div className="secondary-text">Pounds(pp)</div>
            <div className="secondary-text">FMV($)</div>

            <div/>

            <div className="secondary-text">Donation</div>
            <div className="secondary-text">Recycling</div>
            <div className="secondary-text">Internal Reuse</div>
            <div className="secondary-text">Resale</div>
          </div>
        </div>

        <div className={styles.list}>
          <AutoSizer>
            {({width, height}) => (
              <List
                height={height}
                width={width}
                rowCount={metrics.length}
                rowHeight={58}
                rowRenderer={props => this.rowRenderer(props)}
                className={`${styles.overflow} focus-none`}
              />
            )}
          </AutoSizer>
        </div>

        <div>
          <button onClick={this.props.addItem} className={`${styles.add} button`}>
            <label className="ui circular icon button primary">
              <Icon name="add" />
            </label>
            <span>Add Item</span>
          </button>
        </div>

        <hr className={styles.separator}/>
        <h2 className="secondary-text">Please upload any photos of the project
          stages</h2>

        <div className={styles['upload-grid']}>
          <div className={`${styles['upload-info']} ${styles.padding}`}>
            <ImagePicker onChange={this.uploadPhotos}/>
          </div>
          {uploadLayout}
        </div>

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostReportings)
