import React, { useState, useEffect, Fragment } from 'react';
import LoadingEl from '../LoadingEl';
import { Contact } from '../../../store/typings/types';
import { Contacts } from '../../../utils/requests';
import {dateFormatter} from "../../../utils/date";

export type ClientContactsTableProps = {
  parentFilter?: {
    client?: string
  }
}

const ClientContactsTable: React.FC<ClientContactsTableProps> = (props) => {
  const { parentFilter } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contacts, setContacts] = useState<Contact[]>([]);

  useEffect(() => {
    async function fetchContacts() {
      const filter = {
          filters: {
              client: parentFilter && parentFilter.client ? {id: {$eq: parentFilter.client}} : undefined
          }
      }
      const res = await Contacts.contacts(filter);
      setContacts(res);
      setIsLoading(false)
    }
    fetchContacts();

  }, [parentFilter]);

  return (
    <Fragment>
      <LoadingEl show={isLoading} />

      <table className="table table-striped table-vertical-bordered primary-text table-fixed">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
        {
          contacts.map(contact =>
            <tr>
              <td>{contact.name}</td>
              <td>{contact.email}</td>
              <td>{contact.phone}</td>
              <td>{contact.createdAt ? dateFormatter(contact.createdAt) : '-'}</td>
            </tr>
          )
        }
        </tbody>
      </table>
    </Fragment>
  );
};

export default ClientContactsTable;
