const ROUTES_URL = {
  LOGIN: "/login",
  DASHBOARD: "/",
  NEW_PROJECT: {
    START: "/project/:id",
    INVENTORY_LIST: "/project/:id/inventory-list",
    ITEM_INFO: "/project/:id/item-info",
    TOTAL: "/project/:id/total",
    REVIEW: "/project/:id/review",
    SEND: "/project/:id/send",
    SEND_RESULT: "/project/:id/send-result",
  },
  CLIENTS: "/clients",
  LP: "/lp",
  IDM: "/input-diversion-metrics",
  METRICS: {
    START: "/metrics",
    POST_PROJECT_REPORTING: "/metrics/:id",
    RECYCLING_REPORTING: "/metrics/:id/recycling",
    CHARITY_REPORTING: "/metrics/:id/charity",
    SEND: "/metrics/:id/end",
  },
  CLIENT: {
    CLIENT_HOME:"/client/home",
    CLIENT_PROJECT:"/client/project",
  }
};

export default ROUTES_URL

export const NEW_PROJECT_ID = 'new';

export const createEditProjectURL = (id: string = NEW_PROJECT_ID): string => {
  return ROUTES_URL.NEW_PROJECT.START.replace(':id', id)
};

export const createURLwithID = (url: string, id: string): string => {
  return url.replace(':id', id)
};

export const dashboardWithProjectFilter = (title?: string): string => {
  return `${ROUTES_URL.DASHBOARD}?title=${title}`
};
