import React from 'react';
import { Modal } from 'semantic-ui-react';
import ProjectsTable, { ProjectTableProps } from '../../components/ProjectsTable';

const ProjectsModal: React.FC<ProjectTableProps> = (props) => {
  return (
    <Modal.Content>
      <Modal.Description>

        <ProjectsTable parentFilter={props.parentFilter} />

      </Modal.Description>
    </Modal.Content>
  );
};

export default ProjectsModal;
