import React, {Component, SyntheticEvent, useState} from 'react';
import {connect} from 'react-redux';
import {Button, Grid, Icon, Modal} from 'semantic-ui-react';
import {bindActionCreators} from 'redux';
import {List, AutoSizer, ListRowProps} from 'react-virtualized'
import styles from './index.module.css';

import {AppState} from '../../../store';
import RecyclingItemInfo from "../../../common/components/RecyclingItemInfo";
import RecyclingNotes from "../../../common/components/RecyclingNotes";
import {addRecyclingReportingTicket, updateRecyclingReportingTicket} from "../../../store/metrics/actions";
import RecyclingReportingEditor from "../../../common/modals/RecyclingReportingEditor";
import {RecyclingReportingTicket} from "../../../store/typings/types";

const addItem = (item: RecyclingReportingTicket) => {
  console.log(item);
  if (item.id.toString().includes('local_')) {
    return addRecyclingReportingTicket(item);
  } else {
    return updateRecyclingReportingTicket(item);
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    recyclingReporting: state.inputDiversionMetrics.recyclingReporting,
    refNumber: state.inputDiversionMetrics.project.refNumber,
    recyclingReportingGrades: state.inputDiversionMetrics.recyclingReportingGrades
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({addItem}, dispatch);
};

type Props = ReturnType<typeof mapStateToProps> & { addItem: (item: RecyclingReportingTicket) => void };
type State = {
  isAddModalOpened: boolean,
  recyclingReportingTicket: RecyclingReportingTicket | undefined
};

class RecyclingReporting extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      isAddModalOpened: false,
      recyclingReportingTicket: undefined
    };

    this.handleAddModal = (isOpen: boolean) =>  this.setState({ isAddModalOpened: !this.state.isAddModalOpened });
    this.addRecyclingReportingTicket = () => {
      this.setState({
        ...this.state,
        recyclingReportingTicket: undefined
      });
      this.handleAddModal(true);
    }
    this.editRecyclingReportingTicket = (item: RecyclingReportingTicket) => {
      this.setState({
        ...this.state,
        recyclingReportingTicket: item
      });
      this.handleAddModal(true);
    }
  }
  handleAddModal: (isOpen: boolean) => void;
  addRecyclingReportingTicket: () => void;
  editRecyclingReportingTicket: (item: RecyclingReportingTicket) => void;

  private rowRenderer({index, key, style}: ListRowProps) {
    const item = this.props.recyclingReporting[index];
    const recyclingReportingGrades = this.props.recyclingReportingGrades;
    return (
      <div style={style} key={item.id} className={`${styles.row}`}>
        <RecyclingItemInfo
          item={item}
          recyclingReportingGrades={recyclingReportingGrades}
          onEditRecyclingReportingTicket={this.editRecyclingReportingTicket}
        />
      </div>
    );
  };

  render() {
    const {recyclingReporting, refNumber} = this.props;

    return (
      <div className={`page-padding-small ${styles.container}`}>
        <div className={styles.header}>
          <h1 className="secondary-text">{refNumber} - Recycling Reporting</h1>
        </div>

        <div className={styles['form-grid']}>
          <div className={`${styles.row} ${styles.header}`}>
            <div className="secondary-text">Date on Ticket</div>
            <div className="secondary-text">Load Grade</div>
            <div className="secondary-text">Facility Ticket Number</div>
            <div className="secondary-text">Ticket Weight</div>
            <div className="secondary-text">Weight Metric</div>
            <div className="secondary-text">Cost</div>
            <div className="secondary-text">Recovery</div>
            <div className="secondary-text">Sign Off Sheet</div>
          </div>
        </div>

        <div className={styles.list}>
          <AutoSizer>
            {({width, height}) => (
              <List
                height={height}
                width={width}
                rowCount={recyclingReporting.length}
                rowHeight={52}
                className={`${styles.overflow} focus-none`}
                rowRenderer={props => this.rowRenderer(props)}
              />
            )}
          </AutoSizer>
        </div>

        <div>
          <Modal
            trigger={
              <button onClick={() => this.addRecyclingReportingTicket()} className={`${styles.add} button`}>
                <label className="ui circular icon button primary">
                  <Icon name="add"/>
                </label>
                <span>Add Another Ticket</span>
              </button>
            }
            open={this.state.isAddModalOpened}
            onClose={() => this.handleAddModal(false)}>
            <RecyclingReportingEditor
              recyclingReporting={this.state.recyclingReportingTicket}
              recyclingReportingGrades={this.props.recyclingReportingGrades}
              onChange={rr => this.props.addItem(rr)}
              close={() => this.handleAddModal(false)}
            />
          </Modal>
        </div>
        <RecyclingNotes />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecyclingReporting);
