import React, {useState, Fragment, useEffect, useMemo} from 'react';
import {RouteComponentProps, Link, Prompt, matchPath} from 'react-router-dom';
import {Button, Icon} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import styles from './index.module.css';

import RouteWithSubRoutes from '../../common/components/RouteWithSubroutes';
import LoadingEl from '../../common/components/LoadingEl';

import {RouteConfig} from '../../routing';
import ROUTES_URL, {NEW_PROJECT_ID} from '../../routing/urls';
import {PAGE_CONFIGURATIONS, STEPS_TITLE} from './page-config';

import {
  uploadProjectAction,
  resetProject
} from '../../store/projectForEdit/actions';
import {AppState} from '../../store';
import useDidUpdateEffect from '../../utils/hooks';

function getCurrentConfigIndex(url: string): number {
  const current = PAGE_CONFIGURATIONS.findIndex(el => matchPath(url, {
    path: el.url,
    exact: true
  }));
  return current > -1 ? current : 0;
}

type RouteParams = { id: string };

interface Props extends RouteComponentProps<RouteParams> {
  routes: [RouteConfig];
}

const NewProjectPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    loading,
    project,
    projectWasChanged,
    quoteWasChanged,
    quoteCoefficientWasChanged,
    emailsWasChanged
  } = useSelector((state: AppState) => state.projectForEdit);
  const projectID = project.id;
  const ifAnyFieldsWereChangedArr = [projectWasChanged,
    quoteWasChanged,
    quoteCoefficientWasChanged,
    emailsWasChanged];
  const ifAnyFieldsWereChanged = ifAnyFieldsWereChangedArr.some(el => el);

  const {location: {pathname}, match: {params: {id: paramsID}}} = props;
  const configIndex = useMemo(() => getCurrentConfigIndex(pathname), [pathname]);
  const [currentConfigIndex, setConfigIndex] = useState(configIndex);
  const [saveClicked, setsaveClicked] = useState(false);

  const [currentProjectID, setProjectID] = useState<string>(NEW_PROJECT_ID)

  const setProject = (id?: string) => dispatch(resetProject(id));

  useEffect(() => {
    if (paramsID == NEW_PROJECT_ID) {
      setProject();
      setProjectID(NEW_PROJECT_ID);
      return;
    }

    if (paramsID != currentProjectID) {
      setProject(paramsID);
      setProjectID(paramsID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsID, currentProjectID]);

  useDidUpdateEffect(() => {
    if (saveClicked) {
      exit();
    }
  }, [saveClicked]);

  const saveAndExit = () => {
    setsaveClicked(true);
    dispatch(uploadProjectAction(true))
  };

  const exit = () => {
    props.history.push(ROUTES_URL.DASHBOARD);
  };

  const nextPage = () => {
    const {url: currentURL} = PAGE_CONFIGURATIONS[currentConfigIndex];
    const {url: nextURL} = PAGE_CONFIGURATIONS[currentConfigIndex + 1];

    const sendEmails = currentURL === ROUTES_URL.NEW_PROJECT.SEND;
    const resetForm = nextURL === ROUTES_URL.DASHBOARD;

    const needSave = nextURL === ROUTES_URL.NEW_PROJECT.REVIEW;

    if (needSave) {
      dispatch(uploadProjectAction())
    }

    if (sendEmails) {
      dispatch(uploadProjectAction(resetForm, sendEmails))
    }

    if (resetForm) {
      exit()
      return;
    }

    const url = nextURL.replace(':id', currentProjectID)
    props.history.push(url);

    setConfigIndex(currentConfigIndex + 1);
  }

  const backPage = () => {
    let prevIndex = currentConfigIndex - 1 || 0;
    const {url: nextUrl} = PAGE_CONFIGURATIONS[prevIndex];
    const url = nextUrl.replace(':id', currentProjectID)
    props.history.push(url);
    setConfigIndex(prevIndex);
  }

  const currentProgressTitle = PAGE_CONFIGURATIONS[currentConfigIndex].progressTitle;
  const disableNext = useMemo<boolean>(() => {
    const config = PAGE_CONFIGURATIONS[currentConfigIndex];

    if (typeof config.canGoNext !== 'function') {
      return false;
    }

    return !config.canGoNext(project)
  }, [currentConfigIndex, project])

  return (
    <div className={styles.content}>

      <Prompt
        when={!saveClicked && ifAnyFieldsWereChanged}
        message={location =>
          location.pathname.startsWith("/project") && location.pathname !== "/project/new"
            ? true
            : 'Are you sure you want to exit without saving?'
        }
      />

      <LoadingEl show={loading}/>
      {
        !loading &&
        <Fragment>
          <div className={styles.header}>

            <Link to={ROUTES_URL.DASHBOARD} className={styles.home}>
              <Icon name="home" className="primary-text" size="big"/>
            </Link>

            <div className={styles.stepper}>
              {Object.values(STEPS_TITLE).map((el, i, arr) => (
                <Fragment key={i}>
                  <div
                    className={`${styles.step} ${currentProgressTitle === el ? 'active' : ''}`}>
                    <div className={styles['step-marker']}/>
                    <div className={styles['step-label']}>{el}</div>
                  </div>

                  {i + 1 !== arr.length &&
                  <div className={styles['step-connector']}/>}
                </Fragment>
              ))}
            </div>
          </div>

          <div className={styles.wrapper}>
            {props.routes.map((route, i) => <RouteWithSubRoutes
              key={projectID + i} {...route} />)}
          </div>

          <div className={styles.footer}>
            <div>
              {currentConfigIndex > 0 && (
                <Button className={styles['inverted-btn']} onClick={backPage}>
                  Back
                </Button>
              )}
            </div>

            <div>
              <Button className={styles['inverted-btn']} onClick={saveAndExit}>
                Save & Exit
              </Button>

              <Button className="primary" onClick={nextPage}
                      disabled={disableNext}>
                {PAGE_CONFIGURATIONS[currentConfigIndex].nextBtnTitle}
              </Button>
            </div>
          </div>
        </Fragment>
      }
    </div>
  );
};

export default NewProjectPage;
