import React, {useMemo} from 'react';
import {Grid, Input, Button, Form, Icon} from 'semantic-ui-react';
import {useFormState} from 'react-use-form-state';
import {useDispatch} from 'react-redux';
import styles from './index.module.css';
import moment from 'moment';

import DatePickerCustom from "../DatePickerCustom";
import DropdownSelect from "../DropdownSelect";
import {Currency, Photo, Project, RecyclingReportingGrade} from "../../../store/typings/types";
import {
  removeRecyclingReportingTicket,
  updateRecyclingReportingTicket
} from "../../../store/metrics/actions";
import {createPhoto} from "../../../store/data";
import ImageUpload from "../ImageUpload";
import {getImageUrlForPhoto} from "../../../utils/image";
import SearchDropdown from "../SearchDropdown";

type FormValues = {
  project: Project;
  dateOnTicket: Date;
  loadGrade: RecyclingReportingGrade;
  facilityTicketNumber: string;
  ticketWeight: number;
  weightMetric: string;
  ticketCost: number;
  ticketRecovery: number;
  nonRecoverableWeightInPounds: number;
  signOfSheetImage?: Photo | null;
};

type Props = {
  item: {
    id: string;
    project: Project;
    dateOnTicket: Date;
    loadGrade: RecyclingReportingGrade;
    facilityTicketNumber: string;
    ticketWeight: number;
    weightMetric: string;
    ticketCost: number;
    ticketRecovery: number;
    nonRecoverableWeightInPounds: number;
    signOfSheetImage?: Photo | undefined;
  };
  recyclingReportingGrades: RecyclingReportingGrade[],
  onEditRecyclingReportingTicket: any
};

const Index: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {item, recyclingReportingGrades, onEditRecyclingReportingTicket} = props;

  const itemToForm = (): FormValues => {
    const {
      project,
      dateOnTicket,
      loadGrade,
      facilityTicketNumber,
      ticketWeight,
      weightMetric,
      ticketCost,
      ticketRecovery,
      nonRecoverableWeightInPounds,
      signOfSheetImage
    } = item;

    return {
      project,
      dateOnTicket,
      loadGrade,
      facilityTicketNumber,
      ticketWeight,
      weightMetric,
      ticketCost,
      ticketRecovery,
      nonRecoverableWeightInPounds,
      signOfSheetImage
    }
  };

  const onDelete = () => dispatch(removeRecyclingReportingTicket(props.item));
  const formValues = itemToForm();

  const calculateWeightInPounds = (ticketWeight: number, weightMetric: string) => {
    if (ticketWeight && weightMetric) {
      let weightInPounds;
      switch (weightMetric) {
        case 'Kilogram':
          weightInPounds = ticketWeight * 2.2;
          break;
        case 'Gram':
          weightInPounds = ticketWeight * 0.0022;
          break;
        case 'Metric Tonne':
          weightInPounds = ticketWeight / 0.0004535923700100354;
          break;
        case 'USA Ton':
          weightInPounds = ticketWeight * 2000
          break;
        default: // pound
          weightInPounds = ticketWeight;
      }

      return weightInPounds;
    }
    return 0;
  };

  const calculateNonRecoverableWeightInPounds = (loadGrade: RecyclingReportingGrade, ticketWeight: number, weightMetric: string, weightInPounds: number) => {
    if (loadGrade && ticketWeight && weightMetric) {
      if (loadGrade.nonRecoveryPercent) {
        return (weightInPounds / 100) * loadGrade.nonRecoveryPercent;
      }
      return 0; // there is no recoverable due to load grande nonRecoveryPercent -> 0
    }
    return 0;
  };

  const calculateRecoverableWeightInPounds = (loadGrade: RecyclingReportingGrade, ticketWeight: number, weightMetric: string, weightInPounds: number) => {
    if (loadGrade && ticketWeight && weightMetric) {
      if (loadGrade.recoveryPercent) {
        return (weightInPounds / 100) * loadGrade.recoveryPercent;
      }
      return 0; // there is no recoverable due to load grande nonRecoveryPercent -> 0
    }
    return 0;
  };

  const [formState, {number, raw, text}] = useFormState<FormValues>(formValues, {
    onChange(e, stateValues, nextStateValues) {
      const {dateOnTicket, loadGrade, facilityTicketNumber, ticketWeight, weightMetric, ticketCost, ticketRecovery, nonRecoverableWeightInPounds, signOfSheetImage} = nextStateValues;
      const weightInPounds = calculateWeightInPounds(+ticketWeight, weightMetric);
      const updated = {
        ...props.item,
        dateOnTicket,
        loadGrade: loadGrade,
        facilityTicketNumber,
        ticketWeight: +ticketWeight,
        weightMetric,
        ticketCost: +ticketCost,
        ticketRecovery: +ticketRecovery,
        weightInPounds,
        recoverableWeightInPounds: calculateRecoverableWeightInPounds(loadGrade, +ticketWeight, weightMetric, weightInPounds),
        nonRecoverableWeightInPounds: calculateNonRecoverableWeightInPounds(loadGrade, +ticketWeight, weightMetric, weightInPounds),
        signOfSheetImage: signOfSheetImage || undefined
      };
      console.log(signOfSheetImage)
      console.log(props.item.signOfSheetImage)
      console.log(item.signOfSheetImage)
      console.log(updated)
      dispatch(updateRecyclingReportingTicket(updated))
    }
  });

  const {values: {loadGrade, weightMetric, signOfSheetImage} }= formState;

  const weightMetricOptions = [
    {text: 'Kilogram', value: 'Kilogram'},
    {text: 'Gram', value: 'Gram'},
    {text: 'Metric Tonne', value: 'Metric Tonne'},
    {text: 'USA Ton', value: 'USA Ton'},
    {text: 'Pound', value: 'Pound'}
  ];

  const {loadGradeDropdown, weightMetricDropdown} = useMemo(() => {
    const loadGradeSelected = loadGrade ? {
      text: loadGrade.title,
      value: loadGrade.id,
      item: loadGrade,
    } : undefined;

    const loadGradeItems = recyclingReportingGrades.map(el => ({text: el.title, value: el.id, item: el}));

    const weightMetricSelected = weightMetric ? {
      text: weightMetric,
      value: weightMetric,
      item: weightMetric,
    } : undefined;
    const weightMetricItems = weightMetricOptions.map(el => ({text: el.text, value: el.value, item: el}));

    return {
      loadGradeDropdown: {
        loadGradeSelected,
        loadGradeItems
      },
      weightMetricDropdown: {
        weightMetricSelected,
        weightMetricItems
      }
    }
  }, [loadGrade, recyclingReportingGrades, weightMetric, weightMetricOptions]);

  const imgUrl = useMemo(() => {
    if (!item.signOfSheetImage) {
      return '';
    }
    if (item.signOfSheetImage.localFile) {
      return URL.createObjectURL(item.signOfSheetImage.localFile)
    }
    return getImageUrlForPhoto(item.signOfSheetImage);
  }, [item.signOfSheetImage]);

  return (
    <React.Fragment>
      <div>
        {moment(item.dateOnTicket).format('MM/DD/YYYY')}
      </div>
      <div>
        {item.loadGrade.title}
      </div>
      <div>
        {item.facilityTicketNumber}
      </div>
      <div>
        {item.ticketWeight}
      </div>
      <div>
        {item.weightMetric}
      </div>
      <div>
        {item.ticketCost}
      </div>
      <div>
        {item.ticketRecovery}
      </div>
      <div>
        {
          item.signOfSheetImage && (
            <ImageUpload
              {...raw({
                name: 'signOfSheetImage',
                onChange: (files: File[]) => files.length ? createPhoto(files[0]) : null
              })}
              url={imgUrl}
              viewOnly={true}
            />
          )
        }
        {
          !item.signOfSheetImage && (
            <span>-</span>
          )
        }
      </div>
      <div>
        <Button circular icon="edit" className={styles.delete}
                onClick={() => onEditRecyclingReportingTicket(item)}/>
        <Button circular icon="close" className={styles.delete}
                onClick={onDelete}/>
      </div>
    </React.Fragment>
  );
};

export default Index;
