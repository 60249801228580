import { Photo } from "../store/typings/types";
import { API_URL } from "../config";

export function getImageUrlForPhoto(photo: Photo): string {
  return getImageUrl(photo.url)
}

export function getImageUrl(url: string): string {
  if (!url.length) {
    return '';
  }
  return JSON.parse(JSON.stringify(API_URL)).replace('/api', '') + url.replace('http://localhost:1337', '')
}
