import {
  Project,
  Client,
  LogisticPartner,
  InventoryListItem,
  Currency,
  QuoteCoefficients,
  SendData,
  Photo,
  InventoryItem,
  ProjectOwner,
  Contact
} from '../typings/types';

export interface ProjectForEditState {
  loading: boolean;
  project: Project;
  originalProject: Project | undefined;
  projectWasChanged: boolean;
  quoteWasChanged: boolean;
  quoteCoefficientWasChanged: boolean;
  emailsWasChanged: boolean;
  dropdownItems: {
    clients: Client[],
    lps: LogisticPartner[],
    inventoryItems: InventoryItem[],
    clientContacts: Contact[]
  }
}

export const EMAIL_SEPARATOR: string = ';';

export interface ProjectUpdateInfoPlayload {
  uploaded: boolean;
  refNumber?: number;
  id?: string;
  title: string;
  client: Client | undefined;
  wlProvider: Client | undefined;
  address: string;
  date: string;
  lp: LogisticPartner | undefined;
  isProjectDateTBD: boolean;
  netPaymentTerms: string | undefined;
  projectOwner?: ProjectOwner | undefined;
  clientContact?: Contact | undefined;
}

export interface ProjectUpdateQuotePlayload {
  currency: Currency;
  lpQuote: number;
  transferOfTitle: number;
  recovery: string;
  includeMoveMgmt: boolean;
  resold: number;
  quoteCoefficients: QuoteCoefficients;
  manualMoveManagement?: number;
}

export interface ProjectSetFromServerPayload {
  field: 'quote' | 'coefficients' | 'listitem',
  id: string;
  oldId?: string;
}

export type ProjectUpdateEmailsPayload = SendData & {
  uploaded: boolean,
  id: string
}

export type ProjectResetPayload = {
  project: Project,
  dropdownItems: {
    clients: Client[],
    lps: LogisticPartner[],
    inventoryItems: InventoryItem[],
    clientContacts: Contact[]
  }
}

export const PROJECT_TOGGLE_LOADING = 'PROJECT_TOGGLE_LOADING';
export const PROJECT_REPLACE_ITEMS = 'PROJECT_REPLACE_ITEMS';
export const PROJECT_UPDATE_ITEM_BY_ID = 'PROJECT_UPDATE_ITEM_BY_ID';
export const PROJECT_ADD_ITEM = 'PROJECT_ADD_ITEM';
export const PROJECT_DELETE_ITEM = 'PROJECT_DELETE_ITEM';
export const PROJECT_ADD_ITEM_IMG = 'PROJECT_ADD_ITEM_IMG';
export const PROJECT_UPDATE_ITEM_IMG = 'PROJECT_UPDATE_ITEM_IMG';
export const PROJECT_DELETE_ITEM_IMG = 'PROJECT_DELETE_ITEM_IMG';

export const PROJECT_UPDATE_INFO = 'PROJECT_UPDATE_INFO';
export const PROJECT_UPDATE_QUOTE = 'PROJECT_UPDATE_QUOTE';
export const PROJECT_UPDATE_EMAILS = 'PROJECT_UPDATE_EMAILS';
export const PROJECT_RESET = 'PROJECT_RESET';
export const PROJECT_SET_FROM_SERVER = 'PROJECT_SET_FROM_SERVER';
export const PROJECT_REPLACE_CLIENT_CONTACTS_ITEMS = 'PROJECT_REPLACE_CLIENT_CONTACTS_ITEMS';

interface ProjectUpdateInfoAction {
  type: typeof PROJECT_UPDATE_INFO;
  payload: ProjectUpdateInfoPlayload;
}

interface ProjectUpdateItemByIdAction {
  type: typeof PROJECT_UPDATE_ITEM_BY_ID;
  payload: InventoryListItem;
}

interface ProjectReplaceItemsAction {
  type: typeof PROJECT_REPLACE_ITEMS;
  payload: InventoryListItem[];
}

interface ProjectAddItemAction {
  type: typeof PROJECT_ADD_ITEM;
}

interface ProjectDeleteItemAction {
  type: typeof PROJECT_DELETE_ITEM;
  payload: {
    id: string;
  };
}

interface ProjectAddItemImgAction {
  type: typeof PROJECT_ADD_ITEM_IMG;
  payload: {
    itemId: string;
    photos: Photo[];
  };
}

interface ProjectUpdateItemImgAction {
  type: typeof PROJECT_UPDATE_ITEM_IMG;
  payload: {
    itemId: string;
    oldImgId: string;
    photo: Photo;
  };
}

interface ProjectDeleteItemImgAction {
  type: typeof PROJECT_DELETE_ITEM_IMG;
  payload: {
    itemId: string;
    imgId: string;
  };
}

interface ProjectUpdateQuoteAction {
  type: typeof PROJECT_UPDATE_QUOTE;
  payload: ProjectUpdateQuotePlayload;
}

interface ProjectUpdateEmailsAction {
  type: typeof PROJECT_UPDATE_EMAILS;
  payload: ProjectUpdateEmailsPayload;
}

interface ProjectSetFromServerAction {
  type: typeof PROJECT_SET_FROM_SERVER;
  payload: ProjectSetFromServerPayload;
}

interface ProjectResetAction {
  type: typeof PROJECT_RESET;
  payload: ProjectResetPayload;
}

interface ProjectToggleLoadingAction {
  type: typeof PROJECT_TOGGLE_LOADING;
  payload?: boolean;
}

interface ProjectClientContactsReplaceItemsAction {
  type: typeof PROJECT_REPLACE_CLIENT_CONTACTS_ITEMS;
  payload: Contact[];
}

export type ProjectForEditActionTypes =
  | ProjectAddItemAction
  | ProjectDeleteItemAction
  | ProjectUpdateItemByIdAction
  | ProjectReplaceItemsAction
  | ProjectUpdateInfoAction
  | ProjectUpdateQuoteAction
  | ProjectSetFromServerAction
  | ProjectResetAction
  | ProjectUpdateEmailsAction
  | ProjectUpdateItemImgAction
  | ProjectDeleteItemImgAction
  | ProjectToggleLoadingAction
  | ProjectAddItemImgAction
  | ProjectClientContactsReplaceItemsAction;
