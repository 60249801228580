import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import styles from './index.module.css';

import InventoryItemDetails from '../../../common/components/InventoryItemDetails';
import ImagePicker from '../../../common/components/ImagePicker';
import PhotoEl from '../../../common/components/PhotoEl';

import { AppState } from '../../../store';
import { InventoryListItem, Photo } from '../../../store/typings/types';
import { projectUploadItemPhotos, projectDeleteItemImg } from '../../../store/projectForEdit/actions';

const UPLOAD_IMAGE_COUNT = 12;
const IMAGE_ARRAY = Array(UPLOAD_IMAGE_COUNT).fill(0);

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({
    projectUploadItemPhotos: projectUploadItemPhotos,
    projectDeleteItemImg: projectDeleteItemImg
  }, dispatch);
};

const mapStateToProps = (state: AppState) => ({
  inventoryItems: state.projectForEdit.project.quote.inventoryListItems
});

type Props = ReturnType<typeof mapStateToProps> & {
  projectUploadItemPhotos: (files: File[], itemId: string) => void,
  projectDeleteItemImg: (photo: Photo, itemId: string) => void
};
type State = { selectedItemId: string | undefined };

class ItemInfoPage extends Component<Props, State> {
  changeSelectedElement: (item: InventoryListItem) => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedItemId: undefined
    };

    this.changeSelectedElement = (item) => this.setState({ selectedItemId: item.id });
  }

  uploadPhotos = (files: File[]) => {
    if (!files.length) {
      return;
    }
    const item = this.getItemById();

    if (!item) {
      return
    }

    const couldAddFilesCount = UPLOAD_IMAGE_COUNT - item.photos.length

    if (1 > couldAddFilesCount) {
      return
    }

    const validFiles = files.slice(0, couldAddFilesCount);
    this.props.projectUploadItemPhotos(validFiles, item.id)
  }

  deletePhoto = (photo: Photo) => {
    let id = this.state.selectedItemId;
    if (id) {
      this.props.projectDeleteItemImg(photo, id)
    }
  }

  getItemById = (): InventoryListItem | undefined => {
    if (this.state.selectedItemId) {
      return this.props.inventoryItems.find(el => el.id === this.state.selectedItemId)
    }

    return undefined;
  }

  private rowRenderer = ({ index, key, style }: ListRowProps) => {
    const item = this.props.inventoryItems[index];
    return (
      <div className={`${styles.row} ${styles.listItem}`} key={item.id} style={style}>
        <InventoryItemDetails
          item={item}
          onUploadClick={this.changeSelectedElement}
          active={item.id === this.state.selectedItemId} />
      </div >
    );
  };

  render() {
    const { inventoryItems } = this.props;
    const { selectedItemId } = this.state;

    const item = this.getItemById()
    const uploadLayout = IMAGE_ARRAY.map((el, i) => {
      const content =
        item && item.photos[i] ? (
          <PhotoEl photo={item.photos[i]} onClose={this.deletePhoto} />
        ) : (
            <Icon name="image" size="big" />
          );
      return <div key={i}>{content}</div>;
    });

    return (
      <div className={`page-padding-small ${styles.container}`}>
        <h1 className="secondary-text">Company</h1>
        <div className={styles['form-grid']}>
          <div className={`${styles.row} ${styles.header}`}>
            <div className="secondary-text">Items</div>
            <div className="secondary-text">Quantity</div>
            <div className="secondary-text">Weight</div>
            <div className="secondary-text">$Value</div>

            <div />

            <div className="secondary-text">Donation</div>
            <div className="secondary-text">Recycling</div>
            <div className="secondary-text">Internal Reuse</div>
            <div className="secondary-text">Resale</div>
          </div>
        </div>

        <div className={styles.list}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                height={height}
                width={width}
                rowCount={inventoryItems.length}
                rowHeight={58}
                rowRenderer={props => this.rowRenderer(props)}
              />
            )}
          </AutoSizer>
        </div>

        <hr className={styles.separator} />

        {
          selectedItemId &&
          <div className={styles['upload-grid']}>
            <div className={`${styles['upload-info']} ${styles.padding}`}>
              <ImagePicker onChange={this.uploadPhotos} />
            </div>

            {uploadLayout}
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemInfoPage);
