import React from 'react';
import { Modal } from 'semantic-ui-react';
import ClientContactsTable , { ClientContactsTableProps } from '../../components/ClientContactsTable';

const ClientContactsModal: React.FC<ClientContactsTableProps> = (props) => {
  return (
    <Modal.Content>
      <Modal.Description>
        <ClientContactsTable parentFilter={props.parentFilter} />
    </Modal.Description>
    </Modal.Content>
  );
};

export default ClientContactsModal;
