import React, { useState, SyntheticEvent, useRef, useEffect } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { debounce } from 'lodash';
import styles from './index.module.css';

export type SearchDropdownItem = {
  text: string;
  value: string;
  item: object;
}

interface Props {
  placeholder?: string;
  disabled?: boolean;
  selected?: SearchDropdownItem;
  defaultOptions: SearchDropdownItem[];
  className?: string;
  onAdd?: (text: string) => Promise<SearchDropdownItem>;
  onChange?: (item?: object) => void;
  onSearch?: (query: string) => Promise<SearchDropdownItem[]>;
  onBlur?: (e: SyntheticEvent) => void;
}
const SearchDropdown: React.FC<Props> = (props) => {
  const { placeholder, selected, defaultOptions, onChange, onSearch, onAdd, onBlur, className, disabled } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const calculateDropdownOptions = () => {
    if (!selected) {
      return defaultOptions;
    }

    const selectedExistInOptions = defaultOptions.findIndex(el => el.value === selected.value)

    if (selectedExistInOptions > -1) {
      return defaultOptions;
    } else {
      //To prevent selected add on reset clientContacts
      if (selected.item.hasOwnProperty('client')) {
        return [...defaultOptions];
      }
      return [...defaultOptions, selected];
    }
  }
  const dropdownOptions = calculateDropdownOptions();
  const [options, setOptions] = useState<SearchDropdownItem[]>(dropdownOptions);

  useEffect(() => {
    const arr = calculateDropdownOptions()
    setOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const localOnChange = async (e: SyntheticEvent, data: DropdownProps) => {

    if (typeof onChange !== 'function') {
      return;
    }

    let selected = options.find(el => el.value === data.value);

    if (!selected && typeof onAdd === 'function') {
      selected = await onAdd(data.value as string);
      setOptions([selected, ...defaultOptions])
    }

    if (selected) {
      onChange(selected.item);
    }
  }

  const localOnSearch = async (text: string) => {
    if (typeof onSearch !== 'function' || isLoading) {
      return;
    }
    setIsLoading(true);
    const options = await onSearch(text);
    setOptions(options);
    setIsLoading(false)
  }
  const debouncedSearch = useRef(debounce(localOnSearch, 300)).current

  return (
    <Dropdown
      fluid
      selection
      search
      className={`${styles.dropdown} ${className}`}
      allowAdditions={!!onAdd}
      options={options}
      value={selected ? selected.value : ''}
      placeholder={placeholder}
      onChange={localOnChange}
      onBlur={onBlur}
      onSearchChange={(e, data) => debouncedSearch(data.searchQuery)}
      disabled={isLoading || disabled}
      loading={isLoading} />
  );
};

export default SearchDropdown;
