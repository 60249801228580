import ROUTES_URL from "../../routing/urls";
import {Metric} from "../../store/typings/types";
import {getUserRole, ROLES} from "../../utils/user";

const { START, POST_PROJECT_REPORTING, RECYCLING_REPORTING, CHARITY_REPORTING, SEND  } = ROUTES_URL.METRICS;

type PageConfiguration = {
  url: string;
  progressTitle: string;
  nextBtnTitle: string;
  canGoNext?: (project: Metric[]) => boolean;
};

const areValidMetrics = (metrics: Metric[]): boolean => {
  return !metrics.map(({ inventoryItem, quantity, recycling, donation, internalReuse, resale }) => {
      return inventoryItem && quantity === recycling + donation + internalReuse + resale;
    }).filter(el => !el).length;
};

const role = getUserRole();

export const STEPS_TITLE = {
  PROJ_REPORTING: 'Input Values',
  REC_REPORTING: 'Inventory tickets',
  CHAR_REPORTING: 'Charity Sheets'
};

export const PAGE_CONFIGURATIONS: PageConfiguration[] = [
  {
    url: POST_PROJECT_REPORTING,
    progressTitle: STEPS_TITLE.PROJ_REPORTING,
    nextBtnTitle: 'Next',
    canGoNext: areValidMetrics
  },
  {
    url: RECYCLING_REPORTING,
    progressTitle: STEPS_TITLE.REC_REPORTING,
    nextBtnTitle: 'Next'
  },
  {
    url: CHARITY_REPORTING,
    progressTitle: STEPS_TITLE.CHAR_REPORTING,
    nextBtnTitle: role === ROLES.SYSTEM_MANAGER ? 'Approve' : 'Submit',
  },
  {
    url: ROUTES_URL.IDM,
    progressTitle: '',
    nextBtnTitle: '',
  }
];
