import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './index.module.css';
import Logo from '../../assets/logo.png';

import ProjectsTable from '../../common/components/ProjectsTable';
import { AppState } from '../../store';

const HomePage: React.FC = () => {
  const { searchQuery } = useSelector((state: AppState) => state.projects);
  const parentFilter = useMemo(() => ({ title: searchQuery }), [searchQuery]);

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <img src={Logo} className={styles.logo} draggable={false} alt="eco solutions" />
      </div>

      {/* <div className={styles.grid}>{CARDS.map((card, index) => <Card key={index} {...card} />)}</div> */}
      <div className="page-padding-small">
        <ProjectsTable parentFilter={parentFilter} />
      </div>
    </div>
  );
};

export default HomePage;
