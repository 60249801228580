import React, { Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import styles from './index.module.css';

import { AppState } from '../../../store';

const SendResultPage: React.FC = () => {
  const { quote: { senddata: { to, cc, bcc } } } = useSelector((state: AppState) => state.projectForEdit.project);
  return (
    <div className={styles.container}>
      <div className={`${styles.icon} primary-text`}>
        <Icon name="check circle" size="big" />
      </div>
      <h1 className="secondary-text">Success, the quote has been sent to:</h1>
      <div className={styles['email-container']}>
        {to}
      </div>

      {cc && (
        <Fragment>
          <h1 className="secondary-text">CC'd on the email is:</h1>
          <div className={styles['email-container']}>
            <div>{cc}</div>
          </div>
        </Fragment>
      )}

      {bcc && (
        <Fragment>
          <h1 className="secondary-text">BCC'd on the email is:</h1>
          <div className={styles['email-container']}>
            <div>{bcc}</div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default SendResultPage;
