import {
  Project,
} from '../typings/types';

export interface ProjectsState {
  searchQuery: string;
  dropdownItems: {
    projects: Project[];
  }
  selectedID: string;
}

export const PROJECTS_FILTER = 'PROJECTS_CHANGE_FILTER';
export const PROJECTS_DROPDOWN = 'PROJECTS_DROPDOWN';
export const PROJECTS_ID = 'PROJECTS_ID';

interface ProjectEditFilterAction {
  type: typeof PROJECTS_FILTER;
  payload: string;
}

interface ProjectIDAction {
  type: typeof PROJECTS_ID;
  payload: string;
}

interface ProjectDropdownAction {
  type: typeof PROJECTS_DROPDOWN;
  payload:  Project[];
}

export type ProjectsActionTypes =
  | ProjectEditFilterAction
  | ProjectIDAction
  | ProjectDropdownAction;
