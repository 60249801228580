import React from 'react';
import styles from "./index.module.css";

 
const ClientFooter: React.FC = () => {
	return (
		<div className={ styles.footerWrapper}>
      <p>© ecosolutions All rights reserved </p>
    </div>
  );
};

export default ClientFooter;
