import React, {
  ChangeEvent,
  Fragment,
  createRef, useState
} from 'react';
import {Button, Icon, Modal} from 'semantic-ui-react';
import styles from './index.module.css';
import {Prompt} from "react-router-dom";

interface Props {
  onChange: (files: File[]) => void;
  url?: string;
  viewOnly?: boolean
}

const ImageUpload: React.FC<Props> = (props) => {
  const dropContainer = createRef<HTMLInputElement>();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length) {
      notify(Array.from(files))
    }
  }

  const notify = (files: File[]) => {
    props.onChange(files);
  };

  const removeImage = () => {
    if (window.confirm('Are you sure you want to remove image?')) {
      notify([]);
    }
  };

  return (
    <div>
      {
        !props.url &&
        <Fragment>
          <input className={styles.hide} ref={dropContainer} type='file' onChange={onChange} accept="image/*" />
          <Button
            className={`primary-text clear ${styles.btn} ${styles.btnImageUploadWarn}`}
            size="massive"
            onClick={() => dropContainer.current ? dropContainer.current.click() : ''}>
            <span>Upload Image</span>
            <i aria-hidden="true" className="warning icon"></i>
          </Button>
        </Fragment>

      }

      {
        !!props.url && (
          <Fragment>
            <Button
              className={`${styles.btn}`}
              size="massive">
              <Modal trigger={<Icon className={`${styles.iconView}`} name='eye'/>} basic size='small'>
                <Modal.Content>
                  <img src={props.url} alt="Selected" className={styles.image} />
                </Modal.Content>
              </Modal>
              {
                !props.viewOnly && (
                  <Icon className={`${styles.iconClose}`} name='close' onClick={removeImage}/>
                )
              }
            </Button>
          </Fragment>
        )
      }

    </div>
  );
};

export default ImageUpload;
