import React, { useState, Fragment, useEffect, useMemo } from 'react';
import { RouteComponentProps, Link, Prompt, matchPath } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.css';
import { NotificationManager } from 'react-notifications';


import RouteWithSubRoutes from '../../common/components/RouteWithSubroutes';
import LoadingEl from '../../common/components/LoadingEl';

import { RouteConfig } from '../../routing';
import ROUTES_URL, { createURLwithID } from '../../routing/urls';
import { PAGE_CONFIGURATIONS, STEPS_TITLE } from './page-config';

import { AppState } from '../../store';
import {ProjectDropdownAction} from "../../store/projects/actions";
import {getUser, getUserRole, ROLES} from "../../utils/user";
import {
  setProjectMetricsStatus,
  updateInputDiversionMetrics
} from "../../store/metrics/actions";
import useDidUpdateEffect from "../../utils/hooks";
import {PROJECT_METRICS_STATUSES} from "../../utils/project";

function getCurrentConfigIndex(url: string): number {
  const current = PAGE_CONFIGURATIONS.findIndex(el => matchPath(url, { path: el.url, exact: true }));
  return current > -1 ? current : 0;
}

type RouteParams = { id: string };

interface Props extends RouteComponentProps<RouteParams> {
  routes: [RouteConfig];
}

const NewMetrics: React.FC<Props> = (props) => {

  const dispatch = useDispatch();
  const { loading } = useSelector((state: AppState) => state.projectForEdit);
  const { metrics, recyclingReporting, project } = useSelector((state: AppState) => state.inputDiversionMetrics);
  const {selectedID, dropdownItems: {projects}} = useSelector((state: AppState) => state.projects);

  const { location: { pathname } } = props;
  const configIndex = useMemo(() => getCurrentConfigIndex(pathname), [pathname]);
  const [currentConfigIndex, setConfigIndex] = useState(configIndex);
  const [isIDMInfoChanged, setIsIDMInfoChanged] = useState(false);
  const [isSaveAndExit, setIsSaveAndExit] = useState(false);
  const user = getUser();
  const role = getUserRole();

  const getDropdownProjects = (LP: string) => dispatch(ProjectDropdownAction(LP));

  useEffect(() => {
    if (user.type === ROLES.LOGISTIC_PARTNER && !user.lp) { return; }
    getDropdownProjects(user.type === ROLES.LOGISTIC_PARTNER ? user.lp : '');
  },[]);

  useEffect(() => {
    const isIDMChanged = !!metrics.find(e => e.localeWasEdited) || !!recyclingReporting.find(e => e.localeWasEdited);
    setIsIDMInfoChanged(isIDMChanged);
  }, [metrics, recyclingReporting]);

  useEffect(() => {
    if (props.location.pathname === ROUTES_URL.METRICS.START) {
      setConfigIndex(0);
    }
  }, [pathname]);

  useDidUpdateEffect(() => {
    if (isSaveAndExit) {
      exit();
    }
  }, [isSaveAndExit]);

  const saveAndExit = () => {
    setIsSaveAndExit(true);
    if (project.metricsStatus === 'NotSubmitted') {
      dispatch(setProjectMetricsStatus(PROJECT_METRICS_STATUSES.DRAFT));
    }
    dispatch(updateInputDiversionMetrics());
  };

  const exit = () => {
    props.history.push(ROUTES_URL.IDM);
  };

  const nextPage = () => {
    const { url: nextURL } = PAGE_CONFIGURATIONS[currentConfigIndex + 1];

    const resetForm = nextURL === ROUTES_URL.IDM;

    if (resetForm) {
      setIsSaveAndExit(true);
      if (role === ROLES.SYSTEM_MANAGER) {
        dispatch(setProjectMetricsStatus(PROJECT_METRICS_STATUSES.APPROVED));
      } else {
        dispatch(setProjectMetricsStatus(PROJECT_METRICS_STATUSES.SUBMITTED));
      }
      dispatch(updateInputDiversionMetrics());
      exit();
      return;
    }

    if(selectedID !== ''){
      //const project = projects.find(ele => ele.id === selectedID);
      //if (project) {
        props.history.push(createURLwithID(nextURL,selectedID));
        setConfigIndex(currentConfigIndex + 1);
      //}
    }

  };

  const backPage = () => {
    let prevIndex = currentConfigIndex - 1 || 0;
    const { url: nextUrl } = PAGE_CONFIGURATIONS[prevIndex];
    props.history.push(createURLwithID(nextUrl, selectedID));
    setConfigIndex(prevIndex);
  };

  const currentProgressTitle = PAGE_CONFIGURATIONS[currentConfigIndex].progressTitle;
  const disableNext = useMemo<boolean>(() => {
    const config = PAGE_CONFIGURATIONS[currentConfigIndex];

    if (typeof config.canGoNext !== 'function') {
      return false;
    }

    return !config.canGoNext(metrics)
  }, [currentConfigIndex, metrics])

  return (
    <div className={styles.content}>

      <Prompt
        when={!isSaveAndExit && isIDMInfoChanged}
        message={location =>
          location.pathname.startsWith("/metrics")
            ? true
            : `Are you sure you want to exit without saving??`
        }
      />

      <LoadingEl show={loading} />
      {
        !loading &&
        <Fragment>

          <div className={styles.header}>

            <Link to={ROUTES_URL.DASHBOARD} className={styles.home}>
              <Icon name="home" className="primary-text" size="big" />
            </Link>

            <div className={styles.stepper}>
              {Object.values(STEPS_TITLE).map((el, i, arr) => (
                <Fragment key={i}>
                  <div className={`${styles.step} ${currentProgressTitle === el ? 'active' : ''}`}>
                    <div className={styles['step-marker']} />
                    <div className={styles['step-label']}>{el}</div>
                  </div>

                  {i + 1 !== arr.length && <div className={styles['step-connector']} />}
                </Fragment>
              ))}
            </div>
          </div>

          <div className={styles.wrapper}>
            {props.routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)}
          </div>

          <div className={styles.footer}>
            <div>
              {currentConfigIndex > 0 && (
                <Button className={styles['inverted-btn']} onClick={backPage} disabled={disableNext}>
                  Back
                </Button>
              )}
            </div>

            <div>
              <Button className={styles['inverted-btn']} onClick={saveAndExit} disabled={disableNext}>
                Save & Exit
              </Button>

              <Button className="primary" onClick={nextPage} disabled={disableNext}>
                {PAGE_CONFIGURATIONS[currentConfigIndex].nextBtnTitle}
              </Button>
            </div>
          </div>
        </Fragment>
      }
    </div>
  );
};

export default NewMetrics;
