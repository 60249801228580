import React, { useMemo } from 'react';
import { Button } from 'semantic-ui-react';
import styles from './index.module.css';

import { Photo } from '../../../store/typings/types';
import { getImageUrl } from '../../../utils/image';

export type PhotoElProps = {
  photo: Photo;
  onClose: (photo: Photo) => void
};
const PhotoEl: React.FC<PhotoElProps> = (props) => {
  const { photo: { localFile, url }, onClose } = props;

  const imgURL = useMemo(() => {
    if (localFile) {
      return URL.createObjectURL(localFile)
    }

    return getImageUrl(url);
  }, [url, localFile])

  return (
    <div className={styles.container}>
      <Button
        className={`primary-text clear ${styles.btn}`}
        size="massive"
        icon="close"
        circular={true}
        onClick={() => onClose(props.photo)} />
      <img src={imgURL} className={styles.img} alt="" />
    </div>
  );
};

export default PhotoEl;
