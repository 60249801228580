import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  ProjectForEditActionTypes,
  PROJECT_ADD_ITEM,
  PROJECT_UPDATE_ITEM_BY_ID,
  PROJECT_UPDATE_INFO,
  ProjectUpdateInfoPlayload,
  ProjectUpdateQuotePlayload,
  PROJECT_UPDATE_QUOTE,
  PROJECT_REPLACE_ITEMS,
  PROJECT_UPDATE_EMAILS,
  PROJECT_RESET,
  PROJECT_UPDATE_ITEM_IMG,
  PROJECT_ADD_ITEM_IMG,
  PROJECT_DELETE_ITEM,
  PROJECT_DELETE_ITEM_IMG,
  PROJECT_TOGGLE_LOADING,
  ProjectUpdateEmailsPayload,
  PROJECT_REPLACE_CLIENT_CONTACTS_ITEMS
} from './types';
import {
  InventoryListItem,
  Project,
  Photo,
  InventoryItem,
  Contact
} from '../typings/types';
import { createUID, isLocalId } from '../utils';
import { AppState } from '../';
import { uploadEmailsInfo, uploadCoefficients, uploadQuoteInfo, uploadProjectInfo, uploadInventoryListItems } from './uploads';
import {
  InventoryListItems,
  SendDatas,
  Projects,
  Quotes,
  Uploads,
  Qcoeffs,
  Clients,
  LPs,
  InventoryItems
} from '../../utils/requests';
import { createProject, creatQuote } from '../data';
import { parseNumber } from '../../utils/calculation';

export function projectUpdateInfo(info: ProjectUpdateInfoPlayload): ProjectForEditActionTypes {
  return {
    type: PROJECT_UPDATE_INFO,
    payload: info
  };
}

export function projectUpdateItemById(item: InventoryListItem): ProjectForEditActionTypes {
  return {
    type: PROJECT_UPDATE_ITEM_BY_ID,
    payload: item
  };
}

export function projectReplaceItems(items: InventoryListItem[]): ProjectForEditActionTypes {
  return {
    type: PROJECT_REPLACE_ITEMS,
    payload: items
  };
}

export function projectReplaceClientContactsItems(items: Contact[]): ProjectForEditActionTypes {
  return {
    type: PROJECT_REPLACE_CLIENT_CONTACTS_ITEMS,
    payload: items
  };
}

export function projectAddItem(): ProjectForEditActionTypes {
  return {
    type: PROJECT_ADD_ITEM
  };
}

export function projectDeleteItem(id: string): ProjectForEditActionTypes {
  return {
    type: PROJECT_DELETE_ITEM,
    payload: { id }
  }
}

function projectAddItemPhotos(itemId: string, photos: Photo[]): ProjectForEditActionTypes {
  return {
    type: PROJECT_ADD_ITEM_IMG,
    payload: {
      itemId,
      photos
    }
  };
}

export function projectUpdateItemImg(itemId: string, oldImgId: string, photo: Photo): ProjectForEditActionTypes {
  return {
    type: PROJECT_UPDATE_ITEM_IMG,
    payload: {
      itemId,
      oldImgId,
      photo
    }
  };
}

export function projectUploadItemPhotos(files: File[], itemId: string): ThunkAction<void, AppState, null, Action<string>> {
  return async (dispatch) => {
    const photos = files.map((file): Photo => {
      return {
        id: createUID(),
        url: '',
        localFile: file
      }
    })

    dispatch(projectAddItemPhotos(itemId, photos));

    photos.forEach(async el => {
      const res = await Uploads.uploadPhoto(el.localFile as File)

      if (!res) {
        return
      }

      const serverImg = {
        ...el,
        id: res.id,
        url: res.url,
        localFile: undefined,
      }
      dispatch(projectUpdateItemImg(itemId, el.id, serverImg))
    })
  }
}

export function projectDeleteItemImg(photo: Photo, itemId: string): ThunkAction<void, AppState, null, Action<string>> {
  return dispatch => {
    dispatch({
      type: PROJECT_DELETE_ITEM_IMG,
      payload: {
        itemId,
        imgId: photo.id
      }
    })
  }
}

export function projectUpdateQuote(info: ProjectUpdateQuotePlayload): ProjectForEditActionTypes {
  return {
    type: PROJECT_UPDATE_QUOTE,
    payload: info
  };
}

export function projectUpdateEmails(data: ProjectUpdateEmailsPayload): ProjectForEditActionTypes {
  return {
    type: PROJECT_UPDATE_EMAILS,
    payload: data
  };
}

export function resetProject(projectId?: string): ThunkAction<void, AppState, null, Action<string>> {
  return async (dispatch) => {

    dispatch(projectToggleLoading(true));

    let project: Project;

    if (projectId) {
      const serverProject = await Projects.getById(projectId)

      if (serverProject.quote) {
        const quoteId = serverProject.quote.id;

        const results = await Promise.all([
          Quotes.getById(quoteId),
          InventoryListItems.getByQuotes(quoteId)
        ]);

        const [quote, items] = results;
        serverProject.quote = quote;
        serverProject.quote.inventoryListItems = items;
      } else {
        serverProject.quote = creatQuote();
      }

      project = createProject(serverProject);
    } else {
      project = createProject();
    }

    const qCoeffId = project.quote.qCoeff.id;
    if (isLocalId(qCoeffId)) {
      // replace with default values from server
      project.quote.qCoeff = await Qcoeffs.getDefault()
      project.quote.qCoeff.id = qCoeffId;
    }

    const limitCount = 5;
    const [clients, lps, inventoryItems] = await Promise.all([
      Clients.all(limitCount),
      LPs.all(limitCount),
      InventoryItems.all(limitCount)
    ]);

    dispatch({
      type: PROJECT_RESET,
      payload: {
        project,
        dropdownItems: {
          clients,
          lps,
          inventoryItems,
          clientContacts: []
        }
      }
    })

    dispatch(projectToggleLoading(false));
  }
}

export function uploadProjectAction(resetOnComplete = false, sendEmails = false): ThunkAction<void, AppState, null, Action<string>> {
  return async (dispatch, getState) => {

    dispatch(projectToggleLoading(true))

    // Upload flow: project -> [emails, coefficients] -> quote(attach emails and coefficients) -> inventory item
    await uploadProjectInfo(getState, dispatch, sendEmails);

    const [emails, coefficients] = await Promise.all([
      uploadEmailsInfo(getState, dispatch),
      uploadCoefficients(getState, dispatch)
    ]);
     const childWasUpdated = emails.inserted || coefficients.inserted;
    await uploadQuoteInfo(getState, dispatch, childWasUpdated)

    await uploadInventoryListItems(getState, dispatch)

    if (sendEmails) {
      const {
        projectForEdit: {
          project: {
            id,
            quote: {
              senddata: { to }
            }
          }
        }
      } = getState();

      if (to && to.length) {
        await SendDatas.sendEmails(id);
      }
    }

    if (resetOnComplete) {
      dispatch(resetProject());
    }

    dispatch(projectToggleLoading(false))
  };
}

export function projectToggleLoading(loading?: boolean): ProjectForEditActionTypes {
  return {
    type: PROJECT_TOGGLE_LOADING,
    payload: loading
  };
}

export function createInventoryListItemFromCSVRow(row: string[], inventoryItems: InventoryItem[]): InventoryListItem {
  const [title, clientTitle, quantity, pounds, FMV, recycling, donation, internalReuse, resale] = row;
  const inventoryItem = inventoryItems.find(el => title.localeCompare(el.title, undefined, { sensitivity: 'accent' }) === 0);
  return {
    id: createUID(),
    inventoryItem: inventoryItem,
    clientProductName: clientTitle,
    quantity: parseNumber(quantity),
    photos: [],
    pounds: parseNumber(pounds) || (inventoryItem && inventoryItem.pounds) || 0,
    FMV: parseNumber(FMV) || (inventoryItem && inventoryItem.FMV) || 0,
    recycling: parseNumber(recycling) || (inventoryItem && inventoryItem.recycling) || 0,
    donation: parseNumber(donation) || (inventoryItem && inventoryItem.donation) || 0,
    internalReuse: parseNumber(internalReuse) || (inventoryItem && inventoryItem.internalReuse) || 0,
    resale: parseNumber(resale) || (inventoryItem && inventoryItem.resale) || 0,
    localeWasEdited: true
  }
}

export function fetchProjectSelectedClientId(): ThunkAction<void, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const {
      projectForEdit: { project: { client }}
    } = getState();
    return client ? client.id : undefined;
  };
}

