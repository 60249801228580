import React from 'react';
import { Tab } from 'semantic-ui-react';
import './index.module.css';

type Props = {
  panes: {
    menuItem: string;
    render: () => React.ReactNode;
  }[],
  defaultActiveIndex?: number;
}

const TabsModal: React.FC<Props> = (props) => {
  return (<Tab renderActiveOnly={true} panes={props.panes} defaultActiveIndex={props.defaultActiveIndex} />);
};

export default TabsModal;
