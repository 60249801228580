export type Project = {
  id: string;
  createdBy: string;
  refNumber?: number;
  title: string;
  client: Client | undefined;
  wlProvider?: Client | undefined;
  quote: Quote;
  address: string;
  date: string;
  createdAt: string;
  LP: LogisticPartner | undefined;
  prOfComp?: number;
  isProjectDateTBD: boolean;
  netPaymentTerms?: string;
  projectOwner?: ProjectOwner | undefined;
  clientContact?: Contact | undefined;
  projectMetricsStaticImages: Photo[];
  metricsStatus: string;
};

export type Client = {
  id: string;
  name: string;
  logo?: Photo;
  createdAt: string;
}

export type LogisticPartner = {
  id: string;
  title: string;
  contactName: string;
  email: string;
  phone: string;
  logo?: Photo;
  createdAt: string;
}

export type Quote = {
  id: string;
  LP: number;
  transferOfTitle: number;
  recovery: string;
  resold: number;
  qCoeff: QuoteCoefficients;
  includeMoveMgmt: boolean;
  senddata: SendData;
  currency: Currency;
  inventoryListItems: InventoryListItem[];
  manualMoveManagement?: number;
}

export type InventoryListItem = {
  id: string;
  inventoryItem: InventoryItem | undefined;
  clientProductName?: string;
  quantity: number;
  photos: Photo[];
  pounds: number;
  FMV: number;
  recycling: number;
  donation: number;
  internalReuse: number;
  resale: number;
  localeWasEdited?: boolean;
}

export type InventoryItem = {
  id: string;
  title: string;
  pounds: number;
  FMV: number;
  recycling: number;
  donation: number;
  internalReuse: number;
  resale: number;
}

export type QuoteCoefficients = {
  id: string;
  MM: number;
  PM: number;
  LD: number;
  MC: number;
  recycled: number;
}

export type SendData = {
  id: string;
  to: string
  cc: string;
  bcc: string;
}

export enum Currency {
  CAD = 'cad',
  USD = 'usd',
  GBP = 'gbp',
  EUR = 'eur'
}

export type Photo = {
  id: string,
  url: string,
  localFile?: File
}

export enum ProjectStatus {
  Draft = 'Draft',
  QuoteSend = 'Quote sent',
  InProgress = 'In Progress',
  Completed = 'Completed'
}

export type ProjectOwner = {
  id: string;
  username: string;
  email: string;
}

export type Contact = {
  id: string;
  name: string;
  email: string;
  phone: string;
  createdAt?: string;
  client: Client | undefined;
}

export type Metric = {
  id: string;
  clientProductName?: string;
  quantity: number;
  pounds: number;
  FMV: number;
  recycling: number;
  donation: number;
  internalReuse: number;
  resale: number;
  inventoryItem: InventoryItem | undefined;
  project: Project | undefined;
  localeWasEdited?: boolean;
}

export type RecyclingReportingTicket = {
  id: string;
  dateOnTicket: Date;
  loadGrade: RecyclingReportingGrade;
  facilityTicketNumber: string;
  ticketWeight: number;
  weightMetric: string;
  ticketCost: number;
  ticketRecovery: number;
  nonRecoverableWeightInPounds: number;
  weightInPounds?: number;
  recoverableWeightInPounds?: number;
  project: Project;
  signOfSheetImage?: Photo;
  localeWasEdited?: boolean;
}

export type CharityReporting = {
  id: string,
  quantityDonated: number;
  signOfSheetImage?: Photo;
  donationRunNo: number;
  metric: Metric;
  localeWasEdited?: boolean;
}

export type PaginationParams = {
  limit: number,
  offset?: number,
  searchText?: string
}

export type RecyclingReportingGrade = {
  id: string;
  title: string;
  recoveryPercent: number;
  nonRecoveryPercent: number;
}
