import { createStore, Store, combineReducers, applyMiddleware, compose } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import { projectForEditReducer } from './projectForEdit/reducers';
import { ProjectForEditActionTypes } from './projectForEdit/types';

import { projectsReducer } from './projects/reducers';
import { ProjectsActionTypes } from './projects/types';

import { inputDiversionMetricsReducer } from './metrics/reducers';
import { InputDiversionMetricsActionTypes } from './metrics/types';

const rootReducer = combineReducers({
  projectForEdit: projectForEditReducer,
  projects: projectsReducer,
  inputDiversionMetrics: inputDiversionMetricsReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export type AppAction = ProjectForEditActionTypes | ProjectsActionTypes | InputDiversionMetricsActionTypes;

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function makeStore(): Store<AppState, AppAction> {
  return createStore<AppState, AppAction, unknown, unknown>(
    rootReducer,
    composeEnhancers( applyMiddleware(thunk as ThunkMiddleware<AppState, AppAction>))
  );
}
