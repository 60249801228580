
import { ProjectInfoForUpload } from "../utils/requests";
import { Project } from "./typings/types";

export function setProjectForUpload(project: Project): ProjectInfoForUpload {
  return {
    ...project,
    client: project.client && project.client.id,
    wlProvider: project.wlProvider && project.wlProvider.id,
    LP: project.LP && project.LP.id,
    projectOwner: project.projectOwner && project.projectOwner.id,
    clientContact: project.clientContact && project.clientContact.id,
    projectMetricsStaticImages: project.projectMetricsStaticImages
      ? project.projectMetricsStaticImages.map((el) => el.id)
      : [],
  };
}
