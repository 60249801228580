import React, {Fragment, useMemo} from 'react';
import {Input} from 'semantic-ui-react';
import {useDispatch} from 'react-redux';
import {useFormState} from 'react-use-form-state';
import styles from './index.module.css';

import {
  CharityReporting,
  Photo
} from '../../../store/typings/types';
import {updateCharityReportingItem} from "../../../store/metrics/actions";
import ImageUpload from "../ImageUpload";
import {createPhoto} from "../../../store/data";
import {getImageUrlForPhoto} from "../../../utils/image";

type FormValues = {
  quantityDonated: number;
  signOfSheetImage?: Photo | null;
};

type Props = {
  item: CharityReporting;
};

const CharityItemDetails: React.FC<Props> = (props) => {
  const {item} = props;
  const {metric} = item;

  const dispatch = useDispatch();

  const [formState, {raw, number}] = useFormState<FormValues>(item, {
    onChange(e, stateValues, nextStateValues) {
      const {quantityDonated, signOfSheetImage} = nextStateValues;
      const updated = {
        ...props.item,
        quantityDonated: + quantityDonated,
        signOfSheetImage: signOfSheetImage || undefined
      };
      dispatch(updateCharityReportingItem(updated));
    }
  });
  const { signOfSheetImage } = formState.values;

  const imgUrl = useMemo(() => {
    if (!signOfSheetImage) {
      return '';
    }
    if (signOfSheetImage.localFile) {
      return URL.createObjectURL(signOfSheetImage.localFile)
    }
    return getImageUrlForPhoto(signOfSheetImage);
  }, [signOfSheetImage]);

  return (
    <Fragment>
      <Input value={metric.project && metric.project.refNumber ? metric.project.refNumber : ''} className="primary" disabled={true}/>
      <Input value={metric.inventoryItem && metric.inventoryItem.title ? metric.inventoryItem.title : ''} className="primary" disabled={true}/>
      <Input value={metric.clientProductName || ''} className="primary" disabled={true}/>
      <Input value={metric.quantity} className="primary" disabled={true}/>

      <div className={styles['line-container']}/>

      <Input {...number('quantityDonated')}
             min="0"
             className={`primary`}
      />
      <ImageUpload
        {...raw({
          name: 'signOfSheetImage',
          onChange: (files: File[]) => files.length ? createPhoto(files[0]) : null
        })}
        url={imgUrl}
      />

    </Fragment>
  );
};

export default CharityItemDetails;
