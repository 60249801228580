import * as qs from 'qs';
import { API_URL } from '../config';
import {
  Client,
  LogisticPartner,
  InventoryItem,
  QuoteCoefficients,
  Currency,
  SendData,
  Photo,
  Project,
  InventoryListItem,
  Quote,
  ProjectStatus,
  Contact,
  Metric,
  RecyclingReportingTicket,
  CharityReporting,
  PaginationParams, RecyclingReportingGrade
} from "../store/typings/types";
import { isLocalId } from '../store/utils';
import { createQuoteCoefficient } from '../store/data';
import {getUser, setUser, removeUser} from "./user";
import {setProjectMetricsStaticImages} from "./project";

const LENGTH_OF_BASE_URL = API_URL ? API_URL.length : 0;
const GET_URL_MAX_LENGTH = 2000 - LENGTH_OF_BASE_URL;

type FetchOptions = { signal?: AbortSignal };

async function sendRequest(url: string, method: string, body?: object, options?: FetchOptions): Promise<any> {

  const isFormData = body && body instanceof FormData;
  const user = getUser();

  const reqOptions: any = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: undefined,
    signal: options && options.signal ? options.signal : undefined
  };

  if (user && user.token) {
    reqOptions.headers = {
      ...reqOptions.headers,
      'Authorization': 'Bearer '+ user.token
    }
  }

  if (isFormData && reqOptions.headers) {
    delete reqOptions.headers['Content-Type'];
  }

  if (body) {
    reqOptions.body = isFormData ? body as FormData : JSON.stringify({
        ...(url.includes('/auth/local') ? body : {data: body})
    });
  }

  const res = await fetch(`${API_URL}${url}`, reqOptions);

  if (res.status === 401 || res.status === 403) {
    removeUser();
    window.location.reload();
  }

  let response: any = res;
  if (res.headers) {
    const contentType = res.headers.get("content-type");
    if ((contentType || '').indexOf("application/json") !== -1) {
      response = await res.json();
    }
  }

  // filtering response
    if (
        url.includes('/auth/local') ||
        url.includes('/users/me')
    ) {
        return response;
    } else if (
        url.includes('getCountOnly')
    ) {
        return response.meta.pagination.total
    } else {
        return response.data ? response.data : response;
    }
}

const requests = {
  get: (url: string, options?: FetchOptions) => sendRequest(url, 'GET', undefined, options),
  post: (url: string, body: object) => sendRequest(url, 'POST', body),
  put: (url: string, body: object) => sendRequest(url, 'PUT', body),
  delete: (url: string) => sendRequest(url, 'DELETE')
};

const buildUrlWithQueryParams = (url: string, params: object): string => {
  /*const searchParams = new URLSearchParams();
  const entries = Object.entries(params);
  for (const [key, val] of entries) {
    if (typeof val === 'undefined') {
      continue;
    }

    searchParams.append(key, val);
  }

  const query = searchParams.toString();

  if (query.length) {
    return `${url}?${query}`
  } else {
    return url;
  }*/
    const query = qs.stringify(params, {
        encodeValuesOnly: true, // prettify URL
    });
    return `${url}?${query}`;
};

const removeLocalId = (item: any) => {
  if (item.id && item.id.indexOf('local_') > -1) {
    delete item.id;
  }
  return item;
};

export type ClientForUpload = {
  id: string;
  name: string;
  logo?: Photo;
}

export type ClientDetails = Client & { projectsCount: number }

export const Clients = {
  all: (limit?: number): Promise<Client[]> => {
    const url = buildUrlWithQueryParams('/clients', { pagination: { limit }, populate: '*' })
    return requests.get(url)
  },
  filterByName: (name: String): Promise<Client[]> => requests.get(`/clients?filters[name][$containsi]=${name}`),
  addOrUpdate: (client: ClientForUpload): Promise<{ id: string }> => {
    const { name, id } = client;
    const body = { name };

    if (isLocalId(id)) {
      return requests.post(`/clients`, body)
    } else {
      return requests.put(`/clients/${client.id}`, body)
    }
  },
  addLogo: (img: File, clientId: string): Promise<{ id: string, url: string }> => {
    const data = new FormData();
    data.append('files', img);
    data.append('ref', 'api::client.client');
    data.append('refId', clientId);
    data.append('field', 'logo');
    //data.append('ref', img);
    return requests.post('/upload', data)
      .then(res => res[0])
  },
  details: (filter?: string): Promise<ClientDetails[]> => {
    let params: any = {}
    if (filter) {
      params = { filters: { name: { $containsi: filter } }, populate: '*' };
    }
    const url = buildUrlWithQueryParams('/clients/details', params);
    return requests.get(url)
  },
  count: (searchText?: string): Promise<number> => {
    const url = buildUrlWithQueryParams('/clients', { filters: { name: { $containsi: searchText } }, getCountOnly: true });
    return requests.get(url);
  },
  findAll: (query: PaginationParams): Promise<ClientDetails[]> => {
    const url = buildUrlWithQueryParams('/clients/with/project-count', {
        populate: '*',
        pagination: { limit: query.limit, start: query.offset },
        filters: { name: { $containsi: query.searchText } }
    });
    return requests.get(url)
  }
};

export type LPForUpload = {
  id: string;
  title: string;
  contactName: string;
  email: string;
  phone: string;
  logo?: Photo;
}

export const LPs = {
  all: (limit?: number): Promise<LogisticPartner[]> => {
    const url = buildUrlWithQueryParams('/lps', { pagination: { limit } })
    return requests.get(url)
  },
  addOrUpdate: (lp: LPForUpload): Promise<{ id: string }> => {
    const { id, title, contactName, email, phone } = lp;
    const body = { id, title, contactName, email, phone };

    if (isLocalId(id)) {
      return requests.post(`/lps`, removeLocalId(body))
    } else {
      return requests.put(`/lps/${id}`, body)
    }
  },
  addLogo: (img: File, lpId: string): Promise<{ id: string, url: string }> => {
    const data = new FormData();
    data.append('files', img);
    data.append('ref', 'api::lp.lp');
    data.append('refId', lpId);
    data.append('field', 'logo');
    //data.append('ref', img);
    return requests.post('/upload', data)
      .then(res => res[0])
  },
  filter(query: string): Promise<LogisticPartner[]> {
      const url = buildUrlWithQueryParams('/lps', { filters: { title: { $containsi: query } } });
      return requests.get(url);
  },
  count: (searchText?: string): Promise<number> => {
    const url = buildUrlWithQueryParams('/lps', { filters: { title: { $containsi: searchText } }, getCountOnly: true });
    return requests.get(url)
  },
  findAll: (query: PaginationParams): Promise<LogisticPartner[]> => {
    const url = buildUrlWithQueryParams('/lps', {
        populate: '*',
        pagination: { limit: query.limit, start: query.offset },
        filters: { title: { $containsi: query.searchText } }
    });
    return requests.get(url)
  }
};

export const InventoryItems = {
  all: (limit?: number): Promise<InventoryItem[]> => {
    const url = buildUrlWithQueryParams('/inventoryitems', { pagination: { limit } })
    return requests.get(url)
  },
  filter(query: string): Promise<InventoryItem[]> {
      const url = buildUrlWithQueryParams('/inventoryitems', { filters: { title: { $containsi: query } } });
      return requests.get(url);
  },
  add(item: InventoryItem): Promise<InventoryItem> {
    return requests.post(`/inventoryitems`, removeLocalId(item))
  },
  findByTitle(names: string[]): Promise<InventoryItem[]> {
    const unicNames = new Set<string>(names);

    if (unicNames.size === 0) {
      return Promise.resolve([]);
    }

    let currentQuery = ''
    const queries: string[] = [];

    unicNames.forEach(name => {
      const parameter = `filters[title][$containsi]=${name}&`;
      if (currentQuery.length + parameter.length < GET_URL_MAX_LENGTH) {
        currentQuery += parameter;
        return;
      }

      queries.push(currentQuery);
      currentQuery = parameter;
    })

    if (currentQuery.length > 0) {
      queries.push(currentQuery);
    }

    return Promise.all(queries.map(query => requests.get(`/inventoryitems?${query}`)))
      .then(arr => arr.flat())
  }
}

export type ProjectFilter = {
  client?: string,
  LP?: string,
  filters?: any,
  isLegacy?: boolean,
  pagination?: any
}

export type ClientContactsFilter = {
  client?: string,
    filters?: any,
  pagination?: any
}

export type ProjectInfoForUpload = {
  id?: string;
  refNumber?: number;
  title?: string;
  client?: string;
  wlProvider?: string;
  address?: string;
  date?: string | null;
  LP?: string;
  prOfComp?: number | null;
  status?: ProjectStatus;
  projectOwner?: string;
  isProjectDateTBD?: boolean;
  netPaymentTerms?: string;
  clientContact?: string | null;
  projectMetricsStaticImages?: string[];
  metricsStatus?: string;
};

const PROJECT_COUNT_BEFORE = 3887;

export const Projects = {
  addOrUpdate: async (project: ProjectInfoForUpload): Promise<{ id: string }> => {
    if (
        project.clientContact &&
        (typeof project.clientContact === "string") &&
        project.clientContact.indexOf('local_') > -1
    ) {
      project.clientContact = null;
    }
    if (project.date) {
      project.date = (new Date(project.date)).toISOString();
    }

    let res;
    if (project.id && !isLocalId(project.id)) {
        res = await requests.put(`/projects/${project.id}`, project)
    } else {
        res = await requests.post('/projects', removeLocalId(project))
    }
    return setProjectMetricsStaticImages(res);
  },
  fetchNextProjectRefNumber: async (abortCtrl: AbortController): Promise<number | undefined> => {
    try {
      const count = await requests.get('/projects?getCountOnly=true', { signal: abortCtrl.signal });

      return count + PROJECT_COUNT_BEFORE + 1;
    } catch (error) {
      if (error && error.code === 20) {
        // Aborted request
        return;
      }
      console.error('get projects count:', error);
    }
  },
  projects: async (filter: ProjectFilter): Promise<Project[]> => {
    const params = Object.assign({}, filter, { sort: ['refNumber:desc'] });
    const url = buildUrlWithQueryParams('/projects', params);
    const res = await requests.get(url);
    return setProjectMetricsStaticImages(res);
  },
  getById: async (id: string): Promise<Project> => {
      const res = await requests.get(`/projects/${id}?populate=*`);
      return setProjectMetricsStaticImages(res);
  },
  count: (filter: ProjectFilter, searchText?: string): Promise<number> => {
    const params = Object.assign({}, filter, {
        ...(searchText ? {filters: {title: {$containsi: searchText}}} : {}),
        getCountOnly: true
    });
    const url = buildUrlWithQueryParams('/projects', params);
    return requests.get(url);
  },
  findAll: async (filter: ProjectFilter, query: PaginationParams): Promise<Project[]> => {
    const params = Object.assign({}, filter, {
      populate: '*',
      pagination: { start: query.offset, limit: query.limit },
      ...(query && query.searchText ? {filters: {title: {$containsi: query.searchText}}} : {}),
      sort: ['refNumber:desc']
    });
    const url = buildUrlWithQueryParams('/projects', params);
    const res = await requests.get(url);
    return setProjectMetricsStaticImages(res);
  }
}

export type QuoteInfoForUpload = {
  id?: string;
  LP?: number;
  transferOfTitle?: number;
  recovery?: string;
  resold?: number;
  recycled?: number;
  includeMoveMgmt?: boolean;
  currency?: Currency;
  projects?: [string];
  senddata?: string,
  qCoeff?: string,
  manualMoveManagement?: number
}

export const Quotes = {
  addOrUpdate: (info: QuoteInfoForUpload): Promise<{ id: string }> => {
    if (info.id && !isLocalId(info.id)) {
      return requests.put(`/quotes/${info.id}`, info)
    } else {
      return requests.post('/quotes', removeLocalId(info))
    }
  },
  getById: (id: string): Promise<Quote> => {
      const params = {
          populate: '*'
      };
      const url = buildUrlWithQueryParams(`/quotes/${id}`, params);
      return requests.get(url);
  }
}

export const SendDatas = {
  addOrUpdate: (data: SendData): Promise<{ id: string }> => {
    if (data.id && !isLocalId(data.id)) {
      return requests.put(`/senddata/${data.id}`, data)
    } else {
      return requests.post('/senddata', removeLocalId(data))
    }
  },
  sendEmails: (projectId: string): Promise<void> => {
    // Server takes the last version of the emails senddata.
    return requests.post(`/mail`, { projectId })
  }
}

export const Qcoeffs = {
  addOrUpdate: (data: QuoteCoefficients): Promise<{ id: string }> => {
    if (data.id && !isLocalId(data.id)) {
      return requests.put(`/qcoeffs/${data.id}`, data)
    } else {
      return requests.post('/qcoeffs', removeLocalId(data))
    }
  },
  getDefault: async (): Promise<QuoteCoefficients> => {
      const url = buildUrlWithQueryParams('/qcoeffs', { pagination: {limit: 1}});
      const res = await requests.get(url);
    return createQuoteCoefficient(res[0]);
  }
}

export type InventoryItemUpload = {
  id: string;
  inventoryItem: undefined | string;
  clientProductName?: string;
  quantity: number;
  pounds: number;
  FMV: number;
  recycling: number;
  donation: number;
  internalReuse: number;
  resale: number;
  quote: string;
  photos: string[];
}

export const InventoryListItems = {
  addOrUpdate: (data: InventoryItemUpload): Promise<{ id: string }> => {
    if (data.id && !isLocalId(data.id)) {
      return requests.put(`/inventorylistitems/${data.id}`, data)
    } else {
      return requests.post('/inventorylistitems', removeLocalId(data))
    }
  },
  delete: (id: string): Promise<void> => {
    return requests.delete(`/inventorylistitems/${id}`)
  },
  getByQuotes: (id: string): Promise<InventoryListItem[]> => {
      const params = {
          filters: {quote: {id: {$eq: id}}},
          populate: '*',
          pagination: {
              page: 1,
              pageSize: 100
          }
      };
      const url = buildUrlWithQueryParams('/inventorylistitems', params);
      return requests.get(url);
  }
}

export const Uploads = {
  uploadPhoto: (img: File): Promise<{ id: string, url: string }> => {
    const data = new FormData();
    data.append('files', img);
    return requests.post('/upload', data)
      .then(res => res[0])
  },
  deletePhoto: (photo: Photo): Promise<void> => {
    if (!photo.id || isLocalId(photo.id)) {
      return Promise.resolve();
    } else {
      return requests.delete(`/upload/files/${photo.id}`)
    }
  }
}

export const createInvoiceUrl = (projectId: string): string => `${API_URL}/quotes/${projectId}/build`;

export const User = {
  login: async (params: any): Promise<any> => {
    const { email, password } = params;
    const body = { identifier: email, password };

    const response = await requests.post(`/auth/local`, body);
    setUser(response);
    const user = await requests.get(`/users/me?populate=role,lp,client`);
    return Promise.resolve({
        jwt: response.jwt,
        user: user
    });
  }
};

export const Contacts = {
  all: (limit?: number): Promise<Contact[]> => {
    const url = buildUrlWithQueryParams('/contacts', { pagination: { limit } })
    return requests.get(url)
  },
  filterByName: (name: String): Promise<Contact[]> => requests.get(`/contacts?filters[name][$containsi]=${name}`),
  addOrUpdate: (contact: Contact): Promise<{ id: string }> => {
    const { name, email, phone, id, client } = contact;
    const body = { name, email, phone, client: client ? client.id : undefined };

    if (isLocalId(id)) {
      return requests.post(`/contacts`, removeLocalId(body))
    } else {
      return requests.put(`/contacts/${contact.id}`, body)
    }
  },
  contactsFilterByNameOrClient: (filter: ClientContactsFilter): Promise<Contact[]> => {
    const params = Object.assign({}, filter);
    const url = buildUrlWithQueryParams('/contacts', params)
    return requests.get(url)
  },
  contacts: (filter: ClientContactsFilter): Promise<Contact[]> => {
    const params = Object.assign({}, filter);
    const url = buildUrlWithQueryParams('/contacts', params)
    return requests.get(url)
  }
};

export const Metrics = {
  getProjectMetrics: (projectId: string): Promise<Metric[]> => {
    const params = {
        filters: {project: {id: {$eq: projectId}}},
        populate: '*',
        pagination: { limit: 100 }
    };
    const url = buildUrlWithQueryParams('/metrics', params)
    return requests.get(url)
  },
  addOrUpdate: (data: Metric): Promise<{ id: string }> => {
    if (data.id && !isLocalId(data.id)) {
      return requests.put(`/metrics/${data.id}?populate=*`, data)
    } else {
      return requests.post('/metrics?populate=*', removeLocalId(data))
    }
  },
  getMetrics: (params: any): Promise<Metric[]> => {
    const url = buildUrlWithQueryParams('/metrics', params)
    return requests.get(url);
  },
  delete: (id: string): Promise<void> => {
    return requests.delete(`/metrics/${id}`)
  }
};

export const RecyclingReportings = {
  getProjectRecyclingReporting: (projectId: string): Promise<RecyclingReportingTicket[]> => {
    const params = { filters: {project: {id: {$eq: projectId}}}, populate: ['signOfSheetImage', 'loadGrade'], pagination: { limit: 1000 } };
    const url = buildUrlWithQueryParams('/recyclingreportings', params)
    return requests.get(url)
  },
  addOrUpdate: (data: RecyclingReportingTicket): Promise<RecyclingReportingTicket> => {
    if (data.id && !isLocalId(data.id)) {
      return requests.put(`/recyclingreportings/${data.id}`, data)
    } else {
      return requests.post('/recyclingreportings', removeLocalId(data))
    }
  },
  delete: (id: string): Promise<void> => {
    return requests.delete(`/recyclingreportings/${id}`)
  },
  addLogo: (img: File, recyclingReportingId: string): Promise<{ id: string, url: string }> => {
    const data = new FormData();
    data.append('files', img);
    data.append('ref', 'api::recyclingreporting.recyclingreporting');
    data.append('refId', recyclingReportingId);
    data.append('field', 'signOfSheetImage');
    data.append('ref', img);
    return requests.post('/upload', data)
      .then(res => res[0])
  },
  getRecyclingReportingGrades: (): Promise<RecyclingReportingGrade[]> => {
    const url = buildUrlWithQueryParams('/recycling-reporting-grades', { pagination: { limit: 30 } })
    return requests.get(url);
  },
};

export const CharityReportings = {
  getMetricsCharityReporting: (metricsIds: string[]): Promise<CharityReporting[]> => {
    const params = {
        filters: {
            metric: metricsIds,
        },
        pagination: { limit: 1000 },
        populate: '*'
    };
    const url = buildUrlWithQueryParams('/charityreportings', params)
    return requests.get(url)
  },
  addOrUpdate: (data: CharityReporting): Promise<CharityReporting> => {
    if (data.id && !isLocalId(data.id)) {
      return requests.put(`/charityreportings/${data.id}`, data)
    } else {
      return requests.post('/charityreportings', removeLocalId(data))
    }
  },
  addLogo: (img: File, chartyReportingId: string): Promise<{ id: string, url: string }> => {
    const data = new FormData();
    data.append('files', img);
    data.append('ref', 'api::charityreporting.charityreporting');
    data.append('refId', chartyReportingId);
    data.append('field', 'signOfSheetImage');
    data.append('ref', img);
    return requests.post('/upload', data)
      .then(res => res[0])
  },
  delete: (id: string): Promise<void> => {
    return requests.delete(`/charityreportings/${id}`)
  },
  getCharityReportings: (params: any): Promise<CharityReporting[]> => {
    const url = buildUrlWithQueryParams('/charityreportings', params)
    return requests.get(url)
  }
};

export const ClientReporting = {
   getClientReportUrl:(projectIds?: string): Promise<any>  => {
    const url = buildUrlWithQueryParams(`/clients/home/chart`, {projectIds});
    return requests.get(url);
  }
}
