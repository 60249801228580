import React, {Fragment, useEffect, useMemo, useState} from 'react';
import { Modal, Input, Button, Dimmer, Loader } from 'semantic-ui-react';
import { useFormState } from 'react-use-form-state';
import styles from './index.module.css';

import { Client, Contact } from '../../../store/typings/types';
import {Clients, Contacts} from '../../../utils/requests';
import {createClient, createContact} from '../../../store/data';
import SearchDropdown, {SearchDropdownItem} from "../../components/SearchDropdown";

interface FormFields {
  name: string;
  email: string;
  phone: string;
  client: Client;
}

type Props = {
  close: () => void;
  client?: Client;
  contact?: Contact;
  contactsItems?: SearchDropdownItem[];
  onAdd?: (contact: Contact[]) => void;
  onChange?: (contact: Contact, oldId: string) => void;
}

const ContactEditor: React.FC<Props> = (props) => {
  const contact = props.contact || createContact();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formWasChanged, setFormWasChanged] = useState<boolean>(false);
  const [clients, setClients] = useState<SearchDropdownItem[]>([]);

  const searchClients = async (query: string): Promise<SearchDropdownItem[]> => {
    const res = await Clients.filterByName(query);
    return res.map(el => ({text: el.name, value: el.id, item: el}));
  };

  const initialFormData = useMemo((): FormFields => {
    const { name, email, phone, client } = contact;
    return {
      name,
      email,
      phone,
      client: client || props.client || createClient()
    }
  }, [contact])

  const [formState, { text, email, raw }] = useFormState<FormFields>(initialFormData, {
    onChange: () => setFormWasChanged(true)
  });

  useEffect(() => {
    const fetchClients = async () => {
      const limit = 5;
      const res = await Clients.all(limit);
      setClients([...res.map(el => ({text: el.name, value: el.id, item: el}))]);
    };
    if (!clients.length) {
      fetchClients();
    }
  }, [clients])

  const {values: { client }} = formState;
  const {clientDropdown} = useMemo(() => {
    const clientSelected = client ? {
      text: client.name,
      value: client.id,
      item: client
    } : undefined;

    return {
      clientDropdown: {
        clientSelected,
        clientItems: clients,
      }
    }
  }, [clients, client]);

  const saveClientAndClose = async () => {

    if (!formWasChanged) {
      return props.close();
    }

    setIsLoading(true);

    const { name, email, phone, client } = formState.values;

    let updatedContact = {
      ...contact,
      name,
      email,
      phone,
      client
    }

    try {
      const oldId = updatedContact.id;
      const res = await Contacts.addOrUpdate(updatedContact);
      updatedContact.id = res.id;

      if (typeof props.onAdd === 'function' && props.contactsItems) {
        let contacts: any = [];
        props.contactsItems.forEach(el => {
          contacts.push(el.item)
        });
        props.onAdd([...contacts, updatedContact])
      }

      if (typeof props.onChange === 'function') {
        props.onChange(updatedContact, oldId);
      }

      return props.close();

    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  }

  return (
    <Fragment>
      <Modal.Content>
        <Modal.Description>

          {
            isLoading &&
            <Dimmer active>
              <Loader />
            </Dimmer>
          }

          <div className={styles['form-container']}>
            <Input {...text('name')} className="primary" placeholder="Name" />
            <Input {...email('email')} className="primary" placeholder="Contact Email" />
            <Input {...text('phone')} className="primary" placeholder="Contact Phone" />
            <SearchDropdown
              placeholder="Select client"
              {...raw({
                name: 'client',
                onChange: client => client as Client
              })}
              selected={clientDropdown.clientSelected}
              defaultOptions={clientDropdown.clientItems}
              onSearch={query => searchClients(query)}
            />
            <Button className={`${styles.btn} primary`} onClick={saveClientAndClose}>Save</Button>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Fragment>
  );
};

export default ContactEditor;
