import React, { useState, useEffect, SyntheticEvent, useRef } from 'react';
import { Button, Input, Modal, Tab, InputOnChangeData } from 'semantic-ui-react';
import { debounce } from 'lodash';
import styles from './index.module.css';

import ClientEditor from '../../common/modals/ClientEditor';
import TabsModal from '../../common/modals/TabsModal';
import ProjectsModal from '../../common/modals/ProjectsModal';
import LoadingEl from '../../common/components/LoadingEl';
import ClientContactsModal from '../../common/modals/ClientContactsModal';

import { Client, PaginationParams } from '../../store/typings/types';
import { dateFormatter } from '../../utils/date';
import {Clients, ClientDetails} from '../../utils/requests';
import EcoPagination from '../../common/components/EcoPagination';
import { PAGINATION_ITEMS_PER_PAGE } from '../../common/constants/common';

const ClientsPage: React.FC = () => {
  const [clientList, setClientList] = useState<ClientDetails[] | undefined>(undefined);
  const reloadClients = () => setClientList(undefined);
  const addClient = (client: Client) => {
    const details = { ...client, projectsCount: 0 };
    setClientList(!!clientList ? clientList.concat([details]) : [details]);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [selectedClient, setSelectedClient] = useState<Client | undefined>();
  const setClient = (client: Client) => setSelectedClient(client);

  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const handleEditModalOpen = (client: Client) => {
    setClient(client);
    setIsEditModalOpened(true);
  };
  const handleEditModalClose = () => setIsEditModalOpened(false);

  const [isAddModalOpened, setIsAddModalOpened] = useState(false);
  const handleAddModal = (isOpen: boolean) => setIsAddModalOpened(isOpen)
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!clientList) {
      fetchClients({limit: PAGINATION_ITEMS_PER_PAGE});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientList]);

  const fetchClients = async (queryParams: PaginationParams) => {
    setIsLoading(true);
    if (!queryParams.offset) {
      const count = await Clients.count(queryParams.searchText);
      setTotalCount(count);
    }
    const res = await Clients.findAll(queryParams);
    setClientList(res);
    setIsLoading(false);
  };

  const onPageChange = async (limit: number, offset: number) => {
    fetchClients({limit, offset, searchText});
  };

  const search = async (text: string) => {
    setSearchText(text);
    fetchClients({limit: PAGINATION_ITEMS_PER_PAGE, searchText: text})
  };
  const debouncedSearch = useRef(debounce(search, 400)).current;


  const panes = [
    {
      menuItem: 'Information', render: () => <Tab.Pane>
        <ClientEditor close={handleEditModalClose} client={selectedClient} onChange={reloadClients} />
      </Tab.Pane>
    },
    {
      menuItem: 'Projects', render: () => <Tab.Pane>
        <ProjectsModal parentFilter={{ client: selectedClient ? selectedClient.id : undefined }} />
      </Tab.Pane>
    },
    {
      menuItem: 'Contacts', render: () => <Tab.Pane>
        <ClientContactsModal parentFilter={{ client: selectedClient ? selectedClient.id : undefined }} />
      </Tab.Pane>
    }
  ];

  return (
    <div className="page-padding content-scrollable">
      <div className={styles.header}>
        <h1 className="secondary-text">Clients</h1>
        <span>
          <Modal
            trigger={
              <Button circular icon="plus" className="primary" onClick={() => handleAddModal(true)} />
            }
            open={isAddModalOpened}
            onClose={() => handleAddModal(false)}>
            <ClientEditor
              close={() => handleAddModal(false)}
              onChange={client => addClient(client)}
            />
          </Modal>
          <Input icon="search" placeholder="Search" className="primary" onChange={(e, data) => debouncedSearch(data.value)} />
        </span>
      </div>

      <div className="relative">
        <LoadingEl show={typeof clientList === 'undefined' || isLoading} onlySpinner={true} />
        <table className="table table-striped table-vertical-bordered primary-text">
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Number of Projects</th>
              <th>Created</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              clientList &&
              clientList.map(client => (
                <tr key={client.id}>
                  <td>{client.name}</td>
                  <td>{client.projectsCount}</td>
                  <td>{dateFormatter(client.createdAt)}</td>
                  <td>
                    <Button icon="edit" compact className="clear primary-text" onClick={() => handleEditModalOpen(client)} />
                  </td>
                </tr>
              ))
            }

            <Modal open={isEditModalOpened} onClose={handleEditModalClose} size="fullscreen">
              <TabsModal panes={panes} defaultActiveIndex={1} />
            </Modal>
          </tbody>
        </table>
        <EcoPagination
          loading={isLoading}
          totalCount={totalCount}
          onPaginate={onPageChange}
        />
      </div>
    </div>
  );
};

export default ClientsPage;
