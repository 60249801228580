import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import './css'
import * as serviceWorker from './serviceWorker';

import App from './app/App';
import { makeStore } from './store';
import HISTORY from './routing/history';
import ROUTES_URL from './routing/urls';

import LoginPage from './pages/login';
import AuthenticatedRoutes from './app/AuthenticatedRoutes';

const store = makeStore();

ReactDOM.render(
  <Provider store={store}>
    <Router history={HISTORY}>
      <Route path={ROUTES_URL.LOGIN} component={LoginPage} />
      <AuthenticatedRoutes>
        <App />
      </AuthenticatedRoutes>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
