import React, { Fragment } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

interface LoadingElProps {
  show: boolean;
  onlySpinner?: boolean;
}
const LoadingEl: React.FC<LoadingElProps> = ({ show, onlySpinner = false }) => {
  return (
    <Fragment>
      {
        show &&
        <Dimmer active inverted={onlySpinner}>
          <Loader inverted={onlySpinner} />
        </Dimmer>
      }
    </Fragment>
  );
};

export default LoadingEl;
