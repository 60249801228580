import React, { Fragment, useMemo, useState } from 'react';
import { Modal, Input, Button, Dimmer, Loader } from 'semantic-ui-react';
import { useFormState } from 'react-use-form-state';
import styles from './index.module.css';

import { getImageUrlForPhoto } from '../../../utils/image';
import { LPs, Uploads } from '../../../utils/requests';
import { createLP, createPhoto } from '../../../store/data';
import { LogisticPartner, Photo } from '../../../store/typings/types';

import ImagePicker from '../../components/ImagePicker';

interface FormFields {
  title: string;
  contactName: string;
  email: string;
  phone: string;
  logo?: Photo | null;
}

type Props = {
  close: () => void;
  lp?: LogisticPartner;
  onChange?: (lp: LogisticPartner, oldId: string) => void;
}

const LPEditor: React.FC<Props> = (props) => {
  const lp = props.lp || createLP()

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formWasChanged, setFormWasChanged] = useState<boolean>(false);

  const initialFormData = useMemo((): FormFields => {
    const { title, contactName, email, phone, logo } = lp;
    return {
      title,
      contactName,
      email,
      phone,
      logo
    }
  }, [lp])

  const [formState, { text, email, raw }] = useFormState<FormFields>(initialFormData, {
    onChange: () => setFormWasChanged(true)
  });

  const { logo } = formState.values;

  const saveLPAndCLose = async () => {

    if (!formWasChanged) {
      return props.close();
    }

    setIsLoading(true);

    const { title, contactName, email, phone } = formState.values;

    let updatedLP = {
      ...lp,
      title,
      contactName,
      email,
      phone
    }

    const oldId = updatedLP.id;
    const res = await LPs.addOrUpdate(updatedLP);
    updatedLP.id = res.id;

    if (!logo && lp.logo) {
      await Uploads.deletePhoto(lp.logo);
      delete updatedLP.logo;
    }

    if (logo) {
      updatedLP = await uploadPhoto(updatedLP)
    }

    setIsLoading(false);

    if (typeof props.onChange === 'function') {
      props.onChange(updatedLP, oldId);
    }
    props.close();
  }

  const uploadPhoto = async (lp: LogisticPartner): Promise<LogisticPartner> => {
    if (logo && logo.localFile) {
      const res = await LPs.addLogo(logo.localFile, lp.id);
      lp.logo = {
        id: res.id,
        url: res.url
      }
      return lp
    }
    return Promise.resolve(lp);
  }

  let imgUrl = useMemo(() => {
    if (!logo) {
      return '';
    }

    if (logo.localFile) {
      return URL.createObjectURL(logo.localFile)
    }

    return getImageUrlForPhoto(logo);
  }, [logo]);

  return (
    <Fragment>
      <Modal.Content>
        <Modal.Description>

          {
            isLoading &&
            <Dimmer active>
              <Loader />
            </Dimmer>
          }

          <div className={styles['form-container']}>
            <Input {...text('title')} className="primary" placeholder="Company Name" />
            <Input {...text('contactName')} className="primary" placeholder="Primary Contact Name" />
            <Input {...email('email')} className="primary" placeholder="Contact Email" />
            <Input {...text('phone')} className="primary" placeholder="Contact Phone" />
            <ImagePicker
              {...raw({
                name: 'logo',
                onChange: (files: File[]) => files.length ? createPhoto(files[0]) : null
              })}
              url={imgUrl} />

            <Button className={`${styles.btn} primary`} onClick={saveLPAndCLose}>Save</Button>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Fragment>
  );
};

export default LPEditor;
