import React from 'react';
import { Route } from "react-router-dom";
import { RouteConfig } from '../../../routing';

const RouteWithSubRoutes: React.FC<RouteConfig> = (route) => {
	return (
		<Route
      path={route.path}
      exact={route.exact}
			render={(routeProps) => (
				// pass the sub-routes down to keep nesting
				<route.component {...routeProps} routes={route.routes} {...route.props} />
			)}
		/>
	);
}

export default RouteWithSubRoutes;
