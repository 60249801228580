import React, {Fragment, useMemo} from 'react';
import {Button, Grid, Input} from 'semantic-ui-react';
import {useDispatch} from 'react-redux';
import {useFormState} from 'react-use-form-state';
import styles from './index.module.css';

import {InventoryItem, Metric} from '../../../store/typings/types';
import {removeMetric, updateMetric} from "../../../store/metrics/actions";
import {InventoryItems} from "../../../utils/requests";
import SearchDropdown, {SearchDropdownItem} from "../SearchDropdown";

type FormValues = {
  inventoryItem: InventoryItem | undefined;
  clientProductName: string;
  quantity: number;
  pounds: number;
  FMV: number;
  recycling: number;
  donation: number;
  internalReuse: number;
  resale: number;
};

type Props = {
  item: Metric;
  defaultOptions: SearchDropdownItem[];
};

const MetricInfo: React.FC<Props> = (props) => {
  const {item, defaultOptions} = props;
  const {quantity, recycling, donation, internalReuse, resale} = item;

  const dispatch = useDispatch();

  const [formState, {number, text, raw}] = useFormState<FormValues>(item, {
    onChange(e, stateValues, nextStateValues) {
      const {inventoryItem, clientProductName, quantity, pounds, FMV, recycling, donation, internalReuse, resale} = nextStateValues;

      const itemWasChanged = stateValues.inventoryItem !== inventoryItem;

      const updated = {
        ...props.item,
        inventoryItem,
        clientProductName: clientProductName,
        quantity: +quantity,
        pounds: +pounds,
        FMV: +FMV,
        recycling: +recycling,
        donation: +donation,
        internalReuse: +internalReuse,
        resale: +resale
      };

      if (itemWasChanged) {
        formState.setField('pounds', inventoryItem ? inventoryItem.pounds : 0);
        formState.setField('FMV', inventoryItem ? inventoryItem.FMV : 0);
      }

      dispatch(updateMetric(updated));
    }
  });

  const onDelete = () => dispatch(removeMetric(props.item.id));

  const canAdd = useMemo(() => {
    const sum = recycling + donation + internalReuse + resale;
    return quantity - sum;
  }, [quantity, recycling, donation, internalReuse, resale]);

  const searchItems = async (query: string): Promise<SearchDropdownItem[]> => {
    const items = await InventoryItems.filter(query);
    return items.map(el => ({ text: el.title, value: el.id, item: el }))
  }

  const { inventoryItem } = formState.values;
  const dropdownItem = useMemo(() => (inventoryItem ? { text: inventoryItem.title, value: inventoryItem.id, item: inventoryItem } : undefined), [inventoryItem])

  return (
    <Fragment>
      <SearchDropdown
        placeholder="Select Item"
        {...raw({
          name: 'inventoryItem',
          onChange: item => item as InventoryItem
        })}
        className={inventoryItem ? '' : 'error'}
        defaultOptions={defaultOptions}
        selected={dropdownItem}
        onSearch={query => searchItems(query)}
      />
      <Input
        placeholder="Client product name"
        {...text('clientProductName')}
        fluid
        className="primary" />
      <Input {...number('quantity')} min="0" className="primary"/>
      <Input {...number('pounds')} min="0" className="primary"/>
      <Input {...number('FMV')} min="0" className="primary"/>

      <div className={styles['line-container']}/>

      <Input {...number('donation')} min="0" max={canAdd + donation}
             className={`primary ${canAdd && 'error'}`}/>
      <Input {...number('recycling')} min="0" max={canAdd + recycling}
             className={`primary ${canAdd && 'error'}`}/>
      <Input {...number('internalReuse')} min="0" max={canAdd + internalReuse}
             className={`primary ${canAdd && 'error'}`}/>
      <Input {...number('resale')} min="0" max={canAdd + resale}
             className={`primary ${canAdd && 'error'}`}/>
      <Grid.Column width="1">
        <Button circular icon="close" className={styles.delete} onClick={onDelete}/>
      </Grid.Column>
    </Fragment>
  );
};

export default MetricInfo;
