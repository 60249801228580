import {ThunkAction} from "redux-thunk";
import {AppState} from "../index";
import {Action} from "redux";
import {
  CharityReportings,
  InventoryItems,
  InventoryListItems,
  Metrics,
  Projects,
  RecyclingReportings,
  Uploads
} from "../../utils/requests";
import {
  IDM_ADD_DONATION_RUN_CHARITY_REPORTING,
  IDM_ADD_METRIC,
  IDM_ADD_PROJECT_METRICS_STATIC_IMAGES,
  IDM_ADD_RECYCLING_REPORTING_TICKET,
  IDM_DELETE_PROJECT_METRICS_STATIC_IMAGE,
  IDM_PROJECT_SET_METRICS_STATUS,
  IDM_REMOVE_DONATION_RUN_CHARITY_REPORTING,
  IDM_REMOVE_METRIC,
  IDM_REMOVE_RECYCLING_REPORTING_TICKET,
  IDM_SET_IS_LOADING,
  IDM_UPDATE_CHARITY_REPORTING_ITEM,
  IDM_UPDATE_METRIC,
  IDM_UPDATE_PROJECT_METRICS_STATIC_IMAGE,
  IDM_UPDATE_RECYCLING_REPORTING_TICKET,
  INPUT_DIVERSION_METRICS_SET,
  InputDiversionMetricsActionTypes
} from "./types";
import {CharityReporting, Metric, Photo, RecyclingReportingTicket} from "../typings/types";
import {createUID, isLocalId} from "../utils";
import {projectToggleLoading} from "../projectForEdit/actions";
import {createProject} from "../data";
import {differenceBy} from "lodash";
import {setProjectForUpload} from "../helper";

export function updateMetric(item: Metric): InputDiversionMetricsActionTypes {
  return {
    type: IDM_UPDATE_METRIC,
    payload: item
  };
}

export function addRecyclingReportingTicket(item: RecyclingReportingTicket): InputDiversionMetricsActionTypes {
  return {
    type: IDM_ADD_RECYCLING_REPORTING_TICKET,
    payload: item
  };
}

export function removeRecyclingReportingTicket(item: RecyclingReportingTicket): InputDiversionMetricsActionTypes {
  return {
    type: IDM_REMOVE_RECYCLING_REPORTING_TICKET,
    payload: item
  };
}

export function updateRecyclingReportingTicket(item: RecyclingReportingTicket): InputDiversionMetricsActionTypes {
  return {
    type: IDM_UPDATE_RECYCLING_REPORTING_TICKET,
    payload: item
  };
}

export function addProjectMetricsStaticImages(photos: Photo[]): InputDiversionMetricsActionTypes {
  return {
    type: IDM_ADD_PROJECT_METRICS_STATIC_IMAGES,
    payload: photos
  };
}

export function updateProjectMetricsStaticImage(oldImgId: string, photo: Photo): InputDiversionMetricsActionTypes {
  return {
    type: IDM_UPDATE_PROJECT_METRICS_STATIC_IMAGE,
    payload: {
      oldImgId: oldImgId,
      photo: photo
    }
  };
}

export function deleteProjectMetricsStaticImage(imgId: string): InputDiversionMetricsActionTypes {
  return {
    type: IDM_DELETE_PROJECT_METRICS_STATIC_IMAGE,
    payload: {
      imgId: imgId
    }
  };
}

export function addDonationRunCharityReporting(donationRunNo: number): InputDiversionMetricsActionTypes {
  return {
    type: IDM_ADD_DONATION_RUN_CHARITY_REPORTING,
    payload: { donationRunNo }
  };
}

export function removeDonationRunCharityReporting(donationRunNo: number): InputDiversionMetricsActionTypes {
  return {
    type: IDM_REMOVE_DONATION_RUN_CHARITY_REPORTING,
    payload: { donationRunNo }
  };
}

export function updateCharityReportingItem(item: CharityReporting): InputDiversionMetricsActionTypes {
  return {
    type: IDM_UPDATE_CHARITY_REPORTING_ITEM,
    payload: item
  };
}

export function setIDMIsLoading(isLoading: boolean): InputDiversionMetricsActionTypes {
  return {
    type: IDM_SET_IS_LOADING,
    payload: isLoading
  };
}

export function setProjectMetricsStatus(metricsStatus: string): InputDiversionMetricsActionTypes {
  return {
    type: IDM_PROJECT_SET_METRICS_STATUS,
    payload: metricsStatus
  };
}

export function addMetric(): InputDiversionMetricsActionTypes {
  return {
    type: IDM_ADD_METRIC
  };
}

export function removeMetric(id: string): InputDiversionMetricsActionTypes {
  return {
    type: IDM_REMOVE_METRIC,
    payload: { id }
  };
}

export function fetchInputDiversionMetrics(projectId?: string): ThunkAction<void, AppState, null, Action<string>> {
  return async (dispatch) => {

    dispatch(projectToggleLoading(true));

    dispatch(setIDMIsLoading(true))

    if (projectId) {
      const results = await Promise.all([
        Projects.getById(projectId),
        Metrics.getProjectMetrics(projectId),
        RecyclingReportings.getProjectRecyclingReporting(projectId),
        RecyclingReportings.getRecyclingReportingGrades(),
        InventoryItems.all(5)
      ]);

      let [project, metrics, recyclingReporting, recyclingReportingGrades, inventoryItems] = results;
      let charityReporting = [];

      if (!metrics.length && project.quote) {
        const inventoryListItems = await InventoryListItems.getByQuotes(project.quote.id);
        metrics = inventoryListItems.map(item => {
          return {
            id: createUID(),
            clientProductName: item.clientProductName,
            quantity: item.quantity,
            pounds: item.pounds,
            FMV: item.FMV,
            recycling: item.recycling,
            donation: item.donation,
            internalReuse: item.internalReuse,
            resale: item.resale,
            inventoryItem: item.inventoryItem,
            project: project,
            localeWasEdited: true
          }
        });

        let requests:any = [];
        metrics.forEach(ele => {
          requests.push(Metrics.addOrUpdate(ele));
        });
        metrics = await Promise.all(requests);
        await Projects.addOrUpdate(setProjectForUpload({...project, metricsStatus: 'Draft'}));
      }

      recyclingReporting = recyclingReporting.map(ele => {
        if (ele.dateOnTicket) {
          ele.dateOnTicket = new Date(ele.dateOnTicket);
        }
        return ele;
      });

      const metricIds: string[] = [];
      metrics.forEach(e => {
          metricIds.push(e.id);
      });
      charityReporting = await CharityReportings.getMetricsCharityReporting(metricIds);
      if (!charityReporting.length) {
        charityReporting = metrics.map(metric => {
          return {
            id: createUID(),
            quantityDonated: 0,
            signOfSheetImage: undefined,
            donationRunNo: 0,
            metric: metric,
            localeWasEdited: true
          };
        });
      } else {
        let metricFound = null;
        charityReporting = charityReporting.map(item => {
          metricFound = metrics.find(el => el.id == item.metric.id);
          if (metricFound) {
            return {
              ...item,
              metric: metricFound
            }
          }
          return item;
        });
      }

      dispatch({
        type: INPUT_DIVERSION_METRICS_SET,
        payload: {
          project,
          metrics,
          originalMetrics: metrics,
          recyclingReporting,
          originalRecyclingReporting: recyclingReporting,
          charityReporting,
          originalCharityReporting: charityReporting,
          recyclingReportingGrades,
          inventoryItems
        }
      })
    } else {
      let inventoryItems = await InventoryItems.all(5);
      dispatch({
        type: INPUT_DIVERSION_METRICS_SET,
        payload: {
          project: createProject(),
          metrics: [],
          originalMetrics: [],
          recyclingReporting: [],
          originalRecyclingReporting: [],
          charityReporting: [],
          originalCharityReporting: [],
          inventoryItems
        }
      })
    }

    dispatch(setIDMIsLoading(false))

    dispatch(projectToggleLoading(false));
  }
}

export function updateInputDiversionMetrics(): ThunkAction<void, AppState, null, Action<string>> {
  return async (dispatch, getState) => {

    dispatch(projectToggleLoading(true))

    const {
      inputDiversionMetrics: {
        metrics,
        originalMetrics,
        project,
        recyclingReporting,
        originalRecyclingReporting,
        charityReporting,
        originalCharityReporting
      }
    } = getState();

    let requests:any = [];

    // metrics
    const updateMetrics = metrics.filter(ele => ele.localeWasEdited || isLocalId(ele.id));
    if (updateMetrics.length) {
      updateMetrics.forEach(ele => {
        requests.push(Metrics.addOrUpdate(ele));
      });
    }
    const removeMetrics = differenceBy(originalMetrics, metrics, 'id').filter(el => !isLocalId(el.id));
    if (removeMetrics.length) {
      removeMetrics.forEach(ele => {
        requests.push(Metrics.delete(ele.id));
      });
    }

    // recycling reporting
    const updateRecyclingReportingTickets = recyclingReporting.filter(ele => ele.localeWasEdited || isLocalId(ele.id));
    if (updateRecyclingReportingTickets.length) {
      let res = null;
      for (const item of updateRecyclingReportingTickets) {
        res = await RecyclingReportings.addOrUpdate({...item, signOfSheetImage: undefined});
        item.id = res.id;
        if (!item.signOfSheetImage && (res.signOfSheetImage && res.signOfSheetImage.id)) {
          await Uploads.deletePhoto(res.signOfSheetImage);
        }
        if (item.signOfSheetImage && item.signOfSheetImage.localFile) {
          const res = await RecyclingReportings.addLogo(item.signOfSheetImage.localFile, item.id)
          item.signOfSheetImage = {
            id: res.id,
            url: res.url
          };
        }
      }
    }
    const removeRecyclingReportingTickets = differenceBy(originalRecyclingReporting, recyclingReporting, 'id').filter(el => !isLocalId(el.id));
    if (removeRecyclingReportingTickets.length) {
      removeRecyclingReportingTickets.forEach(ele => {
        requests.push(RecyclingReportings.delete(ele.id));
        if (ele.signOfSheetImage) {
          requests.push(Uploads.deletePhoto(ele.signOfSheetImage));
        }
      });
    }

    // project, for updating metrics static images
    if (project) {
      const updateProject = setProjectForUpload(project)
      requests.push(Projects.addOrUpdate(updateProject));
    }

    // charity reporting
    const updateCharityReporting = charityReporting.filter(ele => ele.localeWasEdited || isLocalId(ele.id));
    if (updateCharityReporting.length) {
      let res = null;
      for (const item of updateCharityReporting) {
        res = await CharityReportings.addOrUpdate({...item, signOfSheetImage: undefined});
        item.id = res.id;
        if (!item.signOfSheetImage && (res.signOfSheetImage && res.signOfSheetImage.id)) {
          await Uploads.deletePhoto(res.signOfSheetImage);
        }
        if (item.signOfSheetImage && item.signOfSheetImage.localFile) {
          const res = await CharityReportings.addLogo(item.signOfSheetImage.localFile, item.id)
          item.signOfSheetImage = {
            id: res.id,
            url: res.url
          };
        }
      }
    }
    const removeCharityReporting = differenceBy(originalCharityReporting, charityReporting, 'id').filter(el => !isLocalId(el.id));
    if (removeCharityReporting.length) {
      removeCharityReporting.forEach(ele => {
        requests.push(CharityReportings.delete(ele.id));
        if (ele.signOfSheetImage) {
          requests.push(Uploads.deletePhoto(ele.signOfSheetImage));
        }
      });
    }

    // execute all promises
    if (requests.length) {
      await Promise.all(requests);
    }
    dispatch(fetchInputDiversionMetrics())

    dispatch(projectToggleLoading(false))
  };
}

export function projectUploadMetricsStaticImages(files: File[]): ThunkAction<void, AppState, null, Action<string>> {
  return async (dispatch) => {
    const photos = files.map((file): Photo => {
      return {
        id: createUID(),
        url: '',
        localFile: file
      }
    });

    dispatch(addProjectMetricsStaticImages(photos));

    for (const el of photos) {
      const res = await Uploads.uploadPhoto(el.localFile as File)

      if (!res) {
        return
      }

      const serverImg = {
        ...el,
        id: res.id,
        url: res.url,
        localFile: undefined,
      }
      dispatch(updateProjectMetricsStaticImage(el.id, serverImg))
    }
  }
}
