import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { differenceBy } from "lodash";

import { AppState } from "..";
import {
  ProjectInfoForUpload,
  Projects,
  Quotes,
  SendDatas,
  Qcoeffs,
  InventoryListItems,
  InventoryItemUpload,
  QuoteInfoForUpload,
} from "../../utils/requests";
import {
  ProjectSetFromServerPayload,
  ProjectForEditActionTypes,
  PROJECT_SET_FROM_SERVER,
} from "./types";
import { InventoryListItem } from "../typings/types";
import { isLocalId } from "../utils";
import { projectUpdateInfo, projectUpdateEmails } from "./actions";
import { getUser } from "../../utils/user";

function projectSetFromServer(
  options: ProjectSetFromServerPayload
): ProjectForEditActionTypes {
  return {
    type: PROJECT_SET_FROM_SERVER,
    payload: options,
  };
}

type ActionDispatch = ThunkDispatch<AppState, null, Action<string>>;
type StateGetter = () => AppState;

export async function uploadProjectInfo(
  getState: StateGetter,
  dispatch: ActionDispatch,
  sendEmails: boolean
): Promise<void> {
  let {
    projectForEdit: {
      project: {
        id,
        refNumber,
        title,
        client,
        wlProvider,
        address,
        date,
        LP,
        prOfComp,
        isProjectDateTBD,
        projectOwner,
        netPaymentTerms,
        clientContact,
      },
      projectWasChanged,
    },
  } = getState();

  if (!projectWasChanged && !sendEmails) {
    return;
  }

  if (!projectOwner) {
    const user = getUser();
    projectOwner = {
      id: user ? user.id : null,
      username: user ? user.username : null,
      email: user ? user.email : null,
    };
  }

  let projectInfoForUpload: ProjectInfoForUpload = {
    id,
    refNumber,
    title,
    address,
    date,
    prOfComp: sendEmails ? 50 : prOfComp,
    client: client ? client.id : undefined,
    wlProvider: wlProvider ? wlProvider.id : undefined,
    LP: LP ? LP.id : undefined,
    projectOwner: projectOwner.id,
    isProjectDateTBD: isProjectDateTBD,
    netPaymentTerms: netPaymentTerms ? netPaymentTerms : undefined,
    clientContact: clientContact ? clientContact.id : undefined,
  };

  if (!refNumber) {
    projectInfoForUpload.refNumber = await Projects.fetchNextProjectRefNumber(
      new AbortController()
    );
  }

  const res = await Projects.addOrUpdate(projectInfoForUpload);

  if (!id || !refNumber) {
    dispatch(
      projectUpdateInfo({
        id: res.id,
        uploaded: true,
        refNumber: projectInfoForUpload.refNumber,
        title,
        client,
        address,
        date,
        lp: LP,
        isProjectDateTBD,
        projectOwner,
        netPaymentTerms,
        clientContact,
        wlProvider,
      })
    );
  }
}

export function uploadQuoteInfo(
  getState: StateGetter,
  dispatch: ActionDispatch,
  childWasUpdated: boolean
): Promise<void> {
  const {
    projectForEdit: {
      project: {
        id: projectId,
        quote: {
          id,
          LP,
          transferOfTitle,
          recovery,
          resold,
          includeMoveMgmt,
          currency,
          senddata: { id: senddataId },
          qCoeff: { id: qCoeffId },
          inventoryListItems,
          manualMoveManagement,
        },
      },
      quoteWasChanged,
    },
  } = getState();

  const projectWasAdded = !isLocalId(projectId);
  const quoteWasAdded = !isLocalId(id);

  if (!projectWasAdded) {
    return Promise.resolve();
  }

  const needSaveForItems = inventoryListItems.length && !quoteWasAdded;

  if (!quoteWasChanged && !childWasUpdated && !needSaveForItems) {
    return Promise.resolve();
  }

  const data: QuoteInfoForUpload = {
    id,
    LP,
    transferOfTitle,
    recovery,
    resold,
    includeMoveMgmt,
    currency,
    projects: [projectId],
    senddata: isLocalId(senddataId) ? undefined : senddataId,
    qCoeff: isLocalId(qCoeffId) ? undefined : qCoeffId,
    manualMoveManagement,
  };

  return Quotes.addOrUpdate(data).then((res) => {
    dispatch(projectSetFromServer({ field: "quote", id: res.id }));
  });
}

export async function uploadEmailsInfo(
  getState: StateGetter,
  dispatch: ActionDispatch
): Promise<{ inserted: boolean }> {
  const {
    projectForEdit: {
      project: {
        quote: {
          senddata: { id, to, cc, bcc },
        },
      },
      emailsWasChanged,
    },
  } = getState();

  if (!emailsWasChanged) {
    return { inserted: false };
  }

  const data = {
    id,
    to,
    cc,
    bcc,
  };

  if (!to && !cc && !bcc) {
    return { inserted: false };
  }

  const res = await SendDatas.addOrUpdate(data);

  const created = res.id != id;

  if (created) {
    dispatch(
      projectUpdateEmails({
        uploaded: true,
        id: res.id,
        to,
        cc,
        bcc,
      })
    );
  }

  return { inserted: created };
}

export function uploadCoefficients(
  getState: StateGetter,
  dispatch: ActionDispatch
): Promise<{ inserted: boolean }> {
  const {
    projectForEdit: {
      project: {
        quote: { qCoeff },
      },
      quoteCoefficientWasChanged,
    },
  } = getState();

  if (!quoteCoefficientWasChanged) {
    return Promise.resolve({ inserted: false });
  }

  const id = qCoeff.id;

  return Qcoeffs.addOrUpdate(qCoeff).then((res) => {
    dispatch(projectSetFromServer({ field: "coefficients", id: res.id }));
    return { inserted: id != res.id };
  });
}

export function uploadInventoryListItems(
  getState: StateGetter,
  dispatch: ActionDispatch
): Promise<void> {
  const {
    projectForEdit: {
      project: {
        quote: { id: quoteId, inventoryListItems },
      },
      originalProject,
    },
  } = getState();

  const quoteWasNotAdded = isLocalId(quoteId);

  if (quoteWasNotAdded) {
    return Promise.resolve();
  }

  const forUpdate = inventoryListItems.filter((el) => el.localeWasEdited);
  let forRemove: InventoryListItem[] = [];

  if (
    originalProject &&
    originalProject.quote &&
    originalProject.quote.inventoryListItems
  ) {
    const originalItems = originalProject.quote.inventoryListItems;
    forRemove = differenceBy(originalItems, inventoryListItems, "id").filter(
      (el) => !isLocalId(el.id)
    );
  }

  let requests: Promise<any>[] = [];

  if (forUpdate.length) {
    requests = requests.concat(
      forUpdate.map((item) =>
        uploadAndUpdateStoreForListItem(item, quoteId, dispatch)
      )
    );
  }

  if (forRemove.length) {
    requests = requests.concat(
      forRemove.map((item) => InventoryListItems.delete(item.id))
    );
  }

  return Promise.all(requests).then();
}

function uploadAndUpdateStoreForListItem(
  item: InventoryListItem,
  quoteId: string,
  dispatch: ActionDispatch
): Promise<void> {
  let copy: InventoryItemUpload = Object.assign({}, item, {
    inventoryItem: item.inventoryItem ? item.inventoryItem.id : undefined,
    quote: quoteId,
    photos: item.photos.map((el) => el.id),
  });
  return InventoryListItems.addOrUpdate(copy).then((res) => {
    dispatch(
      projectSetFromServer({
        field: "listitem",
        id: res.id,
        oldId: item.id,
      })
    );
  });
}
