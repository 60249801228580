import React, { useMemo } from 'react';
import { Grid, Input, Button } from 'semantic-ui-react';
import { useFormState } from 'react-use-form-state';
import { useDispatch } from 'react-redux';
import styles from './index.module.css';

import SearchDropdown, { SearchDropdownItem } from '../SearchDropdown';

import { InventoryListItem, InventoryItem } from '../../../store/typings/types';
import { projectDeleteItem, projectUpdateItemById } from '../../../store/projectForEdit/actions';
import { createInventoryItemFromListItem } from '../../../store/data';
import { convertPoundsToTonnes, round } from '../../../utils/calculation';
import { InventoryItems } from '../../../utils/requests';

type FormValues = {
  inventoryItem: InventoryItem | undefined;
  quantity: number;
  pounds: number;
  FMV: number;
  clientProductName: string;
};

type Props = {
  item: InventoryListItem;
  defaultOptions: SearchDropdownItem[];
};

const InventoryItemInfo: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { item, defaultOptions } = props;

  const itemToForm = (): FormValues => {
    const { clientProductName, inventoryItem, FMV, quantity, pounds } = item;
    const clientTitle = clientProductName || (inventoryItem && inventoryItem.title) || '';
    return {
      inventoryItem,
      quantity,
      pounds,
      FMV,
      clientProductName: clientTitle
    };
  };

  const formToItem = (form: FormValues, itemWasChange: boolean, overwriteTitle: boolean): InventoryListItem => {
    const inventoryItem = form.inventoryItem as InventoryItem;
    const useValuesFromInventoryItem = form.inventoryItem && itemWasChange;

    return {
      ...item,
      inventoryItem: inventoryItem,
      quantity: form.quantity,
      pounds: useValuesFromInventoryItem ? inventoryItem.pounds : +form.pounds,
      FMV: useValuesFromInventoryItem ? inventoryItem.FMV : +form.FMV,
      clientProductName: overwriteTitle && useValuesFromInventoryItem ? inventoryItem.title : form.clientProductName
    }
  };

  const onDelete = () => dispatch(projectDeleteItem(props.item.id));
  const formValues = itemToForm();

  const [formState, { number, raw, text }] = useFormState<FormValues>(formValues, {
    onChange(e, stateValues, nextStateValues) {
      const { inventoryItem, quantity, pounds, FMV, clientProductName } = nextStateValues;

      const itemWasChanged = stateValues.inventoryItem !== inventoryItem;
      const canOverwriteUserTitle = (stateValues.inventoryItem && stateValues.inventoryItem.title) === clientProductName;

      const updated = formToItem({
        inventoryItem,
        quantity: +quantity,
        pounds: +pounds,
        FMV: +FMV,
        clientProductName
      }, itemWasChanged, canOverwriteUserTitle);

      if (itemWasChanged) {
        formState.setField('pounds', updated.pounds)
        formState.setField('FMV', updated.FMV)
      }

      if (canOverwriteUserTitle) {
        formState.setField('clientProductName', updated.clientProductName || '')
      }

      dispatch(projectUpdateItemById(updated));
    }
  });

  const searchItems = async (query: string): Promise<SearchDropdownItem[]> => {
    const items = await InventoryItems.filter(query);
    return items.map(el => ({ text: el.title, value: el.id, item: el }))
  }

  const { inventoryItem } = formState.values;
  const dropdownItem = useMemo(() => (inventoryItem ? { text: inventoryItem.title, value: inventoryItem.id, item: inventoryItem } : undefined), [inventoryItem])

  const { item: { pounds, quantity } } = props;
  const tonnes = useMemo(() => round(convertPoundsToTonnes(pounds, quantity), 10), [pounds, quantity])

  return (
    <Grid.Row>
      <Grid.Column width="3">
        <SearchDropdown
          placeholder="Select Item"
          {...raw({
            name: 'inventoryItem',
            onChange: item => item as InventoryItem
          })}
          className={inventoryItem ? '' : 'error'}
          onAdd={async (text: string) => {
            const forUpload = createInventoryItemFromListItem(text, item)
            const res = await InventoryItems.add(forUpload);
            return { text: text, value: res.id, item: res };
          }}
          defaultOptions={defaultOptions}
          selected={dropdownItem}
          onSearch={query => searchItems(query)}
        />
      </Grid.Column>
      <Grid.Column width="3">
        <Input
          placeholder="Client product name"
          {...text('clientProductName')}
          fluid
          className="primary" />
      </Grid.Column>
      <Grid.Column width="2">
        <Input {...number('quantity')} min="0" fluid className="primary" />
      </Grid.Column>
      <Grid.Column width="2">
        <Input {...number('pounds')} min="0" fluid className="primary" />
      </Grid.Column>
      <Grid.Column width="3">
        <Input value={tonnes} disabled={true} fluid className="primary" />
      </Grid.Column>
      <Grid.Column width="2">
        <Input {...number('FMV')} min="0" fluid className="primary" />
      </Grid.Column>
      <Grid.Column width="1">
        <Button circular icon="close" className={styles.delete} onClick={onDelete} />
      </Grid.Column>
    </Grid.Row>
  );
};

export default InventoryItemInfo;
