import React, {useState} from "react";
import { Button } from "semantic-ui-react";
import {API_URL} from "../../../config";
import {getUser} from "../../../utils/user";

const ModePdfExport: React.FC = () => {
  const user = getUser();
  const [loading, setLoading] = useState<boolean>(false);

  const exportPdf = async () => {
    setLoading(true);
    try {
      let responseOne: any = await fetch(`${API_URL}/clients/export-pdf/initiate`, {
        method: 'GET',
        headers: { 'Authorization': 'Bearer '+ user.token }
      })
      responseOne = await responseOne.json();

      let mostRecentReportRunToken: string = responseOne._embedded.report_runs[0].token;
      const timeout = Date.now() + 60 * 5; // close the call after 5 min
      const interval = setInterval(async () => {
        let responseTwo: any = await fetch(`${API_URL}/clients/export-pdf/status`, {
          method: 'POST',
          headers: { 'Authorization': 'Bearer '+ user.token },
          body: JSON.stringify({
            mostRecentReportRunToken
          })
        });
        responseTwo = await responseTwo.json();
        const fileName = responseTwo.filename;
        const state = responseTwo.state;
        if (state === "enqueued") {
          console.log('Pdf generation is still in progress.');
        } else if (state === "completed") {
          let responseThree: any = await fetch(`${API_URL}/clients/export-pdf/download`, {
            method: 'POST',
            headers: { 'Authorization': 'Bearer '+ user.token },
            body: JSON.stringify({
              mostRecentReportRunToken
            })
          });
          const blob = await responseThree.blob();
          const url = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.download = `${fileName}.pdf`;
          link.click();

          URL.revokeObjectURL(url);
          setLoading(false);
          clearInterval(interval);
          return;
        }
        if (Date.now() >= timeout) return;
      }, 5000);

    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Button className="primary btn-export" href="" onClick={() => exportPdf()} disabled={loading}>
      {loading ? 'Generating PDF...' : 'Export PDF'}
    </Button>
  );
};

export default ModePdfExport;
