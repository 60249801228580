import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Modal, Tab } from 'semantic-ui-react';
import styles from './index.module.css'

import LPEditor from '../../common/modals/LPEditor';
import TabsModal from '../../common/modals/TabsModal';
import ProjectsModal from '../../common/modals/ProjectsModal';
import LoadingEl from '../../common/components/LoadingEl';

import {
  LogisticPartner,
  PaginationParams
} from '../../store/typings/types';
import { LPs } from '../../utils/requests';
import { debounce } from 'lodash';
import EcoPagination from '../../common/components/EcoPagination';
import { PAGINATION_ITEMS_PER_PAGE } from '../../common/constants/common';

const LPPage: React.FC = () => {
  const [lps, setLPs] = useState<LogisticPartner[] | undefined>(undefined);
  const [selectedLP, setSelectedLP] = useState<LogisticPartner | undefined>();
  const reload = () => setLPs(undefined);
  const addLP = (lp: LogisticPartner) => setLPs(!!lps ? lps.concat([lp]) : [lp]);

  const [isLoading, setIsLoading] = useState(false);

  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const handleOpenEditModal = (lp: LogisticPartner) => {
    setSelectedLP(lp);
    setIsEditModalOpened(true);
  };
  const handleCloseEditModal = () => setIsEditModalOpened(false);

  const [isAddModalOpened, setIsAddModalOpened] = useState(false);
  const handleAddModal = (isOpen: boolean) => setIsAddModalOpened(isOpen);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!lps) {
      fetchLPs({limit: PAGINATION_ITEMS_PER_PAGE});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lps]);

  const fetchLPs = async (queryParams: PaginationParams) => {
    setIsLoading(true);
    if (!queryParams.offset) {
      const count = await LPs.count(queryParams.searchText);
      setTotalCount(count);
    }
    const res = await LPs.findAll(queryParams);
    setLPs(res);
    setIsLoading(false);
  };

  const onPageChange = async (limit: number, offset: number) => {
    fetchLPs({limit, offset, searchText});
  };

  const search = async (text: string) => {
    setSearchText(text);
    fetchLPs({limit: PAGINATION_ITEMS_PER_PAGE, searchText: text})
  };
  const debouncedSearch = useRef(debounce(search, 400)).current;

  const panes = [
    {
      menuItem: 'Information', render: () => (
        <Tab.Pane>
          <LPEditor close={handleCloseEditModal} lp={selectedLP} onChange={reload} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Projects', render: () => (
        <Tab.Pane>
          <ProjectsModal parentFilter={{ lp: selectedLP ? selectedLP.id : undefined }} />
        </Tab.Pane>
      )
    }
  ];

  return (
    <div className="page-padding content-scrollable">
      <div className={styles.header}>
        <h1 className="secondary-text">Logistics Partners</h1>
        <span>
          <Modal
            trigger={
              <Button circular icon="plus" className="primary" onClick={() => handleAddModal(true)} />
            }
            open={isAddModalOpened}
            onClose={() => handleAddModal(false)}>
            <LPEditor
              close={() => handleAddModal(false)}
              onChange={lp => addLP(lp)}
            />
          </Modal>
          <Input
            icon="search"
            placeholder="Search"
            className="primary"
            onChange={(e, data) => debouncedSearch(data.value)} />
        </span>
      </div>

      <div className="relative">
        <LoadingEl show={typeof lps === 'undefined' || isLoading} onlySpinner={true} />

        <table className="table table-striped table-vertical-bordered primary-text">
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Email</th>
              <td />
            </tr>
          </thead>
          <tbody>
            {
              lps && lps.map(lp => (
                <tr key={lp.id}>
                  <td>{lp.title}</td>
                  <td>{lp.email}</td>
                  <td>
                    <Button icon="edit" compact className="clear primary-text" onClick={() => handleOpenEditModal(lp)} />
                  </td>
                </tr>
              ))
            }

            <Modal open={isEditModalOpened} onClose={handleCloseEditModal} size="fullscreen">
              <TabsModal panes={panes} defaultActiveIndex={1} />
            </Modal>
          </tbody>
        </table>
        <EcoPagination
          loading={isLoading}
          totalCount={totalCount}
          onPaginate={onPageChange}
        />
      </div>
    </div>
  );
};

export default LPPage;
