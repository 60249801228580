import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import HISTORY from "../routing/history";
import ROUTES_URL from "../routing/urls";
import {getUser, getUserRole, ROLES} from "../utils/user";

class AuthenticatedRoutes extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      user: undefined
    }
  }

  componentDidMount() {
    const user = getUser();
    if (!user) {
      HISTORY.push(ROUTES_URL.LOGIN);
    } else {
      this.setState({
        user: user
      });
      const role = getUserRole();
      if (role === ROLES.LOGISTIC_PARTNER) {
        HISTORY.push(ROUTES_URL.IDM);
      } else if(role === ROLES.SYSTEM_MANAGER) {
        HISTORY.push(ROUTES_URL.DASHBOARD);
      } else {
        HISTORY.push(ROUTES_URL.CLIENT.CLIENT_HOME);
      }
    }
  }

  render() {
    if (!this.state.user) {
      return (
        <div></div>
      );
    }
    return (
      <div className="dashboard">
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(AuthenticatedRoutes);
