import React, { useState } from "react";
import { useFormState } from "react-use-form-state";
import styles from "./index.module.css";
import { Button, Form, Grid, Header, Message } from "semantic-ui-react";
import Logo from "../../assets/logo.png";
import { User } from "../../utils/requests";
import { setUser } from "../../utils/user";
import { ROLES } from "../../utils/user";
import ROUTES_URL from "../../routing/urls";
interface FormFields {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const [loginInProgress, setLoginInProgress] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [errorMsgs, setErrorMsgs] = useState<string[]>([]);

  const [formState, { email, password }] = useFormState<FormFields>(
    {
      email: "",
      password: "",
    },
    {}
  );

  const loginUser = async () => {
    let { email, password } = formState.values;
    let errors = [];
    if (!email) {
      errors.push("Please provide email");
    }
    if (!password) {
      errors.push("Sorry, wrong password");
    }
    if (errors.length) {
      setErrorMsgs(errors);
      setError(true);
      return;
    }
    setErrorMsgs([]);
    setLoginInProgress(true);
    const res = await User.login({ email, password });
    if (res.user) {
      if (
        res.user.role &&
        [ROLES.SYSTEM_MANAGER, ROLES.LOGISTIC_PARTNER, ROLES.CLIENT].indexOf(
          res.user.role.type
        ) > -1
      ) {
        setUser(res);
        window.location.reload();
        //HISTORY.push(ROUTES_URL.DASHBOARD);
      } else {
        setErrorMsgs(["User having system manager role can access."]);
        setError(true);
        setLoginInProgress(false);
      }
    } else {
      setErrorMsgs([
        res.message.map((el: any) => el.messages.map((el: any) => el.message)),
      ]);
      setError(true);
      setLoginInProgress(false);
    }
  };

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          <img src={Logo} className={styles.logo} alt="eco solutions" />
        </Header>

        <h1 className="secondary-text">Business Portal Login</h1>

        <Form size="large" error>
          <Form.Field
            placeholder="E-mail address"
            className={styles["form-el"]}
          >
            <label className={styles.label}>E-mail</label>
            <Form.Input
              {...email("email")}
              className={`primary ${styles.input}`}
            />
          </Form.Field>

          <Form.Field
            placeholder="E-mail address"
            className={styles["form-el"]}
          >
            <label className={styles.label}>Password</label>
            <Form.Input
              {...password("password")}
              className={`primary ${styles.input}`}
            />
          </Form.Field>

          {errorMsgs.length ? <Message error list={errorMsgs} /> : ""}

          <Button
            fluid
            size="large"
            loading={loginInProgress}
            disabled={loginInProgress}
            onClick={loginUser}
            className="ui button primary"
          >
            Login
          </Button>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default LoginPage;
