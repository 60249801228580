import { ReactComponentLike } from "prop-types";
import ROUTES_URL from "./urls";

import HomePage from "../pages/home";

import NewProjectPage from "../pages/new-project";
import ProjectPage from "../pages/new-project/project";
import InventoryListPage from "../pages/new-project/inventory-list";
import ItemInfoPage from "../pages/new-project/item-info";
import TotalPage from "../pages/new-project/total";
import ReviewPage from "../pages/new-project/review";
import SendPage from "../pages/new-project/send";
import SendResultPage from "../pages/new-project/send-result";
import ClientsPage from "../pages/clients";
import LPPage from "../pages/lp";
import Metrics from "../pages/input-diversion-metrics";
import PostReporting from "../pages/new-metrics/post-reporting";
import RecyclingReporting from "../pages/new-metrics/recycling-reporting";
import CharityReporting from "../pages/new-metrics/charity-reporting";
import NewMetrics from "../pages/new-metrics";
import { ROLES } from "../utils/user";
import InputDiversionMetrics from "../pages/input-diversion-metrics";
import ClientHome from "../pages/client-home";
import ClientProject from "../pages/client-project";

export type RouteConfig = {
  path: string;
  exact?: boolean;
  component: ReactComponentLike;
  routes?: RouteConfig[];
  props?: Object;
  roles?: string[];
};
const Routes: RouteConfig[] = [
  {
    path: "/",
    exact: true,
    roles: [ROLES.SYSTEM_MANAGER],
    component: HomePage,
  },
  {
    path: ROUTES_URL.NEW_PROJECT.START,
    component: NewProjectPage,
    roles: [ROLES.SYSTEM_MANAGER],
    routes: [
      {
        path: ROUTES_URL.NEW_PROJECT.START,
        exact: true,
        component: ProjectPage,
      },
      {
        path: ROUTES_URL.NEW_PROJECT.INVENTORY_LIST,
        component: InventoryListPage,
      },
      {
        path: ROUTES_URL.NEW_PROJECT.ITEM_INFO,
        component: ItemInfoPage,
      },
      {
        path: ROUTES_URL.NEW_PROJECT.TOTAL,
        component: TotalPage,
      },
      {
        path: ROUTES_URL.NEW_PROJECT.REVIEW,
        component: ReviewPage,
      },
      {
        path: ROUTES_URL.NEW_PROJECT.SEND,
        component: SendPage,
      },
      {
        path: ROUTES_URL.NEW_PROJECT.SEND_RESULT,
        component: SendResultPage,
      },
    ],
  },
  {
    path: ROUTES_URL.CLIENTS,
    roles: [ROLES.SYSTEM_MANAGER],
    component: ClientsPage,
  },
  {
    path: ROUTES_URL.LP,
    roles: [ROLES.SYSTEM_MANAGER],
    component: LPPage,
  },
  {
    path: ROUTES_URL.IDM,
    roles: [ROLES.SYSTEM_MANAGER],
    component: InputDiversionMetrics,
  },
  {
    path: ROUTES_URL.METRICS.START,
    roles: [ROLES.SYSTEM_MANAGER],
    component: NewMetrics,
    routes: [
      {
        path: ROUTES_URL.METRICS.POST_PROJECT_REPORTING,
        exact: true,
        component: PostReporting,
      },
      {
        path: ROUTES_URL.METRICS.RECYCLING_REPORTING,
        component: RecyclingReporting,
      },
      {
        path: ROUTES_URL.METRICS.CHARITY_REPORTING,
        component: CharityReporting,
      },
    ],
  },
  {
    path: ROUTES_URL.CLIENT.CLIENT_HOME,
    roles: [ROLES.CLIENT],
    exact: true,
    component: ClientHome,
  },
  {
    path: ROUTES_URL.CLIENT.CLIENT_PROJECT,
    roles: [ROLES.CLIENT],
    exact: true,
    component: ClientProject,
  },
];

export default Routes;
