import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './index.module.css';

interface DatePickerCustomProps {
	value: Date;
	onChange: (date: Date) => void;
	disabled: boolean
}

const DatePickerCustom: React.FC<DatePickerCustomProps> = (props) => {
	return <DatePicker selected={props.value} onChange={props.onChange} className={styles.text} disabled={props.disabled} />;
};

export default DatePickerCustom;
