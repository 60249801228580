import React from "react";
import SystemApp from "../common/components/AppRoles/SystemApp";
import { getUserRole, ROLES } from "../utils/user";
import ClientApp from "../common/components/AppRoles/ClientApp";

import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";

const App: React.FC = () => {
  const role = getUserRole();

  return (
    <>
      {(role === ROLES.LOGISTIC_PARTNER || role === ROLES.SYSTEM_MANAGER) && (
        <SystemApp />
      )}
      {role === ROLES.CLIENT && <ClientApp />}

      <NotificationContainer />
    </>
  );
};

export default App;
