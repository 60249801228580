export const PROJECT_METRICS_STATUSES = {
  NOT_SUBMITTED: 'NotSubmitted',
  DRAFT: 'Draft',
  SUBMITTED: 'Submitted',
  APPROVED: 'Approved',
};

export function setProjectMetricsStaticImages(projects: any): any {
    // to set default value of projectMetricsStaticImages
    if (Array.isArray(projects)) {
        projects = projects.map((el: any) => {
            el.projectMetricsStaticImages = el.projectMetricsStaticImages ? el.projectMetricsStaticImages : [];
            return el;
        });
    } else {
        projects.projectMetricsStaticImages = projects.projectMetricsStaticImages ? projects.projectMetricsStaticImages : [];
    }
    return projects;
}
