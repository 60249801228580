import React, {Fragment, useMemo} from 'react';
import {Input, Button, Icon} from 'semantic-ui-react';
import {useDispatch} from 'react-redux';
import {useFormState} from 'react-use-form-state';
import styles from './index.module.css';

import {InventoryListItem} from '../../../store/typings/types';
import {projectUpdateItemById} from '../../../store/projectForEdit/actions';
import {calcWeight, calcItemValue, round} from '../../../utils/calculation';

type FormValues = {
  recycling: number;
  donation: number;
  internalReuse: number;
  resale: number;
};

type Props = {
  item: InventoryListItem;
  onUploadClick: (item: InventoryListItem) => void;
  active?: boolean;
  hideWeightAndValue?: boolean
};

const InventoryItemDetails: React.FC<Props> = (props) => {
  const {item, active, hideWeightAndValue} = props;
  const {inventoryItem, quantity, pounds, FMV, recycling, donation, internalReuse, resale, clientProductName} = item;

  const dispatch = useDispatch();

  const [, {number}] = useFormState<FormValues>(item, {
    onChange(e, stateValues, nextStateValues) {
      const {recycling, donation, internalReuse, resale} = nextStateValues;

      const updated = {
        ...props.item,
        inventoryItem: props.item.inventoryItem,
        recycling: +recycling,
        donation: +donation,
        internalReuse: +internalReuse,
        resale: +resale
      };
      dispatch(projectUpdateItemById(updated));
    }
  });

  const weight = useMemo(() => round(calcWeight(pounds, quantity), 10), [quantity, pounds]);
  const value = useMemo(() => calcItemValue(quantity, FMV), [quantity, FMV]);
  const sum = recycling + donation + internalReuse + resale;
  const canAdd = quantity - sum;

  const itemTitle = clientProductName || (inventoryItem && inventoryItem.title) || '';

  return (
    <Fragment>
      <Input value={itemTitle} className="primary" disabled={true}/>
      <Input value={quantity} className="primary" disabled={true}/>
      {!hideWeightAndValue &&
      <>
        <Input value={weight} className="primary" disabled={true}/>
        <Input value={value}
               className={`${styles['vertical-line-right']} primary`}
               disabled={true}/>
      </>
      }

      <div className={styles['line-container']}/>

      <Input {...number('donation')} min="0" max={canAdd + donation}
             className={`primary ${canAdd && 'error'}`}/>
      <Input {...number('recycling')} min="0" max={canAdd + recycling}
             className={`primary ${canAdd && 'error'}`}/>
      <Input {...number('internalReuse')} min="0" max={canAdd + internalReuse}
             className={`primary ${canAdd && 'error'}`}/>
      <Input {...number('resale')} min="0" max={canAdd + resale}
             className={`primary ${canAdd && 'error'}`}/>
      {!hideWeightAndValue && <Button
        onClick={() => props.onUploadClick(item)}
        className={`${styles.btn} ${active ? styles.active : ''}`}
        size="tiny">
        <Icon name="arrow up" fitted={true}/>
        <span className={styles.btnText}>Drag and Drop</span>
      </Button>}
    </Fragment>
  );
};

export default InventoryItemDetails;
