import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormState } from "react-use-form-state";
import { Form, Input, Grid, Button, Modal, Dropdown } from "semantic-ui-react";
import styles from "./index.module.css";

import SearchDropdown, {
  SearchDropdownItem,
} from "../../../common/components/SearchDropdown";
import DatePickerCustom from "../../../common/components/DatePickerCustom";
import DropdownSelect from "../../../common/components/DropdownSelect";
import ClientEditor from "../../../common/modals/ClientEditor";
import LPEditor from "../../../common/modals/LPEditor";

import { AppState } from "../../../store";
import {
  Project,
  Client,
  LogisticPartner,
  ProjectOwner,
  Contact,
} from "../../../store/typings/types";
import {
  fetchProjectSelectedClientId,
  projectReplaceClientContactsItems,
  projectUpdateInfo,
} from "../../../store/projectForEdit/actions";
import { ProjectUpdateInfoPlayload } from "../../../store/projectForEdit/types";

import { Projects, LPs, Clients, Contacts } from "../../../utils/requests";
import Toggle from "../../../common/components/Toggle";
import ContactEditor from "../../../common/modals/ContactEditor";

interface FormFields {
  wlProvider: Client | undefined;
  refNumber: number | undefined;
  title: string;
  client: Client | undefined;
  address: string;
  date: Date;
  lp: LogisticPartner | undefined;
  isProjectDateTBD: boolean;
  projectOwner: ProjectOwner | undefined;
  netPaymentTerms: string | undefined;
  clientContact: Contact | undefined;
}

const DEFAULT_NET_PAYMENT_TERMS = "45";

const ProjectPage: React.FC = () => {
  const dispatch = useDispatch();
  const {
    project,
    dropdownItems: { clients, lps, clientContacts },
  } = useSelector((state: AppState) => state.projectForEdit);

  const searchClients = async (
    query: string
  ): Promise<SearchDropdownItem[]> => {
    const res = await Clients.filterByName(query);
    return res.map((el) => ({ text: el.name, value: el.id, item: el }));
  };

  const searchLPs = async (query: string): Promise<SearchDropdownItem[]> => {
    const res = await LPs.filter(query);
    return res.map((el) => ({ text: el.title, value: el.id, item: el }));
  };

  const searchClientContacts = async (
    query: string
  ): Promise<SearchDropdownItem[]> => {
    const clientId = await dispatch(fetchProjectSelectedClientId());
    if (clientId) {
      let filter = {
        filters: {
          name: { $containsi: query },
          client: { id: { $eq: clientId.toString() } },
        },
      };
      const res = await Contacts.contactsFilterByNameOrClient(filter);
      return res.map((el) => ({ text: el.name, value: el.id, item: el }));
    }
    return [];
  };

  const resetClientContactsOnAdd = (clientContacts: Contact[]) => {
    dispatch(projectReplaceClientContactsItems(clientContacts));
  };

  const formInitialData = useMemo(
    () => convertProjectToForm(project),
    [project]
  );
  const convertFormToProjectInfo = (
    form: FormFields
  ): ProjectUpdateInfoPlayload => {
    // check here
    return {
      uploaded: false,
      title: form.title,
      client: form.client || undefined,
      wlProvider: form.wlProvider || undefined,
      address: form.address,
      date: form.date.toISOString(),
      lp: form.lp || undefined,
      isProjectDateTBD: form.isProjectDateTBD,
      netPaymentTerms: form.netPaymentTerms
        ? form.netPaymentTerms.toString()
        : "",
      clientContact: form.clientContact || undefined,
    };
  };

  const [serverRefNumber, setServerRefNumber] = useState<Number | undefined>(
    undefined
  );
  const [formState, { text, raw, checkbox }] = useFormState<FormFields>(
    formInitialData,
    {
      onChange(e, stateValues, nextStateValues) {
        handleTBD(nextStateValues.isProjectDateTBD);
        const updatedProject = convertFormToProjectInfo(nextStateValues);
        dispatch(projectUpdateInfo(updatedProject));
      },
    }
  );

  const {
    values: {
      client,
      lp,
      refNumber,
      projectOwner,
      netPaymentTerms,
      clientContact,
      wlProvider,
    },
  } = formState;
  const netPaymentTermsOptions = [
    {
      text: "30",
      value: "30",
    },
    {
      text: "45",
      value: "45",
    },
    {
      text: "60",
      value: "60",
    },
    {
      text: "90",
      value: "90",
    },
    {
      text: "120",
      value: "120",
    },
  ];

  useEffect(() => {
    const abortCtrl = new AbortController();
    const fetchProjectCount = async () => {
      const number = await Projects.fetchNextProjectRefNumber(abortCtrl);
      if (typeof number === "number") {
        setServerRefNumber(number);
      }
    };

    if (!refNumber && !serverRefNumber) {
      fetchProjectCount();
    }

    return () => {
      abortCtrl.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refNumber]);

  useEffect(() => {
    const fetchClientContacts = async () => {
      if (client && client.id) {
        let filter = {
          filters: { client: { id: { $eq: client.id } } },
          pagination: { limit: 5 },
          populate: "*",
        };
        const res = await Contacts.contactsFilterByNameOrClient(filter);
        dispatch(projectReplaceClientContactsItems(res));
      }
      return [];
    };
    fetchClientContacts();
  }, [client]);

  const [isClientModalOpened, setIsClientModalOpened] = useState(false);
  const [isWlProviderModalOpened, setIsWlProviderModalOpened] = useState(false);
  const [isLPModalOpened, setIsLPModalOpened] = useState(false);
  const [isTBD, setTBD] = useState<boolean>(false);
  const [isClientContactModalOpened, setIsClientContactModalOpened] =
    useState(false);
  const handleTBD = (isTBD: boolean) => setTBD(isTBD);
  const handleClientModal = (isOpen: boolean) => setIsClientModalOpened(isOpen);
  const handleWlProviderModal = (isOpen: boolean) =>
    setIsWlProviderModalOpened(isOpen);
  const handleLPModal = (isOpen: boolean) => setIsLPModalOpened(isOpen);
  const handleClientContactModal = (isOpen: boolean) =>
    setIsClientContactModalOpened(isOpen);
  const {
    clientDropdown,
    lpDropdown,
    netPaymentTermsDropdown,
    clientContactDropdown,
    wlProviderDropdown,
  } = useMemo(() => {
    const clientSelected = client
      ? {
          text: client.name,
          value: client.id,
          item: client,
        }
      : undefined;

    const clientItems = clients.map((el) => ({
      text: el.name,
      value: el.id,
      item: el,
    }));

    const wlProviderSelected = wlProvider
      ? {
          text: wlProvider.name,
          value: wlProvider.id,
          item: wlProvider,
        }
      : undefined;

    const lpSelected = lp
      ? {
          text: lp.title,
          value: lp.id,
          item: lp,
        }
      : undefined;
    const lpsItems = lps.map((el) => ({
      text: el.title,
      value: el.id,
      item: el,
    }));

    const netPaymentTermsItems = netPaymentTermsOptions.map((el) => ({
      text: el.text,
      value: el.value,
      item: el,
    }));

    const netPaymentTermsSelected = netPaymentTerms
      ? {
          text: netPaymentTerms,
          value: netPaymentTerms,
        }
      : netPaymentTermsItems.find(
          (item) => item.value === DEFAULT_NET_PAYMENT_TERMS
        );

    const clientContactSelected = clientContact
      ? {
          text: clientContact.name,
          value: clientContact.id,
          item: clientContact,
        }
      : undefined;
    const clientContactItems = clientContacts.map((el) => ({
      text: el.name,
      value: el.id,
      item: el,
    }));

    return {
      clientDropdown: {
        clientSelected,
        clientItems,
      },
      wlProviderDropdown: {
        clientItems,
        wlProviderSelected,
      },
      lpDropdown: {
        lpSelected,
        lpsItems,
      },
      netPaymentTermsDropdown: {
        netPaymentTermsSelected,
        netPaymentTermsItems,
      },
      clientContactDropdown: {
        clientContactSelected,
        clientContactItems,
      },
    };
  }, [
    lps,
    lp,
    clients,
    client,
    wlProvider,
    netPaymentTermsOptions,
    netPaymentTerms,
    clientContacts,
    clientContact,
  ]);
  return (
    <div className="page-padding-small">
      <h1 className="secondary-text">New Project</h1>

      <Form className={styles.form}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>Reference Number</label>
                <Input
                  value={refNumber || serverRefNumber || ""}
                  className="primary"
                  disabled
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>LP Contact Name</label>
                <Input
                  value={lp ? lp.contactName : ""}
                  className="primary"
                  disabled
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>Title</label>
                <Input {...text("title")} className="primary" />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>LP</label>
                <div className={styles.dropdownGroup}>
                  <SearchDropdown
                    placeholder="Select Logistic Partner"
                    {...raw({
                      name: "lp",
                      onChange: (lp) => lp as LogisticPartner,
                    })}
                    defaultOptions={lpDropdown.lpsItems}
                    selected={lpDropdown.lpSelected}
                    onSearch={(query) => searchLPs(query)}
                  />
                  <Modal
                    trigger={
                      <Button
                        circular
                        icon="plus"
                        className="primary"
                        onClick={() => handleLPModal(true)}
                      />
                    }
                    open={isLPModalOpened}
                    onClose={() => handleLPModal(false)}
                  >
                    <LPEditor
                      {...raw({
                        name: "lp",
                        onChange: (lp) => lp as LogisticPartner,
                      })}
                      close={() => handleLPModal(false)}
                    />
                  </Modal>
                </div>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>Client Primary Contact</label>
                <div className={styles.dropdownGroup}>
                  <SearchDropdown
                    placeholder="Select Contact"
                    disabled={!client}
                    {...raw({
                      name: "clientContact",
                      onChange: (clientContact) => clientContact as Contact,
                    })}
                    defaultOptions={clientContactDropdown.clientContactItems}
                    selected={clientContactDropdown.clientContactSelected}
                    onSearch={(query) => searchClientContacts(query)}
                  />

                  <Modal
                    trigger={
                      <Button
                        circular
                        icon="plus"
                        className="primary"
                        disabled={!client}
                        onClick={() => handleClientContactModal(true)}
                      />
                    }
                    open={isClientContactModalOpened}
                    onClose={() => handleClientContactModal(false)}
                  >
                    <ContactEditor
                      {...raw({
                        name: "clientContact",
                        onChange: (clientContact) => clientContact as Contact,
                      })}
                      client={client}
                      contactsItems={clientContactDropdown.clientContactItems}
                      onAdd={resetClientContactsOnAdd}
                      close={() => handleClientContactModal(false)}
                    />
                  </Modal>
                </div>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>LP Email</label>
                <Input
                  value={lp ? lp.email : ""}
                  className="primary"
                  disabled
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>Client</label>
                <div className={styles.dropdownGroup}>
                  <SearchDropdown
                    placeholder="Select client"
                    {...raw({
                      name: "client",
                      onChange: (client) => client as Client,
                    })}
                    defaultOptions={clientDropdown.clientItems}
                    selected={clientDropdown.clientSelected}
                    onSearch={(query) => searchClients(query)}
                  />

                  <Modal
                    trigger={
                      <Button
                        circular
                        icon="plus"
                        className="primary"
                        onClick={() => handleClientModal(true)}
                      />
                    }
                    open={isClientModalOpened}
                    onClose={() => handleClientModal(false)}
                  >
                    <ClientEditor
                      {...raw({
                        name: "client",
                        onChange: (client) => client as Client,
                      })}
                      close={() => handleClientModal(false)}
                    />
                  </Modal>
                </div>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>LP Phone Number</label>
                <Input
                  value={lp ? lp.phone : ""}
                  className="primary"
                  disabled
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>WL Program Provider</label>
                <div className={styles.dropdownGroup}>
                  <SearchDropdown
                    placeholder="Select WL Program"
                    {...raw({
                      name: "wlProvider",
                      onChange: (wlProvider) => wlProvider as Client,
                    })}
                    defaultOptions={wlProviderDropdown.clientItems}
                    selected={wlProviderDropdown.wlProviderSelected}
                    onSearch={(query) => searchClients(query)}
                  />

                  <Modal
                    trigger={
                      <Button
                        circular
                        icon="plus"
                        className="primary"
                        onClick={() => handleWlProviderModal(true)}
                      />
                    }
                    open={isWlProviderModalOpened}
                    onClose={() => handleWlProviderModal(false)}
                  >
                    <ClientEditor
                      {...raw({
                        name: "wlProvider",
                        onChange: (wlProvider) => wlProvider as Client,
                      })}
                      close={() => handleWlProviderModal(false)}
                    />
                  </Modal>
                </div>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>Contact Email</label>
                <Input
                  value={clientContact ? clientContact.email : ""}
                  className="primary"
                  disabled
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>Contact Phone Number</label>
                <Input
                  value={clientContact ? clientContact.phone : ""}
                  className="primary"
                  disabled
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>Project Address</label>
                <Input {...text("address")} className="primary" />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>Project Date</label>
                <div className={styles.dropdownGroup}>
                  <DatePickerCustom
                    {...raw({
                      name: "date",
                      onChange: (date: Date) => date,
                    })}
                    value={formState.values.date}
                    disabled={isTBD}
                  />
                  <Toggle label="TBD" {...checkbox("isProjectDateTBD")} />
                </div>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>Project Owner</label>
                <Input
                  value={projectOwner ? projectOwner.username : ""}
                  className="primary"
                  disabled
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Field inline className={styles["form-el"]}>
                <label className={styles.label}>Net Payment Terms</label>
                <div className={styles.dropdownGroup}>
                  <DropdownSelect
                    selected={netPaymentTermsDropdown.netPaymentTermsSelected}
                    options={netPaymentTermsDropdown.netPaymentTermsItems}
                    {...text("netPaymentTerms")}
                  />
                </div>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
};

export default ProjectPage;

function convertProjectToForm(project: Project): FormFields {
  const {
    client,
    title,
    address,
    date,
    LP,
    refNumber,
    projectOwner,
    netPaymentTerms,
    clientContact,
    isProjectDateTBD,
    wlProvider,
  } = project;
  return {
    refNumber: refNumber,
    title: title,
    client: client,
    wlProvider: wlProvider,
    address: address,
    date: date ? new Date(date) : new Date(),
    lp: LP,
    isProjectDateTBD: isProjectDateTBD,
    projectOwner: projectOwner,
    clientContact: clientContact,
    netPaymentTerms:
      netPaymentTerms && 0 !== netPaymentTerms.length
        ? netPaymentTerms.toString()
        : DEFAULT_NET_PAYMENT_TERMS,
  };
}
