import {
  IDM_ADD_DONATION_RUN_CHARITY_REPORTING,
  IDM_ADD_METRIC,
  IDM_ADD_PROJECT_METRICS_STATIC_IMAGES,
  IDM_ADD_RECYCLING_REPORTING_TICKET,
  IDM_DELETE_PROJECT_METRICS_STATIC_IMAGE,
  IDM_PROJECT_SET_METRICS_STATUS,
  IDM_REMOVE_DONATION_RUN_CHARITY_REPORTING,
  IDM_REMOVE_METRIC,
  IDM_REMOVE_RECYCLING_REPORTING_TICKET,
  IDM_SET_IS_LOADING,
  IDM_UPDATE_CHARITY_REPORTING_ITEM,
  IDM_UPDATE_METRIC,
  IDM_UPDATE_PROJECT_METRICS_STATIC_IMAGE,
  IDM_UPDATE_RECYCLING_REPORTING_TICKET,
  INPUT_DIVERSION_METRICS_SET,
  InputDiversionMetricsActionTypes,
  InputDiversionMetricsState
} from "./types"
import {createProject, createRecyclingReportingTicket} from "../data";
import {CharityReporting, Metric, Photo, RecyclingReportingTicket} from "../typings/types";
import {createUID} from "../utils";

const initialState: InputDiversionMetricsState = {
  project: createProject(),
  metrics: [],
  originalMetrics: [],
  recyclingReporting: [],
  originalRecyclingReporting: [],
  charityReporting: [],
  originalCharityReporting: [],
  recyclingReportingGrades: [],
  inventoryItems: [],
  isLoading: false
};

export function inputDiversionMetricsReducer(state = initialState, action: InputDiversionMetricsActionTypes): InputDiversionMetricsState {
  switch (action.type) {
    case INPUT_DIVERSION_METRICS_SET:
      return setInputDiversionMetrics(state, action.payload);
    case IDM_UPDATE_METRIC:
      return updateMetric(state, action.payload);
    case IDM_ADD_RECYCLING_REPORTING_TICKET:
      return addRecyclingReportingTicket(state, action.payload);
    case IDM_REMOVE_RECYCLING_REPORTING_TICKET:
      return removeRecyclingReportingTicket(state, action.payload);
    case IDM_UPDATE_RECYCLING_REPORTING_TICKET:
      return updateRecyclingReportingTicket(state, action.payload);
    case IDM_ADD_PROJECT_METRICS_STATIC_IMAGES:
      return addProjectMetricsStaticImages(state, action.payload);
    case IDM_UPDATE_PROJECT_METRICS_STATIC_IMAGE:
      return updateProjectMetricsStaticImages(state, action.payload);
    case IDM_DELETE_PROJECT_METRICS_STATIC_IMAGE:
      return deleteProjectMetricsStaticImages(state, action.payload);
    case IDM_ADD_DONATION_RUN_CHARITY_REPORTING:
      return addDonationRunCharityReporting(state, action.payload);
    case IDM_REMOVE_DONATION_RUN_CHARITY_REPORTING:
      return removeDonationRunCharityReporting(state, action.payload);
    case IDM_UPDATE_CHARITY_REPORTING_ITEM:
      return updateCharityReportingItem(state, action.payload);
    case IDM_SET_IS_LOADING:
      return setIDMIsLoading(state, action.payload);
    case IDM_PROJECT_SET_METRICS_STATUS:
      return setProjectMetricsStatus(state, action.payload);
    case IDM_ADD_METRIC:
      return addMetric(state);
    case IDM_REMOVE_METRIC:
      return removeMetric(state, action.payload);
    default:
      return state;
  }
}

function setInputDiversionMetrics(state: InputDiversionMetricsState, payload: InputDiversionMetricsState): InputDiversionMetricsState {
  return {
    project: payload.project,
    metrics: payload.metrics,
    originalMetrics: payload.originalMetrics,
    recyclingReporting: payload.recyclingReporting,
    originalRecyclingReporting: payload.originalRecyclingReporting,
    charityReporting: payload.charityReporting,
    originalCharityReporting: payload.originalCharityReporting,
    recyclingReportingGrades: payload.recyclingReportingGrades,
    inventoryItems: payload.inventoryItems
  };
}

function updateMetric(state: InputDiversionMetricsState, item: Metric): InputDiversionMetricsState {
  item.localeWasEdited = true;
  const metrics = state.metrics.map((el) => (el.id == item.id ? item : el))
  return {
    ...state,
    metrics: metrics
  };
}

function addRecyclingReportingTicket(state: InputDiversionMetricsState, payload: RecyclingReportingTicket): InputDiversionMetricsState {
  let newItem = payload;
  console.log(payload)
  newItem.project = state.project;
  const recyclingReporting = [...state.recyclingReporting, ...[newItem]];
  return {
    ...state,
    recyclingReporting
  }
}

function removeRecyclingReportingTicket(state: InputDiversionMetricsState, item: RecyclingReportingTicket): InputDiversionMetricsState {
  const recyclingReporting = state.recyclingReporting.filter(el => el.id != item.id);
  return {
    ...state,
    recyclingReporting
  }
}

function updateRecyclingReportingTicket(state: InputDiversionMetricsState, item: RecyclingReportingTicket): InputDiversionMetricsState {
  item.localeWasEdited = true;
  const recyclingReporting = state.recyclingReporting.map((el) => (el.id == item.id ? item : el));
  return {
    ...state,
    recyclingReporting
  }
}

function addProjectMetricsStaticImages(state: InputDiversionMetricsState, photos: Photo[]): InputDiversionMetricsState {
  return {
    ...state,
    project: {
      ...state.project,
      projectMetricsStaticImages: [...state.project.projectMetricsStaticImages, ...photos]
    }
  }
}

function updateProjectMetricsStaticImages(state: InputDiversionMetricsState, payload: { oldImgId: string, photo: Photo }): InputDiversionMetricsState {
  const { oldImgId, photo } = payload;
  const { project: { projectMetricsStaticImages } } = state;

  const photos = projectMetricsStaticImages.map(el => el.id == oldImgId ? photo : el);

  return {
    ...state,
    project: {
      ...state.project,
      projectMetricsStaticImages: photos
    }
  }
}

function deleteProjectMetricsStaticImages(state: InputDiversionMetricsState, payload: { imgId: string}): InputDiversionMetricsState {
  const { imgId } = payload;
  const { project: { projectMetricsStaticImages } } = state;

  const photos = projectMetricsStaticImages.filter(el => el.id != imgId);

  return {
    ...state,
    project: {
      ...state.project,
      projectMetricsStaticImages: photos
    }
  }
}

function addDonationRunCharityReporting(state: InputDiversionMetricsState, payload: { donationRunNo: number }): InputDiversionMetricsState {
  const { donationRunNo } = payload;
  const charityReporting = state.metrics.map(metric => {
    return {
      id: createUID(),
      quantityDonated: 0,
      signOfSheetImage: undefined,
      donationRunNo: donationRunNo,
      metric: metric,
      localeWasEdited: true
    };
  });
  return {
    ...state,
    charityReporting: [...state.charityReporting, ...charityReporting]
  }
}

function removeDonationRunCharityReporting(state: InputDiversionMetricsState, payload: { donationRunNo: number }): InputDiversionMetricsState {
  const { donationRunNo } = payload;
  let charityReporting = state.charityReporting.filter(el => el.donationRunNo !== donationRunNo);
  if (charityReporting.find(el => el.donationRunNo > donationRunNo)) { // to set the donationRunNo, according to indexes
    charityReporting = charityReporting.map(el => {
      if (el.donationRunNo > donationRunNo) {
        el.donationRunNo = el.donationRunNo - 1;
      }
      return el;
    })
  }
  return {
    ...state,
    charityReporting: charityReporting
  }
}

function updateCharityReportingItem(state: InputDiversionMetricsState, item: CharityReporting): InputDiversionMetricsState {
  item.localeWasEdited = true;
  return {
    ...state,
    charityReporting: state.charityReporting.map(el => el.id == item.id ? item : el)
  }
}

function setIDMIsLoading(state: InputDiversionMetricsState, isLoading: boolean): InputDiversionMetricsState {
  return {
    ...state,
    isLoading: isLoading
  }
}

function setProjectMetricsStatus(state: InputDiversionMetricsState, metricsStatus: string): InputDiversionMetricsState {
  return {
    ...state,
    project: {
      ...state.project,
      metricsStatus: metricsStatus
    }
  }
}

function addMetric(state: InputDiversionMetricsState): InputDiversionMetricsState {
  const metric = {
    id: createUID(),
    clientProductName: '',
    quantity: 0,
    pounds: 0,
    FMV: 0,
    recycling: 0,
    donation: 0,
    internalReuse: 0,
    resale: 0,
    inventoryItem: undefined,
    project: state.project,
    localeWasEdited: true
  };
  return {
    ...state,
    metrics: [
      ...state.metrics,
      ...[metric]
    ]
  };
}

function removeMetric(state: InputDiversionMetricsState, payload: { id: string }): InputDiversionMetricsState {
  return {
    ...state,
    metrics: state.metrics.filter(el => el.id !== payload.id)
  };
}
