import React from "react";
import styles from "./App.module.css";

import RouteWithSubRoutes from "../../../common/components/RouteWithSubroutes";

import Routes from "../../../routing";
import { getUserRole, removeUser } from "../../../utils/user";
import ClientMenu from "../../../common/components/ClientMenu";
import ClientFooter from "../../../common/components/ClientFooter";

const ClientApp: React.FC = () => {
  const role = getUserRole();

  return (
    <div className="">
      <ClientMenu />

      <main className={styles.main}>
        {Routes.map((route, i) => {
          if (route.roles)
            return route.roles && route.roles.indexOf(role) === -1 ? null : (
              <RouteWithSubRoutes key={i} {...route} />
            );
        }).filter((route) => route)}
      </main>
      {<ClientFooter />}

    </div>
  );
};

export default ClientApp;
