import {
  PROJECT_ADD_ITEM,
  PROJECT_ADD_ITEM_IMG,
  PROJECT_DELETE_ITEM,
  PROJECT_DELETE_ITEM_IMG,
  PROJECT_REPLACE_CLIENT_CONTACTS_ITEMS,
  PROJECT_REPLACE_ITEMS,
  PROJECT_RESET,
  PROJECT_SET_FROM_SERVER,
  PROJECT_TOGGLE_LOADING,
  PROJECT_UPDATE_EMAILS,
  PROJECT_UPDATE_INFO,
  PROJECT_UPDATE_ITEM_BY_ID,
  PROJECT_UPDATE_ITEM_IMG,
  PROJECT_UPDATE_QUOTE,
  ProjectForEditActionTypes,
  ProjectForEditState,
  ProjectResetPayload,
  ProjectSetFromServerPayload,
  ProjectUpdateEmailsPayload,
  ProjectUpdateInfoPlayload,
  ProjectUpdateQuotePlayload
} from './types';
import {
  createClient,
  createContact,
  createInventoryListItem,
  createProject
} from '../data';
import {Contact, InventoryListItem, Photo, Project} from '../typings/types';
import {isLocalId} from '../utils';

const initialState: ProjectForEditState = {
  loading: false,
  project: createProject(),
  originalProject: undefined,
  projectWasChanged: false,
  quoteWasChanged: false,
  quoteCoefficientWasChanged: false,
  emailsWasChanged: false,
  dropdownItems: {
    clients: [],
    lps: [],
    inventoryItems: [],
    clientContacts: []
  }
};

export function projectForEditReducer(state = initialState, action: ProjectForEditActionTypes): ProjectForEditState {
  switch (action.type) {
    case PROJECT_UPDATE_INFO:
      return updateProjectInfo(state, action.payload);
    case PROJECT_REPLACE_ITEMS:
      return replaceItems(state, action.payload);
    case PROJECT_ADD_ITEM:
      return addItem(state);
    case PROJECT_UPDATE_ITEM_BY_ID:
      return updateItem(state, action.payload);
    case PROJECT_DELETE_ITEM:
      return deleteItem(state, action.payload.id);
    case PROJECT_UPDATE_QUOTE:
      return updateQuote(state, action.payload);
    case PROJECT_UPDATE_EMAILS:
      return updateEmails(state, action.payload);
    case PROJECT_SET_FROM_SERVER:
      return setFromServer(state, action.payload);
    case PROJECT_ADD_ITEM_IMG:
      return addImgForItem(state, action.payload);
    case PROJECT_UPDATE_ITEM_IMG:
      return updateItemImg(state, action.payload);
    case PROJECT_DELETE_ITEM_IMG:
      return deleteItemImg(state, action.payload);
    case PROJECT_RESET:
      return resetProject(action.payload);
    case PROJECT_TOGGLE_LOADING:
      return {
        ...state,
        loading: typeof action.payload === 'boolean' ? action.payload : !state.loading
      };
    case PROJECT_REPLACE_CLIENT_CONTACTS_ITEMS:
      return replaceClientContactsItems(state, action.payload);
    default:
      return state;
  }
}

function updateProjectInfo(state: ProjectForEditState, info: ProjectUpdateInfoPlayload): ProjectForEditState {
  const { uploaded, id, refNumber, isProjectDateTBD } = info;

  return {
    ...state,
    projectWasChanged: !uploaded,
    project: {
      ...state.project,
      id: id || state.project.id,
      refNumber: refNumber || state.project.refNumber,
      title: info.title,
      client: info.client,
      wlProvider: info.wlProvider,
      address: info.address,
      date: info.date,
      LP: info.lp,
      isProjectDateTBD: info.isProjectDateTBD,
      projectOwner: info.projectOwner,
      netPaymentTerms: info.netPaymentTerms,
      clientContact: info.clientContact
    }
  };
}

function updateItem(state: ProjectForEditState, item: InventoryListItem): ProjectForEditState {
  const inventoryItems = state.project.quote.inventoryListItems;
  const itemCopy: InventoryListItem = {
    id: item.id,
    inventoryItem: item.inventoryItem,
    quantity: item.quantity,
    photos: item.photos ? [...item.photos] : [],
    pounds: item.pounds,
    FMV: item.FMV,
    recycling: item.recycling,
    donation: item.donation,
    internalReuse: item.internalReuse,
    resale: item.resale,
    clientProductName: item.clientProductName,
    localeWasEdited: true
  };
  const items = inventoryItems.map((el) => (el.id == itemCopy.id ? itemCopy : el));

  return {
    ...state,
    project: {
      ...state.project,
      quote: {
        ...state.project.quote,
        inventoryListItems: items
      }
    }
  };
}

function addItem(state: ProjectForEditState): ProjectForEditState {
  const inventoryItems = state.project.quote.inventoryListItems;
  const item = createInventoryListItem();

  item.localeWasEdited = true;
  const items = [...inventoryItems, item];
  return {
    ...state,
    project: {
      ...state.project,
      quote: {
        ...state.project.quote,
        inventoryListItems: items
      }
    }
  };
}

function deleteItem(state: ProjectForEditState, id: String): ProjectForEditState {
  const updatedItems = state.project.quote.inventoryListItems.filter((el) => el.id != id);
  return {
    ...state,
    project: {
      ...state.project,
      quote: {
        ...state.project.quote,
        inventoryListItems: updatedItems
      }
    }
  };
}

function updateQuote(state: ProjectForEditState, info: ProjectUpdateQuotePlayload): ProjectForEditState {
  const {
    project: {
      quote: {
        qCoeff: { LD, MM, PM, MC, recycled }
      }
    },
    quoteWasChanged,
    quoteCoefficientWasChanged
  } = state;
  const incomingCoefficient = info.quoteCoefficients;

  const editCoefficient = incomingCoefficient.LD !== LD ||
    incomingCoefficient.MM !== MM ||
    incomingCoefficient.PM !== PM ||
    incomingCoefficient.MC !== MC ||
    incomingCoefficient.recycled !== recycled ||
    quoteCoefficientWasChanged;

  const editQuote = quoteWasChanged || !editCoefficient;

  return {
    ...state,
    quoteCoefficientWasChanged: editCoefficient,
    quoteWasChanged: editQuote,
    project: {
      ...state.project,
      quote: {
        ...state.project.quote,
        LP: info.lpQuote,
        transferOfTitle: info.transferOfTitle,
        recovery: info.recovery,
        includeMoveMgmt: info.includeMoveMgmt,
        currency: info.currency,
        resold: info.resold,
        manualMoveManagement: info.manualMoveManagement,
        qCoeff: {
          ...info.quoteCoefficients
        }
      }
    }
  };
}

function replaceItems(state: ProjectForEditState, items: InventoryListItem[]): ProjectForEditState {
  return {
    ...state,
    project: {
      ...state.project,
      quote: {
        ...state.project.quote,
        inventoryListItems: items
      }
    }
  };
}

function updateEmails(state: ProjectForEditState, data: ProjectUpdateEmailsPayload): ProjectForEditState {
  const { id, to, cc, bcc, uploaded } = data;
  return {
    ...state,
    emailsWasChanged: !uploaded,
    project: {
      ...state.project,
      quote: {
        ...state.project.quote,
        senddata: {
          id,
          to,
          cc,
          bcc
        }
      }
    }
  };
}

function setFromServer(state: ProjectForEditState, data: ProjectSetFromServerPayload): ProjectForEditState {
  const { field, id } = data;

  if (field === 'quote') {
    return {
      ...state,
      project: {
        ...state.project,
        quote: {
          ...state.project.quote,
          id: id
        }
      },
      quoteWasChanged: false,
    }
  }

  if (field === 'coefficients') {
    return {
      ...state,
      project: {
        ...state.project,
        quote: {
          ...state.project.quote,
          qCoeff: {
            ...state.project.quote.qCoeff,
            id: id
          }
        }
      },
      quoteCoefficientWasChanged: false,
    }
  }

  if (field === 'listitem') {
    const items = state.project.quote.inventoryListItems;
    const oldItemIndex = items.findIndex(el => el.id == data.oldId);
    const updatedItems = items.map((el, i) => {
      if (i === oldItemIndex) {
        return Object.assign({}, items[i], { id, localeWasEdited: false });
      }
      return el;
    });

    return {
      ...state,
      project: {
        ...state.project,
        quote: {
          ...state.project.quote,
          inventoryListItems: updatedItems
        }
      }
    }
  }

  return state;
}

function addImgForItem(state: ProjectForEditState, payload: { itemId: string, photos: Photo[] }): ProjectForEditState {
  const { itemId, photos } = payload;
  const { project: { quote: { inventoryListItems } } } = state;

  const index = inventoryListItems.findIndex(el => el.id == itemId);

  if (index === -1) {
    return state;
  }

  const totalPhotos = [...inventoryListItems[index].photos, ...photos]
  const items = inventoryListItems.map((el, i) => i === index ? { ...el, photos: totalPhotos } : el);
  return {
    ...state,
    project: {
      ...state.project,
      quote: {
        ...state.project.quote,
        inventoryListItems: items
      }
    }
  }
}

function updateItemImg(
  state: ProjectForEditState,
  payload: { itemId: string, oldImgId: string, photo: Photo }
): ProjectForEditState {
  const { itemId, photo, oldImgId } = payload;
  const { project: { quote: { inventoryListItems } } } = state;

  const index = inventoryListItems.findIndex(el => el.id == itemId);

  if (index === -1) {
    return state;
  }

  const items = inventoryListItems.map((item, i) => {
    if (i !== index) {
      return item;
    }

    const photos = item.photos.map(el => el.id == oldImgId ? photo : el)

    return {
      ...item,
      photos,
      localeWasEdited: true
    }
  });

  return {
    ...state,
    project: {
      ...state.project,
      quote: {
        ...state.project.quote,
        inventoryListItems: items
      }
    }
  }
}

function deleteItemImg(state: ProjectForEditState, payload: { itemId: string, imgId: string }): ProjectForEditState {
  const { itemId, imgId } = payload;
  const { project: { quote: { inventoryListItems } } } = state;

  const index = inventoryListItems.findIndex(el => el.id == itemId);

  if (index === -1) {
    return state;
  }

  const items = inventoryListItems.map((item, i) => {
    if (i !== index) {
      return item;
    }

    const photos = item.photos.filter(el => el.id != imgId)

    return {
      ...item,
      photos,
      localeWasEdited: true
    }
  });

  return {
    ...state,
    project: {
      ...state.project,
      quote: {
        ...state.project.quote,
        inventoryListItems: items
      }
    }
  }
}

function resetProject(payload: ProjectResetPayload): ProjectForEditState {
  const { project, dropdownItems: { clients, inventoryItems, lps, clientContacts } } = payload;

  let projectCopy: Project | undefined = project;
  if (!isLocalId(project.id)) {
    projectCopy = createProject(project);
  }
  return {
    ...initialState,
    project: createProject(projectCopy),
    originalProject: projectCopy,
    dropdownItems: {
      clients,
      inventoryItems,
      lps,
      clientContacts
    }
  }
}

function replaceClientContactsItems(state: ProjectForEditState, items: Contact[]): ProjectForEditState {
  return {
    ...state,
    project: {
      ...state.project,
      clientContact: createContact()
    },
    dropdownItems: {
      ...state.dropdownItems,
      clientContacts: items
    }
  };
}
