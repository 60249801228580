import React, {useEffect, useRef, useState} from "react";
import styles from "./index.module.css";
import { ClientReporting, Projects } from "../../utils/requests";
import { Dimmer, Loader, Button } from "semantic-ui-react";
import MultiSelect from "@ani411/react-multi-select-component";
import { debounce } from 'lodash';
import {getUser} from "../../utils/user";
import ModePdfExport from "../../common/components/ModePdfExport";
import ModeCsvExport from "../../common/components/ModeCsvExport";

const ClientHome: React.FC = () => {
  const user = getUser();

  const [iframeSource, setIframeSource] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState<any>([]);
  const [selectedProjects, setSelectedProjects] = useState<any>([]);

  useEffect(() => {
    (async () => {
      await fetchProjects();
    })();
  }, []);

  const onProjectsChange = async (projectsSelected: any) => {
    await fetchClientReporting(
      projectsSelected.length ? projectsSelected : projects
    );
  };
  const debouncedSearch = useRef(debounce(onProjectsChange, 800)).current

  const fetchProjects = async () => {
    const projectsFound = await Projects.projects({
      filters: {
        $or: [{ client: user.client.id }, { wlProvider: user.client.id }],
        metricsStatus: 'Approved'
      },
      pagination: { limit: 100 },
    });
    const options = projectsFound.map((el: any) => ({
      label: el.title,
      value: el.id
    }))
    setProjects(options);
    setSelectedProjects(options);
    await fetchClientReporting(options);
  };

  const fetchClientReporting = async (projectsSelected: any) => {
    setIsLoading(true);
    try {
      const res = await ClientReporting.getClientReportUrl(projectsSelected.map((el: any) => el.value).join());
      const url = res.iframeUrl;
      setIframeSource(url);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const displayMessage = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  return (
    <div className={styles.clientHome}>
      <div>
        {isLoading && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}

        {iframeSource && (
          <React.Fragment>
            <MultiSelect
              options={projects}
              value={selectedProjects}
              onChange={(el: any) => {
                setSelectedProjects(el);
                debouncedSearch(el.length ? el : projects);
              }}
              labelledBy="Select Projects"
              disableSearch={true}
              hasSelectAll={false}
              className={styles.projectDropdown}
            />
            <div className={styles.btnExportContainer}>
              <ModeCsvExport/>
              <ModePdfExport/>
            </div>
            <iframe
              width="1200"
              height="700"
              src={iframeSource}
              frameBorder="0"
              onLoad={displayMessage}
            ></iframe>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ClientHome;
