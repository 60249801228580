import React, {useEffect, useState} from "react";

const ModeCsvExport: React.FC = () => {
  const [showExport, setShowExport] = useState(false);

  useEffect(() => {
    // for csv export
    window.addEventListener('message', function (e) {
      // always check the origin and make sure it is from app.mode.com
      console.log('message => ', e)
      if (e.origin === 'https://app.mode.com') {
        if (e.data['type'] == 'reportExportPaths') {
          const modeBaseUrl = e.origin
          // CSV Export
          const csvExportUrl = e.data['report_csv_export_path']
          const csvExportLink: any = document.getElementById('csv-export-link')
          if (csvExportLink && csvExportLink.href) {
            csvExportLink.href = modeBaseUrl + csvExportUrl
          }
        }
      }
    })
  }, []);

  return (
    <a className="ui button primary btn-export" id="csv-export-link" href=""> CSV Export </a>
  );
};

export default ModeCsvExport;
