import React from "react";
import styles from "./index.module.css";
import Logo from "../../../assets/logo.png";
import { NavLink } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { removeUser } from "../../../utils/user";
import ROUTES_URL from "../../../routing/urls";
import IdleTimer from "react-idle-timer";

const ClientMenu: React.FC = () => {
  const { CLIENT } = ROUTES_URL;

  const logOut = () => {
    removeUser();
    window.location.reload();
  };

  const onIdle = () => {
    logOut();
  };

  return (
    <div className={styles.clientMenu}>
      <IdleTimer timeout={30 * 1000 * 60} onIdle={onIdle}></IdleTimer>

      <div className={styles.clientMenuLogo}>
        <img src={Logo} alt="" />
      </div>
      <div className={styles.menuList}>
        <NavLink to={CLIENT.CLIENT_HOME} activeClassName={styles.active} exact>
          Home
        </NavLink>
        <NavLink
          to={CLIENT.CLIENT_PROJECT}
          activeClassName={styles.active}
          exact
        >
          Search by Project
        </NavLink>
        <Button className="ui button default" size="medium" onClick={logOut}>
          Logout
        </Button>
      </div>
    </div>
  );
};

export default ClientMenu;
