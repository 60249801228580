import React, { useMemo, useEffect, useState, ChangeEvent } from 'react';
import { Input, Grid } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormState } from 'react-use-form-state';
import styles from './index.module.css';

import Toggle from '../../../common/components/Toggle';
import RadioEl from '../../../common/components/RadioEl';

import { AppState } from '../../../store';
import { Currency } from '../../../store/typings/types';
import { projectUpdateQuote } from '../../../store/projectForEdit/actions';
import { ProjectUpdateQuotePlayload } from '../../../store/projectForEdit/types';
import {
  getCurrencySign,
  calcQuote,
  calcLPPricePerTonnes,
  calcDifference,
  currencyFormat,
  calcGrossMargin,
  calcBottomBlock,
  calcPricePerTonne,
  round,
  safeDivision
} from '../../../utils/calculation';
import { Projects } from '../../../utils/requests';

interface FormFields {
  currency: Currency;
  LP: number;
  recovery: string;
  includeMoveMgmt: boolean;
  recycled: number;
  resold: number;
  MM: number;
  PM: number;
  LD: number;
  MC: number;
  manualMoveManagement: number;
}

const TotalPage: React.FC = () => {
  const project = useSelector((state: AppState) => state.projectForEdit.project);
  const { quote, refNumber } = project;
  const {
    currency,
    qCoeff: { id: coefficientId, recycled, MC, MM },
    inventoryListItems,
    transferOfTitle,
    LP,
    manualMoveManagement
  } = quote;
  const dispatch = useDispatch();

  const [serverRefNumber, setServerRefNumber] = useState<Number>(0)

  useEffect(() => {
    const abortCtrl = new AbortController();
    const fetchProjectCount = async () => {
      const number = await Projects.fetchNextProjectRefNumber(abortCtrl);
      if (typeof number === 'number') {
        setServerRefNumber(number);
      }
    };

    if (!refNumber && !serverRefNumber) {
      fetchProjectCount();
    }

    return () => {
      abortCtrl.abort()
    }
  }, [refNumber, serverRefNumber]);

  const projectToForm = (): FormFields => {
    const {
      recovery,
      includeMoveMgmt,
      currency,
      resold,
      LP,
      manualMoveManagement,
      qCoeff: { MM, PM, LD, MC, recycled }
    } = project.quote;
    return {
      currency,
      LP,
      recovery,
      includeMoveMgmt,
      recycled,
      resold,
      MM,
      PM,
      LD,
      MC,
      manualMoveManagement: manualMoveManagement || 0
    };
  };

  const formObj = projectToForm();

  const [, { text, number, radio, checkbox, raw }] = useFormState<FormFields>(formObj, {
    onChange(e, stateValues, nextStateValues) {
      const {
        currency,
        LP,
        recovery,
        includeMoveMgmt,
        recycled,
        resold,
        MM,
        PM,
        LD,
        MC,
        manualMoveManagement
      } = nextStateValues;

      const info: ProjectUpdateQuotePlayload = {
        currency,
        recovery,
        resold: +resold,
        includeMoveMgmt,
        lpQuote: +LP,
        transferOfTitle: transferOfTitle,
        quoteCoefficients: {
          id: coefficientId,
          MM: +MM,
          PM: +PM,
          LD: +LD,
          MC: +MC,
          recycled: +recycled
        },
        manualMoveManagement: manualMoveManagement.length === 0 ? undefined : +manualMoveManagement
      };
      dispatch(projectUpdateQuote(info));
    }
  });

  const { moveMgmt, processMgmt, landfillDiversion, total } = useMemo(() => {
    const res = calcQuote(quote)
    res.moveMgmt = round(res.moveMgmt)
    res.processMgmt = round(res.processMgmt)
    res.landfillDiversion = round(res.landfillDiversion)
    res.total = round(res.total);
    return res;
  }, [quote]);

  const difference = useMemo(() => currencyFormat(calcDifference(LP, total)), [LP, total])
  const grossMargin = useMemo(() => calcGrossMargin(LP, total), [LP, total])
  const pricePerTonne = useMemo(() => calcPricePerTonne(total, inventoryListItems), [total, inventoryListItems])
  const pricePerTonnesLP = useMemo(() => calcLPPricePerTonnes(LP, inventoryListItems), [LP, inventoryListItems])

  const {
    donated,
    internalReuse,
    totalRecycledValue,
    totalDiverted,
    totalRecoveredMaterials,
    quoteTotal,
    metalCredit
  } = useMemo(() => calcBottomBlock(recycled, inventoryListItems, MC), [recycled, inventoryListItems, MC]);

  const textForTransferOfTitle = useMemo(() => {
    const sign = getCurrencySign(currency);
    const tOfT = transferOfTitle.toFixed(2);
    return `-${sign}${tOfT}`;
  }, [transferOfTitle, currency])

  return (
    <div className="page-padding-small">
      <h1 className="secondary-text">Company</h1>

      <div className={styles.container}>
        <div className={styles['left-block']}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <RadioEl label="$ CAD" {...radio('currency', Currency.CAD)} className={'margin-r'} />
                <RadioEl label="$ USD" {...radio('currency', Currency.USD)} className={'margin-r'} />
                <RadioEl label="￡ GBP" {...radio('currency', Currency.GBP)} className={'margin-r'} />
                <RadioEl label="€ EUR" {...radio('currency', Currency.EUR)} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width="4" verticalAlign="middle">
                <label>Project Number</label>
              </Grid.Column>
              <Grid.Column width="5">
                <Input className="primary full-w" value={refNumber || serverRefNumber} disabled={true} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width="4" verticalAlign="middle">
                <label>LP Quote</label>
              </Grid.Column>
              <Grid.Column width="5">
                <Input className="primary full-w" {...number('LP')} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width="4" verticalAlign="middle">
                <label>Move Mgmt</label>
              </Grid.Column>
              <Grid.Column width="5">
                <Input className="primary full-w"
                  type="number"
                  {...raw({
                    name: 'manualMoveManagement',
                    onChange: (event: ChangeEvent<HTMLInputElement>) => event.target.value
                  })}
                  min="0"
                  value={manualMoveManagement || moveMgmt} />
              </Grid.Column>
              <Grid.Column width="5" verticalAlign="middle" className={styles.row}>
                <span className={styles['operation-symbol']}>X</span>
                <Input className={`${styles.factor} margin-r`}
                  type="number"
                  step={0.1}
                  {...raw({
                    name: 'MM',
                    onChange: (event: ChangeEvent<HTMLInputElement>) => event.target.value
                  })}
                  min="0"
                  disabled={!!manualMoveManagement}
                  value={manualMoveManagement ? round(safeDivision(manualMoveManagement, LP), 1) : MM} />
                <Toggle {...checkbox('includeMoveMgmt')} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width="4" verticalAlign="middle">
                <label>Process Mgmt</label>
              </Grid.Column>
              <Grid.Column width="5">
                <Input className="primary full-w" value={processMgmt} disabled={true} />
              </Grid.Column>
              <Grid.Column width="3" verticalAlign="middle">
                <span className={styles['operation-symbol']}>X</span>
                <Input {...number('PM')} step={0.1} className={styles.factor} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width="4" verticalAlign="middle">
                <label>Landfill Diversion</label>
              </Grid.Column>
              <Grid.Column width="5">
                <Input className="primary full-w" value={landfillDiversion} disabled={true} />
              </Grid.Column>
              <Grid.Column width="3" verticalAlign="middle">
                <span className={styles['operation-symbol']}>X</span>
                <Input {...number('LD')} step={0.1} className={styles.factor} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width="4" verticalAlign="middle">
                <label>Transfer of Title</label>
              </Grid.Column>
              <Grid.Column width="5">
                <Input className="primary full-w" disabled value={textForTransferOfTitle} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width="4" verticalAlign="middle">
                <label>
                  <b>Total</b>
                </label>
              </Grid.Column>
              <Grid.Column width="5">
                <Input className="primary full-w" value={total} disabled={true} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width="4" verticalAlign="middle">
                <label>Recovery</label>
              </Grid.Column>
              <Grid.Column width="5">
                <Input className="primary full-w" {...text('recovery')} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width="4" verticalAlign="middle">
                <label>Metal Credit</label>
              </Grid.Column>
              <Grid.Column width="5">
                <Input className="primary full-w" value={metalCredit} disabled={true} />
              </Grid.Column>
              <Grid.Column width="3" verticalAlign="middle">
                <span className={styles['operation-symbol']}>X</span>
                <Input {...number('MC')} step={0.1} className={styles.factor} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <div>
          <div className={styles['info-block']}>
            <b>
              <span>Difference</span>
              <span>$ {difference}</span>
            </b>

            <b>
              <span>Gross Margin</span>
              <span>{grossMargin}</span>
            </b>

            <div>
              <span>Price Per Tonne LP</span>
              <span>$ {pricePerTonnesLP}</span>
            </div>

            <b>
              <span>Price Per Tonne</span>
              <span>$ {pricePerTonne}</span>
            </b>
          </div>

          <div className={styles['info-block']}>
            <b>
              <span>Donated</span>
              <span>$ {donated}</span>
            </b>

            <b>
              <span>Internal Reuse</span>
              <span>{internalReuse}</span>
            </b>

            <b>
              <span>Resold</span>
              <span>
                <span className="margin-r">$</span>
                <Input {...number('resold')} className={styles.resold} />
              </span>
            </b>

            <div>
              <span>
                <b className="margin-r">Recycled</b>
                <span className="margin-r">%</span>
                <Input {...number('recycled')} className={styles.factor} />
              </span>
              <b>$ {totalRecycledValue}</b>
            </div>

            <div>
              <span>Total Diverted</span>
              <span>$ {totalDiverted}</span>
            </div>

            <div>
              <span>Total Recovered Materials</span>
              <span>$ {totalRecoveredMaterials}</span>
            </div>

            <b>
              <span>Total</span>
              <span>$ {quoteTotal}</span>
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalPage;
