import React from 'react';
import styles from './index.module.css';
import { Icon } from 'semantic-ui-react';

interface ToggleProps {
	onChange: (event: any) => void;
	onBlur?: (event: any) => void;
	name?: string;
	checked?: boolean;
	label?: string;
}
const Toggle: React.FC<ToggleProps> = (props) => {
	return (
		<label className={styles.container}>
			<div className={styles.switch}>
				<input
					type="checkbox"
					onChange={props.onChange}
					onBlur={props.onBlur}
					checked={props.checked}
				/>

				<span className={styles.slider}>
					<Icon name={!!props.checked ? 'check' : 'close'} className={styles.icon} />
				</span>
			</div>
      {props.label && <span className={styles.label}>{props.label}</span>}
		</label>
	);
};

export default Toggle;
