import {
  Quote,
  Project,
  Currency,
  InventoryListItem,
  InventoryItem,
  Client,
  LogisticPartner,
  Photo,
  QuoteCoefficients,
  ProjectOwner, Contact, RecyclingReportingTicket, RecyclingReportingGrade
} from './typings/types';
import { createUID } from './utils';
import {getUser} from "../utils/user";
import {PROJECT_METRICS_STATUSES} from "../utils/project";

export const createInventoryItem = (inventoryItem?: InventoryItem, title?: string): InventoryItem => {
  let itemTitle = inventoryItem ? inventoryItem.title : '';
  if (title) {
    itemTitle = title;
  }
  return {
    id: inventoryItem ? inventoryItem.id : createUID(),
    title: itemTitle,
    pounds: inventoryItem ? inventoryItem.pounds : 0,
    FMV: inventoryItem ? inventoryItem.FMV : 0,
    recycling: inventoryItem ? inventoryItem.recycling : 0,
    donation: inventoryItem ? inventoryItem.donation : 0,
    internalReuse: inventoryItem ? inventoryItem.internalReuse : 0,
    resale: inventoryItem ? inventoryItem.resale : 0
  };
};

export const createInventoryItemFromListItem = (title: string, item: InventoryListItem): InventoryItem => {
  const forUpload = createInventoryItem()
  forUpload.title = title;
  forUpload.pounds = item.pounds;
  forUpload.FMV = item.FMV;
  forUpload.recycling = item.recycling;
  forUpload.donation = item.donation;
  forUpload.internalReuse = item.internalReuse;
  forUpload.internalReuse = item.resale;
  return forUpload;
}

export const createInventoryListItem = (listItem?: InventoryListItem, withTitle?: string): InventoryListItem => {

  let inventoryItem: InventoryItem | undefined;
  if (listItem && listItem.inventoryItem) {
    inventoryItem = createInventoryItem(listItem.inventoryItem);
  }

  let clientProductName: string | undefined;
  if (withTitle) {
    clientProductName = withTitle;
  }
  if (listItem) {
    clientProductName = listItem.clientProductName
  }

  return {
    id: listItem ? listItem.id : createUID(),
    inventoryItem: inventoryItem,
    clientProductName: clientProductName,
    quantity: listItem ? listItem.quantity : 0,
    photos: listItem ? listItem.photos : [],
    pounds: listItem ? listItem.pounds : 0,
    FMV: listItem ? listItem.FMV : 0,
    recycling: listItem ? listItem.recycling : 0,
    donation: listItem ? listItem.donation : 0,
    internalReuse: listItem ? listItem.internalReuse : 0,
    resale: listItem ? listItem.resale : 0
  };
};

export const creatQuote = (quote?: Quote): Quote => {

  const qCoeff = createQuoteCoefficient(quote ? quote.qCoeff : undefined);

  const incomingSendData = quote && quote.senddata;

  const senddata = {
    id: incomingSendData && incomingSendData.id ? incomingSendData.id : createUID(),
    cc: incomingSendData && incomingSendData.cc ? incomingSendData.cc : '',
    bcc: incomingSendData && incomingSendData.bcc ? incomingSendData.bcc : '',
    to: incomingSendData && incomingSendData.to ? incomingSendData.to : ''
  };

  let inventoryListItems = [];

  if (quote && quote.inventoryListItems) {
    inventoryListItems = quote.inventoryListItems.map(el => createInventoryListItem(el))
  } else {
    inventoryListItems = [createInventoryListItem()];
  }

  return {
    id: quote ? quote.id : createUID(),
    LP: quote ? quote.LP : 0,
    transferOfTitle: 1, // Always 1
    recovery: quote ? quote.recovery : '',
    resold: quote ? quote.resold : 0,
    qCoeff,
    includeMoveMgmt: quote && typeof quote.includeMoveMgmt === 'boolean' ? quote.includeMoveMgmt : true,
    senddata,
    currency: quote ? quote.currency : Currency.CAD,
    inventoryListItems,
    manualMoveManagement: quote ? quote.manualMoveManagement : undefined
  }
};

export const createQuoteCoefficient = (qCoeff?: QuoteCoefficients): QuoteCoefficients => {
  return {
    id: qCoeff && qCoeff.id ? qCoeff.id : createUID(),
    MM: qCoeff && qCoeff.MM ? qCoeff.MM : 0,
    PM: qCoeff && qCoeff.PM ? qCoeff.PM : 0,
    LD: qCoeff && qCoeff.LD ? qCoeff.LD : 0,
    MC: qCoeff && qCoeff.MC ? qCoeff.MC : 0,
    recycled: qCoeff && qCoeff.recycled ? qCoeff.recycled : 0
  }
}

export const createProject = (project?: Project): Project => {
  const client = project && project.client ? createClient(project.client) : undefined;
  const wlProvider =
    project && project.wlProvider ? createClient(project.wlProvider) : undefined;
  const lp = project && project.LP ? createLP(project.LP) : undefined;
  const projectOwner = createProjectOwner(project ? project.projectOwner : undefined);
  const clientContact = project && project.clientContact ? createContact(project.clientContact) : createContact();

  return {
    id: project ? project.id : createUID(),
    createdBy: "",
    refNumber: project ? project.refNumber : undefined,
    title: project ? project.title : "",
    client: client,
    wlProvider: wlProvider,
    quote: creatQuote(project ? project.quote : undefined),
    address: project ? project.address : "",
    date: project ? project.date : "",
    createdAt: project ? project.createdAt : new Date().toString(),
    LP: lp,
    prOfComp: project && project.prOfComp ? project.prOfComp : 0,
    projectOwner: projectOwner,
    isProjectDateTBD:
      project && project.hasOwnProperty("isProjectDateTBD")
        ? project.isProjectDateTBD
        : false,
    netPaymentTerms:
      project && project.netPaymentTerms ? project.netPaymentTerms : "",
    clientContact: clientContact,
    projectMetricsStaticImages: [],
    metricsStatus: project
      ? project.metricsStatus
      : PROJECT_METRICS_STATUSES.NOT_SUBMITTED,
  };
};

export function createClient(client?: Client): Client {
  return {
    id: client ? client.id : createUID(),
    name: client ? client.name : '',
    logo: undefined,
    createdAt: client ? client.createdAt : new Date().toString()
  };
}

export function createLP(lp?: LogisticPartner): LogisticPartner {
  return {
    id: lp ? lp.id : createUID(),
    title: lp ? lp.title : '',
    contactName: lp ? lp.contactName : '',
    email: lp ? lp.email : '',
    phone: lp ? lp.phone : '',
    logo: undefined,
    createdAt: lp ? lp.createdAt : new Date().toString()
  };
}

export function createPhoto(file: File): Photo {
  return {
    id: createUID(),
    url: '',
    localFile: file
  }
}

export function createProjectOwner(projectOwner?: ProjectOwner): ProjectOwner {
  const user = getUser();
  return {
    id: projectOwner ? projectOwner.id : (user ? user.id : null),
    username: projectOwner ? projectOwner.username : (user ? user.username : null),
    email: projectOwner ? projectOwner.email : (user ? user.email : null)
  };
}

export function createContact(contact?: Contact): Contact {
  return {
    id: contact ? contact.id : createUID(),
    name: contact ? contact.name : '',
    email: contact ? contact.email : '',
    phone: contact ? contact.phone : '',
    client: contact && contact.client ? createClient(contact.client) : undefined
  };
}

export function createRecyclingReportingTicket (item?: RecyclingReportingTicket): RecyclingReportingTicket {

  return {
    id: item ? item.id : createUID(),
    dateOnTicket: item ? item.dateOnTicket : new Date(),
    loadGrade: item ? item.loadGrade : {id: '1', title: '', recoveryPercent: 0, nonRecoveryPercent: 0},
    facilityTicketNumber: item ? item.facilityTicketNumber : '',
    ticketWeight: item ? item.ticketWeight : 0,
    weightMetric: item ? item.weightMetric : 'Kilogram',
    ticketCost: item ? item.ticketCost : 0,
    ticketRecovery: item ? item.ticketRecovery : 0,
    nonRecoverableWeightInPounds: item ? item.nonRecoverableWeightInPounds : 0,
    project: item ? item.project : createProject(),
    localeWasEdited: true,
  };
};
