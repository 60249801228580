import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import {Dimmer, Loader, Tab} from "semantic-ui-react";
import SearchDropdown, {
  SearchDropdownItem,
} from "../../common/components/SearchDropdown";
import {Projects, Metrics, CharityReportings, ClientReporting} from "../../utils/requests";
import "react-image-gallery/styles/css/image-gallery.css";
import Photos from "./photos";
import Report from "./report";
import { getUser } from "../../utils/user";
import _ from "lodash";
const ClientProject: React.FC = () => {
  const user = getUser();

  const [projectItems, setProjectItems] = useState<any>([]);
  const [projectSelected, setProjectSelected] = useState<any>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showPhotoLoader, setShowPhotoLoader] = useState<boolean>(false);
  const [isPhotoListEmpty, setIsPhotoListEmpty] = useState<boolean>(false);
  const [photosListing, setPhotosListing] = useState<any>([]);
  const [iframeSource, setIframeSource] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const panes = [
    {
      menuItem: "Report",
      render: () => (
        <Tab.Pane attached={false}>
          <div className={styles.clientHome}>
            {isLoading && (
              <Dimmer active>
                <Loader />
              </Dimmer>
            )}
            <iframe
              width="1200"
              height="480"
              src={iframeSource}
              frameBorder="0"
              onLoad={displayMessage}
            ></iframe>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Photos",
      render: () => (
        <Tab.Pane attached={false}>
          <Photos
            showPhotoLoader={showPhotoLoader}
            photosListing={photosListing}
            isPhotoListEmpty={isPhotoListEmpty}
            projectSelected={projectSelected}
          />
        </Tab.Pane>
      ),
    },
  ];

  useEffect(() => {
    searchProjects(" ", true);
    //fetchClientReporting();
  }, []);

  const searchProjects = async (query: string, initialCall: boolean) => {
    let filters = {
      filters: {
        title: { $containsi: query },
        $or: [{ client: user.client.id }, { wlProvider: user.client.id }],
        metricsStatus: 'Approved'
      },
      pagination: { limit: 10 },
    };
    if (initialCall) {
      setShowLoader(true);
    }
    const res = await Projects.projects(filters);

    const projectList: any = res.map((el: any) => ({
      text: el.title,
      value: el.id,
      item: el,
    }));
    setProjectItems(projectList);
    if (initialCall) {
      setShowLoader(false);
    }
    return projectList;
  };

  const fetchClientReporting = async (projectId: string) => {
    try {
      setIsLoading(true);
      const res = await ClientReporting.getClientReportUrl(projectId);
      setIframeSource(res.iframeUrl);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const displayMessage = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleSelectedProject = (project: any) => {
    setIsLoading(true);
    const selectedProject = {
      text: project.title,
      value: project.id,
      item: project,
    };
    fetchClientReporting(project.id.toString());
    //setIframeSource(iframeSource + `&param_project_ids=${project.id}`);
    setProjectSelected(selectedProject);
    getMetrics(project.id);
  };

  const getMetrics = async (projectId: string) => {
    setShowPhotoLoader(true);
    let filters = {
      filters: {
        project: projectId,
      },
    };

    const res = await Metrics.getMetrics(filters);
    if (res.length > 0) {
      const metricIds: Array<any> = [];
      res.map((item) => {
        metricIds.push(item.id);
      });
      let metricsFilter = {
        filters: {
          metric: metricIds,
        },
        populate: {
          metric: {
            populate: ["inventoryItem"],
          },
          signOfSheetImage: "*",
        },
      };
      const metricesResult = await CharityReportings.getCharityReportings(
        metricsFilter
      );

      if (metricesResult.length > 0) {
        var grouped = _.mapValues(
          _.groupBy(metricesResult, "donationRunNo"),
          (mlist) => mlist.map((metrice) => _.omit(metrice, "donationRunNo"))
        );

        setPhotosListing(grouped);
        setIsPhotoListEmpty(false);
      } else {
        setIsPhotoListEmpty(true);
      }
    } else {
      setIsPhotoListEmpty(true);
    }
    setShowPhotoLoader(false);
  };

  return (
    <>
      <div className={styles.clientProject}>
        {showLoader ? (
          <div className="ui segment" style={{ height: "400px" }}>
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
            <p></p>
          </div>
        ) : (
          <>
            <div className={styles.projectSearchWrapper}>
              <SearchDropdown
                placeholder="Select Project"
                onChange={(project) => {
                  handleSelectedProject(project);
                }}
                defaultOptions={projectItems}
                selected={projectSelected}
                onSearch={(query) => searchProjects(query, false)}
              />
            </div>

            {
              projectSelected && (
                <div className={styles.selectedProjectDetailWrapper}>
                  <Tab menu={{ pointing: true }} panes={panes} />
                </div>
              )
            }
          </>
        )}
      </div>
    </>
  );
};

export default ClientProject;
