import React, { useState, SyntheticEvent, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Input, Button, Form } from "semantic-ui-react";
import styles from "./App.module.css";

import RouteWithSubRoutes from "../../../common/components/RouteWithSubroutes";

import Routes from "../../../routing";
import ROUTES_URL, { createEditProjectURL } from "../../../routing/urls";
import HISTORY from "../../../routing/history";
import { editProjectsFilter } from "../../../store/projects/actions";
import { useFormState } from "react-use-form-state";
import { debounce } from "lodash";
import { getUserRole, removeUser, ROLES } from "../../../utils/user";

const { CLIENTS, LP, DASHBOARD, IDM, METRICS } = ROUTES_URL;

const SystemApp: React.FC = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const role = getUserRole();
  const menuBtn = (
    <Button
      icon="content"
      size="small"
      className={`${styles.menuBtn} primary-text clear`}
      onClick={toggleMenu}
    />
  );

  const changeSearch = (search: string) => dispatch(editProjectsFilter(search));
  const debouncedChangeSearch = useRef(debounce(changeSearch, 400)).current;

  const [formState, { search }] = useFormState(
    { search: "" },
    {
      onChange(e, stateValues, nextStateValues) {
        debouncedChangeSearch(nextStateValues.search);
      },
    }
  );

  const onSearchSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const text = formState.values.search;
    if (text) {
      HISTORY.push(ROUTES_URL.DASHBOARD);
    }
  };

  const logOut = () => {
    removeUser();
    window.location.reload();
  };

  return (
    <div className={styles.App}>
      {isMenuOpen && (
        <div className={`${styles["side-menu"]} primary-text`}>
          <div className={styles["menu-icon"]}>{menuBtn}</div>
          <div className={styles["side-container"]}>
            <div className={styles["menu-header"]}>
              {/* <div>Business Portal</div>
                <div>3rd Party Portal</div>
                <div>Client Portal</div> */}
            </div>
            <Form onSubmit={onSearchSubmit} autoComplete="off">
              <Input
                icon="search"
                placeholder="Search Project"
                className={`${styles.search} primary`}
                {...search("search")}
              />
            </Form>

            <nav className={styles["menu-content"]}>
              {role === ROLES.SYSTEM_MANAGER ? (
                <NavLink to={DASHBOARD} activeClassName={styles.active} exact>
                  Dashboard
                </NavLink>
              ) : (
                ""
              )}
              {role === ROLES.SYSTEM_MANAGER ? (
                <NavLink
                  to={createEditProjectURL()}
                  activeClassName={styles.active}
                >
                  Add New Project
                </NavLink>
              ) : (
                ""
              )}
              {role === ROLES.SYSTEM_MANAGER ? (
                <NavLink to={CLIENTS} activeClassName={styles.active}>
                  Clients
                </NavLink>
              ) : (
                ""
              )}
              {role === ROLES.SYSTEM_MANAGER ? (
                <NavLink to={LP} activeClassName={styles.active}>
                  Logistics Partners
                </NavLink>
              ) : (
                ""
              )}
              <NavLink to={IDM} activeClassName={styles.active}>
                Input Diversion Metrics
              </NavLink>
            </nav>

            <Button
              className="ui button default"
              fluid
              size="large"
              onClick={logOut}
            >
              Logout
            </Button>
          </div>
        </div>
      )}

      <main className={styles.main}>
        {!isMenuOpen && menuBtn}
        {Routes.map((route, i) => {
          if (route.roles)
            return route.roles && route.roles.indexOf(role) === -1 ? null : (
              <RouteWithSubRoutes key={i} {...route} />
            );
        }).filter((route) => route)}
      </main>
    </div>
  );
};

export default SystemApp;
