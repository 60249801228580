import ROUTES_URL from "../../routing/urls";
import { Project } from "../../store/typings/types";

const { START, INVENTORY_LIST, ITEM_INFO, TOTAL, SEND, REVIEW, SEND_RESULT } = ROUTES_URL.NEW_PROJECT;

type PageConfiguration = {
  url: string;
  progressTitle: string;
  nextBtnTitle: string;
  canGoNext?: (project: Project) => boolean;
};

const isAllItemsSelected = (project: Project): boolean => {
  return !project.quote.inventoryListItems.find(item => !item.inventoryItem);
}

const isValidInventoryListItem = (project: Project): boolean => {
  return !!project.quote.inventoryListItems
    .find(({ quantity, recycling, donation, internalReuse, resale }) => {
      return quantity === recycling + donation + internalReuse + resale;
    })
}

export const STEPS_TITLE = {
  NEW_PROJECT: 'New Project',
  CALC_BUILDER: 'Calc Builder',
  INPUT_VALUES: 'Input Values',
  REVIEW: 'Review',
  SEND: 'Send'
}

export const PAGE_CONFIGURATIONS: PageConfiguration[] = [
  {
    url: START,
    progressTitle: STEPS_TITLE.NEW_PROJECT,
    nextBtnTitle: 'Build Quote',
  },
  {
    url: INVENTORY_LIST,
    progressTitle: STEPS_TITLE.CALC_BUILDER,
    nextBtnTitle: 'Next',
    canGoNext: isAllItemsSelected
  },
  {
    url: ITEM_INFO,
    progressTitle: STEPS_TITLE.INPUT_VALUES,
    nextBtnTitle: 'Next',
    canGoNext: isValidInventoryListItem
  },
  {
    url: TOTAL,
    progressTitle: STEPS_TITLE.INPUT_VALUES,
    nextBtnTitle: 'Save & Continue',
  },
  {
    url: REVIEW,
    progressTitle: STEPS_TITLE.REVIEW,
    nextBtnTitle: 'Continue',
  },
  {
    url: SEND,
    progressTitle: STEPS_TITLE.SEND,
    nextBtnTitle: 'Send',
  },
  {
    url: SEND_RESULT,
    progressTitle: STEPS_TITLE.SEND,
    nextBtnTitle: 'Dashboard',
  },
  {
    url: ROUTES_URL.DASHBOARD,
    progressTitle: STEPS_TITLE.SEND,
    nextBtnTitle: '',
  }
];
