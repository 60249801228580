import React, { useMemo } from 'react';
import { useFormState } from 'react-use-form-state';
import { Input } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-multi-email/style.css';
import { ReactMultiEmail } from 'react-multi-email';
import styles from './index.module.css';

import Toggle from '../../../common/components/Toggle';

import { AppState } from '../../../store';
import { projectUpdateEmails } from '../../../store/projectForEdit/actions';
import { EMAIL_SEPARATOR, ProjectUpdateEmailsPayload } from '../../../store/projectForEdit/types';

interface FormFields {
  sendToClient: boolean;
  to: string;
  sendToCC: boolean;
  ccForm: string[];
  sendToBCC: boolean;
  bccForm: string[];
}

const SendPage: React.FC = () => {
  const dispatch = useDispatch();
  const { clientContact, quote: { senddata } } = useSelector(
    (state: AppState) => state.projectForEdit.project
  );

  const clientContactEmail = clientContact ? clientContact.email : '';

  const formData = useMemo<FormFields>(() => {
    const { cc, bcc, to } = senddata;

    return {
      sendToClient: clientContactEmail === to && to.length > 0,
      to: to.length > 0 ? to : clientContactEmail,
      sendToCC: true,
      ccForm: cc.length > 0 ? cc.split(EMAIL_SEPARATOR) : [],
      sendToBCC: true,
      bccForm: bcc.length > 0 ? bcc.split(EMAIL_SEPARATOR) : []
    }
  }, [senddata, clientContactEmail])

  const [formState, { checkbox, raw, email }] = useFormState<FormFields>(formData, {
    onChange(e, stateValues, nextStateValues) {
      const { to, sendToClient } = nextStateValues;

      const data: ProjectUpdateEmailsPayload = {
        id: senddata.id,
        uploaded: false,
        cc: '',
        bcc: '',
        to: sendToClient ? clientContactEmail : to
      }

      if (sendToClient && to !== clientContactEmail) {
        formState.setField('to', clientContactEmail)
      }

      if (nextStateValues.sendToCC) {
        data.cc = nextStateValues.ccForm.join(EMAIL_SEPARATOR);
      }

      if (nextStateValues.sendToBCC) {
        data.bcc = nextStateValues.bccForm.join(EMAIL_SEPARATOR);
      }

      dispatch(projectUpdateEmails(data));
    }
  });

  const { ccForm, bccForm, sendToClient } = formState.values;

  const createEmailLabel = (email: string, index: number, removeEmail: (index: number) => void) => {
    return (
      <div data-tag key={index} className={styles['email-text']}>
        {email}
        <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
      </div>
    );
  }

  return (
    <div className="page-padding-small">
      <h1 className="secondary-text">Company</h1>

      <div className={styles.container}>
        <div className={styles.row}>
          <Toggle {...checkbox('sendToClient')} label="Send to Client Email" />
          <Input {...email('to')} className={`${styles.emails} primary margin-l`} disabled={sendToClient} />
        </div>
        <div className={styles.row}>
          <Toggle {...checkbox('sendToCC')} label="CC'd Send to additional Emails" />
          <div className={`${styles.emails} margin-l`}>
            <ReactMultiEmail
              emails={ccForm}
              {...raw({
                name: 'ccForm',
                onChange: (emails: string[]) => emails
              })}
              getLabel={createEmailLabel}
            />
          </div>
        </div>
        <div className={styles.row}>
          <Toggle {...checkbox('sendToBCC')} label="BCC'd Send to additional Emails" />
          <div className={`${styles.emails} margin-l`}>
            <ReactMultiEmail
              className={styles.emails}
              emails={bccForm}
              {...raw({
                name: 'bccForm',
                onChange: (emails: string[]) => emails
              })}
              getLabel={createEmailLabel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendPage;
