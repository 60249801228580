import React, { Fragment, useMemo, useState } from 'react';
import {Modal, Input, Button, Dimmer, Loader, Form, Grid} from 'semantic-ui-react';
import { useFormState } from 'react-use-form-state';
import styles from './index.module.css';

import { getImageUrlForPhoto } from '../../../utils/image';
import { RecyclingReportings, LPs, Uploads } from '../../../utils/requests';
import {createLP, createPhoto, createRecyclingReportingTicket} from '../../../store/data';
import {LogisticPartner, Photo, Project, RecyclingReportingGrade, RecyclingReportingTicket} from '../../../store/typings/types';

import ImagePicker from '../../components/ImagePicker';
import DatePickerCustom from "../../components/DatePickerCustom";
import SearchDropdown from "../../components/SearchDropdown";
import DropdownSelect from "../../components/DropdownSelect";
import ImageUpload from "../../components/ImageUpload";
import {updateRecyclingReportingTicket} from "../../../store/metrics/actions";

interface FormFields {
  dateOnTicket: Date;
  loadGrade: RecyclingReportingGrade;
  facilityTicketNumber: string;
  ticketWeight: number;
  weightMetric: string;
  ticketCost: number;
  ticketRecovery: number;
  nonRecoverableWeightInPounds: number;
  signOfSheetImage?: Photo | null;
}

type Props = {
  close: () => void;
  recyclingReporting?: RecyclingReportingTicket;
  recyclingReportingGrades: RecyclingReportingGrade[],
  onChange?: (recyclingReporting: RecyclingReportingTicket) => void;
}

const RecyclingReportingEditor: React.FC<Props> = (props) => {
  const { recyclingReportingGrades } = props;
  const recyclingReporting = props.recyclingReporting || createRecyclingReportingTicket()

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formWasChanged, setFormWasChanged] = useState<boolean>(false);

  const initialFormData = useMemo((): FormFields => {
    const { dateOnTicket, loadGrade, facilityTicketNumber, ticketWeight, weightMetric, ticketCost, ticketRecovery, nonRecoverableWeightInPounds, signOfSheetImage } = recyclingReporting;
    return {
      dateOnTicket,
      loadGrade,
      facilityTicketNumber,
      ticketWeight,
      weightMetric,
      ticketCost,
      ticketRecovery,
      nonRecoverableWeightInPounds,
      signOfSheetImage
    }
  }, [recyclingReporting]);

  const calculateWeightInPounds = (ticketWeight: number, weightMetric: string) => {
    if (ticketWeight && weightMetric) {
      let weightInPounds;
      switch (weightMetric) {
        case 'Kilogram':
          weightInPounds = ticketWeight * 2.2;
          break;
        case 'Gram':
          weightInPounds = ticketWeight * 0.0022;
          break;
        case 'Metric Tonne':
          weightInPounds = ticketWeight / 0.0004535923700100354;
          break;
        case 'USA Ton':
          weightInPounds = ticketWeight * 2000
          break;
        default: // pound
          weightInPounds = ticketWeight;
      }

      return weightInPounds;
    }
    return 0;
  };

  const calculateNonRecoverableWeightInPounds = (loadGrade: RecyclingReportingGrade, ticketWeight: number, weightMetric: string, weightInPounds: number) => {
    if (loadGrade && ticketWeight && weightMetric) {
      if (loadGrade.nonRecoveryPercent) {
        return (weightInPounds / 100) * loadGrade.nonRecoveryPercent;
      }
      return 0; // there is no recoverable due to load grande nonRecoveryPercent -> 0
    }
    return 0;
  };

  const calculateRecoverableWeightInPounds = (loadGrade: RecyclingReportingGrade, ticketWeight: number, weightMetric: string, weightInPounds: number) => {
    if (loadGrade && ticketWeight && weightMetric) {
      if (loadGrade.recoveryPercent) {
        return (weightInPounds / 100) * loadGrade.recoveryPercent;
      }
      return 0; // there is no recoverable due to load grande nonRecoveryPercent -> 0
    }
    return 0;
  };

  const [formState, { text, number, raw }] = useFormState<FormFields>(initialFormData, {
    onChange(e, stateValues, nextStateValues) {
      const {dateOnTicket, loadGrade, facilityTicketNumber, ticketWeight, weightMetric, ticketCost, ticketRecovery, nonRecoverableWeightInPounds, signOfSheetImage} = nextStateValues;
      const weightInPounds = calculateWeightInPounds(+ticketWeight, weightMetric);
      recyclingReporting.weightInPounds = calculateWeightInPounds(+ticketWeight, weightMetric);
      recyclingReporting.recoverableWeightInPounds = calculateRecoverableWeightInPounds(loadGrade, +ticketWeight, weightMetric, weightInPounds);
      recyclingReporting.nonRecoverableWeightInPounds = calculateNonRecoverableWeightInPounds(loadGrade, +ticketWeight, weightMetric, weightInPounds);
      recyclingReporting.signOfSheetImage = signOfSheetImage || undefined;
    }
  });

  const {values: {loadGrade, weightMetric, signOfSheetImage} } = formState;

  const weightMetricOptions = [
    {text: 'Kilogram', value: 'Kilogram'},
    {text: 'Gram', value: 'Gram'},
    {text: 'Metric Tonne', value: 'Metric Tonne'},
    {text: 'USA Ton', value: 'USA Ton'},
    {text: 'Pound', value: 'Pound'}
  ];

  const {loadGradeDropdown, weightMetricDropdown} = useMemo(() => {
    const loadGradeSelected = loadGrade ? {
      text: loadGrade.title,
      value: loadGrade.id,
      item: loadGrade,
    } : undefined;

    const loadGradeItems = recyclingReportingGrades.map((el: any) => ({text: el.title, value: el.id, item: el}));

    const weightMetricSelected = weightMetric ? {
      text: weightMetric,
      value: weightMetric,
      item: weightMetric,
    } : undefined;
    const weightMetricItems = weightMetricOptions.map(el => ({text: el.text, value: el.value, item: el}));

    return {
      loadGradeDropdown: {
        loadGradeSelected,
        loadGradeItems
      },
      weightMetricDropdown: {
        weightMetricSelected,
        weightMetricItems
      }
    }
  }, [loadGrade, recyclingReportingGrades, weightMetric, weightMetricOptions]);

  const saveLPAndCLose = async () => {
    const { dateOnTicket, loadGrade, facilityTicketNumber, ticketWeight, weightMetric, ticketCost, ticketRecovery, nonRecoverableWeightInPounds, signOfSheetImage } = formState.values;

    let updatedRR: RecyclingReportingTicket = {
      ...recyclingReporting,
      dateOnTicket,
      loadGrade,
      facilityTicketNumber,
      ticketWeight: +ticketWeight,
      weightMetric,
      ticketCost: +ticketCost,
      ticketRecovery: +ticketRecovery,
      nonRecoverableWeightInPounds: +nonRecoverableWeightInPounds,
      signOfSheetImage: signOfSheetImage || undefined
    }

    if (typeof props.onChange === 'function') {
      props.onChange(updatedRR);
    }
    props.close();
  }

  const uploadPhoto = async (recyclingReporting: RecyclingReportingTicket): Promise<RecyclingReportingTicket> => {
    if (signOfSheetImage && signOfSheetImage.localFile) {
      const res = await RecyclingReportings.addLogo(signOfSheetImage.localFile, recyclingReporting.id);
      recyclingReporting.signOfSheetImage = {
        id: res.id,
        url: res.url
      }
      return recyclingReporting
    }
    return Promise.resolve(recyclingReporting);
  }

  let imgUrl = useMemo(() => {
    if (!signOfSheetImage) {
      return '';
    }

    if (signOfSheetImage.localFile) {
      return URL.createObjectURL(signOfSheetImage.localFile)
    }

    return getImageUrlForPhoto(signOfSheetImage);
  }, [signOfSheetImage]);

  return (
    <Fragment>
      <Modal.Content>
        <Modal.Description>

          {
            isLoading &&
            <Dimmer active>
              <Loader />
            </Dimmer>
          }

          <div>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form>
                    <Form.Group className={styles['fields-custom']}>
                      <Form.Field className={styles['width-100']}>
                        <DatePickerCustom
                          {...raw({
                            name: 'dateOnTicket',
                            onChange: (date: Date) => date
                          })}
                          value={formState.values.dateOnTicket}
                          disabled={false}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Grid.Column>
                <Grid.Column>
                  <SearchDropdown
                    placeholder="Select Grade"
                    {...raw({
                      name: "loadGrade",
                      onChange: (loadGrade) => loadGrade as RecyclingReportingGrade,
                    })}
                    defaultOptions={loadGradeDropdown.loadGradeItems}
                    selected={loadGradeDropdown.loadGradeSelected}
                    //onSearch={(query) => searchClients(query)}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input
                    placeholder="Facility Ticket Number"
                    {...text('facilityTicketNumber')}
                    fluid
                    className="primary"/>
                </Grid.Column>
                <Grid.Column>
                  <Input {...number('ticketWeight')} min="0" fluid className="primary"/>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column>
                  <DropdownSelect
                    selected={weightMetricDropdown.weightMetricSelected}
                    options={weightMetricDropdown.weightMetricItems}
                    {...text('weightMetric',)} />
                </Grid.Column>
                <Grid.Column>
                  <Input {...number('ticketCost')} fluid className="primary"/>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input {...number('ticketRecovery')} min="0" fluid className="primary"/>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column>
                  <ImageUpload
                    {...raw({
                      name: 'signOfSheetImage',
                      onChange: (files: File[]) => files.length ? createPhoto(files[0]) : null
                    })}
                    url={imgUrl}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Button className={`${styles.btn} primary`} onClick={saveLPAndCLose}>Save</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>

          </div>
        </Modal.Description>
      </Modal.Content>
    </Fragment>
  );
};

export default RecyclingReportingEditor;
