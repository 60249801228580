import React, { SyntheticEvent } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

export type DropDownOption = { text: string; value: string };

interface DropdownSelectProps {
  placeholder?: string;
  value?: string;
  options: DropDownOption[];
  selected?: DropDownOption;
  onChange?: (el: SyntheticEvent<HTMLElement, Event>) => void;
}

const DropdownSelect: React.FC<DropdownSelectProps> = (props) => {

  const onChangeLocal = (e: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (props.onChange) {
      const option = props.options.find((el) => el.value === data.value);
      const updateEvent = Object.assign({}, e, {
        target: {
          option,
          value: data.value,
          validity: { valid: true }
        }
      });
      props.onChange(updateEvent);
    }
  };

  return (
    <Dropdown
      fluid
      placeholder={props.placeholder}
      value={props.selected ? props.selected.value : undefined}
      selection
      options={props.options}
      onChange={onChangeLocal}
    />
  );
};

export default DropdownSelect;
