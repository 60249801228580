import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import styles from './index.module.css';

import {getUser, getUserRole, ROLES} from "../../utils/user";
import {
  editProjectsSelectedID
} from "../../store/projects/actions";
import {fetchInputDiversionMetrics} from "../../store/metrics/actions";
import ROUTES_URL from "../../routing/urls";
import HISTORY from "../../routing/history";
import {Button, Input} from "semantic-ui-react";
import {PROJECT_METRICS_STATUSES} from "../../utils/project";
import {
  PaginationParams,
  Project
} from "../../store/typings/types";
import {PAGINATION_ITEMS_PER_PAGE} from "../../common/constants/common";
import {Projects} from '../../utils/requests';
import EcoPagination from "../../common/components/EcoPagination";
import {debounce} from "lodash";


const InputDiversionMetrics: React.FC = () => {
  const dispatch = useDispatch();
  const [allProjects, setAllProjects] = useState<Project[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const user = getUser();
  const role = getUserRole();

  useEffect(() => {
    if (!allProjects) {
      fetchIDMProjects({limit: PAGINATION_ITEMS_PER_PAGE});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProjects]);

  const fetchIDMProjects = async (queryParams: PaginationParams) => {
    setIsLoading(true);
    const filter = user.type === ROLES.LOGISTIC_PARTNER ? { LP: user.lp, isLegacy: false } : { isLegacy: false };
    if (!queryParams.offset) {
      const count = await Projects.count(filter, queryParams.searchText);
      setTotalCount(count);
    }
    const res = await Projects.findAll(filter, queryParams);
    setAllProjects(res);
    setIsLoading(false);
  };

  const search = async (text: string) => {
    setSearchText(text);
    fetchIDMProjects({limit: PAGINATION_ITEMS_PER_PAGE, searchText: text})
  };
  const debouncedSearch = useRef(debounce(search, 400)).current;

  const onPageChange = async (limit: number, offset: number) => {
    fetchIDMProjects({limit, offset, searchText});
  };

  const isEligibleToViewMetrics = (project: Project) => {
    let isEligible = false;
    if (project.prOfComp && project.prOfComp > 60) {
      isEligible = true;
    }
    if (
      [PROJECT_METRICS_STATUSES.SUBMITTED, PROJECT_METRICS_STATUSES.APPROVED].indexOf(project.metricsStatus) > -1 &&
      role === ROLES.LOGISTIC_PARTNER
    ) {
      isEligible = false;
    }
    if (role === ROLES.SYSTEM_MANAGER) {
      isEligible = true;
    }
    return isEligible
  };

  const selectProject = (projectId: string) => {
    dispatch(editProjectsSelectedID(projectId));
    dispatch(fetchInputDiversionMetrics());
    HISTORY.push(ROUTES_URL.METRICS.POST_PROJECT_REPORTING.replace(':id', projectId));
  };

  return (
    <div className="page-padding content-scrollable">
      <div className={styles.header}>
      </div>

      <div className={styles.header}>
        <h1 className="secondary-text">Recycling & Charity Metrics</h1>
        <span>
          <Input
            icon="search"
            placeholder="Search"
            className="primary"
            onChange={(e, data) => debouncedSearch(data.value)} />
        </span>
      </div>
      <div className="relative">
        <table className="table table-striped table-vertical-bordered primary-text">
          <thead>
          <tr>
            <th>Project Number</th>
            <th>Project Title</th>
            <th>LP Name</th>
            <th>Status</th>
            <td />
          </tr>
          </thead>
          <tbody>
          {
            allProjects && allProjects.map((project, index) =>
              <tr key={index}>
                <td>{project.refNumber}</td>
                <td>{project.title}</td>
                <td>{project.LP && project.LP.title || '-'}</td>
                <td>{project.metricsStatus ? project.metricsStatus.replace(/([A-Z])/g, ' $1').trim() : '-'}</td>
                <td>
                  {
                    isEligibleToViewMetrics(project)
                      ? (
                        <Button icon="edit" compact className="clear primary-text" onClick={() => selectProject(project.id)}/>
                      ) : '-'
                  }
                </td>
              </tr>
            )
          }
          </tbody>
        </table>
        <EcoPagination
          loading={isLoading}
          totalCount={totalCount}
          onPaginate={onPageChange}
        />
      </div>
    </div>
  );
};

export default InputDiversionMetrics;
