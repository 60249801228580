import {
  PROJECTS_DROPDOWN,
  PROJECTS_FILTER,
  PROJECTS_ID,
  ProjectsActionTypes,
  ProjectsState
} from './types';

const initialState: ProjectsState = {
  searchQuery: '',
  dropdownItems: {
    projects: []
  },
  selectedID: ''
};

export function projectsReducer(state = initialState, action: ProjectsActionTypes): ProjectsState {
  const { payload } = action;
  switch (action.type) {
    case PROJECTS_FILTER:
      return {
        dropdownItems: {projects: state.dropdownItems.projects},
        searchQuery: payload,
        selectedID: state.selectedID
      } as ProjectsState;
    case PROJECTS_DROPDOWN:
      return {
        searchQuery: state.searchQuery,
        dropdownItems: {
          projects: payload
        },
        selectedID: state.selectedID
      } as ProjectsState;
    case PROJECTS_ID:
      return {
        searchQuery: state.searchQuery,
        dropdownItems: {projects: state.dropdownItems.projects},
        selectedID: payload
      } as ProjectsState;
    default:
      return state;
  }
}
