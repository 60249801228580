import React, { useState, useEffect, Fragment } from 'react';

import ProjectItem from './ProjectItem';
import LoadingEl from '../LoadingEl';

import {PaginationParams, Project} from '../../../store/typings/types';
import {LPs, Projects} from '../../../utils/requests';
import {PAGINATION_ITEMS_PER_PAGE} from "../../constants/common";
import EcoPagination from "../EcoPagination";

export type ProjectTableProps = {
  parentFilter?: {
    client?: string,
    lp?: string;
    title?: string;
  }
}

const ProjectsTable: React.FC<ProjectTableProps> = (props) => {
  const { parentFilter } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<Project[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    fetchProjects({limit: PAGINATION_ITEMS_PER_PAGE});
  }, [parentFilter]);

  const fetchProjects = async function (queryParams: PaginationParams) {
    setIsLoading(true);
    const filter = {
        filters: {
            client: parentFilter && parentFilter.client ? {id: {$eq: parentFilter.client}} : undefined,
            LP: parentFilter && parentFilter.lp ? {id: {$eq: parentFilter.lp}} : undefined,
            title: { $containsi: parentFilter && parentFilter.title }
        }
    };
    if (!queryParams.offset) {
      const count = await Projects.count(filter);
      setTotalCount(count);
    }
    const res = await Projects.findAll(filter, queryParams);
    setProjects(res);
    setIsLoading(false);
  };

  const onPageChange = async (limit: number, offset: number) => {
    fetchProjects({limit, offset, searchText: ''});
  };

  return (
    <Fragment>
      <LoadingEl show={isLoading} />

      <table className="table table-striped table-vertical-bordered primary-text table-fixed">
        <thead>
          <tr>
            <th>Project Title</th>
            <th>Project Number</th>
            <th>Project Owner</th>
            <th>Created</th>
            <th>Client</th>
            <th>Status</th>
            {/*<th>Project Owner</th> */}
            {/*//TODO*/}
            <th>%</th>
            <td />
          </tr>
        </thead>
        <tbody>
          {
            projects.map(project =>
              <ProjectItem project={project} key={project.id} />
            )
          }
        </tbody>
      </table>
      <EcoPagination
        loading={isLoading}
        totalCount={totalCount}
        onPaginate={onPageChange}
      />
    </Fragment>
  );
};

export default ProjectsTable;
