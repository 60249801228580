import React, { useState, ChangeEvent, useRef, Fragment, useEffect } from 'react';
import { Icon, Button } from 'semantic-ui-react';
import styles from './index.module.css';

interface Props {
  onChange: (files: File[]) => void;
  url?: string;
}

const ImagePicker: React.FC<Props> = (props) => {

  const [highlighted, setHighlighted] = useState(false);
  const dropContainer = useRef<HTMLDivElement>(null);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length) {
      notify(Array.from(files))
    }
  }

  const allowDrop = (event: Event): Boolean => {
    event.preventDefault();
    return false;
  }

  const dragLeave = (event: Event) => {
    const { target } = event;

    if (!target || !dropContainer.current || !highlighted) {
      return
    }

    const container = dropContainer.current;

    if (container === target) {
      setHighlighted(false);
    }
  }

  const dragEnter = (event: Event) => {
    const { target } = event;

    if (!target || !dropContainer.current) {
      return
    }

    const isChildEl = dropContainer.current.contains(target as HTMLElement)

    if (!highlighted && isChildEl) {
      setHighlighted(true);
    }
  }

  const drop = (event: Event) => {
    event.preventDefault();

    const items = (event as any).dataTransfer.items || []
    const images = []

    for (let i = 0; i < items.length; i++) {
      const img = items[i];
      if (img.kind === 'file' && img.type.indexOf('image/') === 0)       {
        images.push(img.getAsFile())
      }
    }

    if (images.length === 0) {
      return;
    }
    
    notify(images)
    setHighlighted(false);
  }

  useEffect(() => {
    document.addEventListener('dragover', allowDrop)
    document.addEventListener('dragenter', dragEnter);
    document.addEventListener('dragleave', dragLeave);
    document.addEventListener('drop', drop);
    return () => {
      document.removeEventListener('dragover', allowDrop)
      document.removeEventListener('dragenter', dragEnter);
      document.removeEventListener('dragleave', dragLeave);
      document.removeEventListener('drop', drop);
    };
  });

  const notify = (files: File[]) => props.onChange(files);

  const text = 'Drag and drop photos from your desktop';

  return (
    <div className={`${highlighted ? styles.dashed : ''} ${styles.container}`}
      ref={dropContainer}>

      {
        !props.url &&
        <label className={styles.padding}>
          <input className={styles.hide} type='file' onChange={onChange} accept="image/*" />

          <div className={styles.placeholder}>
            <Icon name="image outline" size="big" fitted={true} />
            <span>{text}</span>
          </div>
        </label>
      }

      {
        !!props.url && (
          <Fragment>
            <Button
              className={`primary-text clear ${styles.btn}`}
              size="massive"
              icon="close"
              circular={true}
              onClick={() => notify([])} />
            <img src={props.url} alt="Selected" className={styles.image} />
          </Fragment>
        )
      }

    </div >
  );
};

export default ImagePicker;
