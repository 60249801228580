import React, { useMemo } from 'react';
import {Button, Modal} from 'semantic-ui-react';
import styles from './index.module.css';

import { Photo } from '../../../store/typings/types';
import { getImageUrl } from '../../../utils/image';

export type PhotoVDProps = {
  photo: Photo;
  onClose: (photo: Photo) => void
};
const ImageVD: React.FC<PhotoVDProps> = (props) => {
  const { photo: { localFile, url }, onClose } = props;

  const imgURL = useMemo(() => {
    if (localFile) {
      return URL.createObjectURL(localFile)
    }

    return getImageUrl(url);
  }, [url, localFile]);

  const removeImage = () => {
    if (window.confirm('Are you sure you want to remove image?')) {
      onClose(props.photo);
    }
  };

  return (
    <div className={styles.container}>
      <Modal trigger={
        <Button
          className={`primary-text clear ${styles.btn}`}
          size="massive"
          icon="eye"
          circular={true} />
      } basic size='small'>
        <Modal.Content>
          <img src={imgURL} alt="Selected" className={styles.image} />
        </Modal.Content>
      </Modal>
      <Button
        className={`primary-text clear ${styles.btn} ${styles.btnClose}`}
        size="massive"
        icon="close"
        circular={true}
        onClick={removeImage} />
      <img src={imgURL} className={styles.img} alt="" />
    </div>
  );
};

export default ImageVD;
