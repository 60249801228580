import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Icon } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { List, AutoSizer, ListRowProps } from 'react-virtualized'
import styles from './index.module.css';

import InventoryItemInfo from '../../../common/components/InventoryItemInfo';
import CSVButton from '../../../common/components/CSVButton';

import { AppState } from '../../../store';
import { projectAddItem } from '../../../store/projectForEdit/actions';

const addItem = () => projectAddItem();

const mapStateToProps = (state: AppState) => {
  const projectForEdit = state.projectForEdit;
  const options = projectForEdit.dropdownItems.inventoryItems.map(el => ({ text: el.title, value: el.id, item: el }));
  return {
    projectInventoryItems: projectForEdit.project.quote.inventoryListItems,
    defaultItems: options
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ addItem }, dispatch);
};

type Props = ReturnType<typeof mapStateToProps> & { addItem: () => void };

class InventoryListPage extends Component<Props> {

  private rowRenderer({ index, key, style }: ListRowProps) {
    const item = this.props.projectInventoryItems[index];
    return (
      <Grid style={style} key={item.id} className={styles.gridMargin}>
        <InventoryItemInfo item={item} defaultOptions={this.props.defaultItems} />
      </Grid>
    );
  };

  render() {
    const { projectInventoryItems } = this.props;

    return (
      <div className={`page-padding-small ${styles.container}`}>
        <Grid columns={6} className={styles.gridMargin}>
          <Grid.Row className={styles['table-header']}>
            <Grid.Column width="3" className="secondary-text">
              Items
							</Grid.Column>
            <Grid.Column width="3" className="secondary-text">
              Client product name
							</Grid.Column>
            <Grid.Column width="2" className="secondary-text">
              Quantity
							</Grid.Column>
            <Grid.Column width="2" className="secondary-text">
              Pounds(pp)
							</Grid.Column>
            <Grid.Column width="3" className="secondary-text">
              Tonnes
							</Grid.Column>
            <Grid.Column width="1" className="secondary-text">
              FMV($)
							</Grid.Column>
          </Grid.Row>
        </Grid>

        <div className={styles.list}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                height={height}
                width={width}
                rowCount={projectInventoryItems.length}
                rowHeight={52}
                className={`${styles.overflow} focus-none`}
                rowRenderer={props => this.rowRenderer(props)}
              />
            )}
          </AutoSizer>
        </div>

        <div>
          <button onClick={this.props.addItem} className={`${styles.add} button`}>
            <label className="ui circular icon button primary">
              <Icon name="add" />
            </label>
            <span>Add Item</span>
          </button>
        </div>

        <div className={styles.upload}>
          <CSVButton />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryListPage);
